import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import api from "../../../api";
import Chart from "chart.js/auto";
import { fetchData } from "../../../GlobalFunctions";

const JobDetails = () => {
  interface MindJobDetailsData {
    failed: number;
    run_time: string;
    running: number;
    success: number;
  }

  const { job_name } = useParams();
  const [jobDetailsData, setJobDetailsData] = useState<
    MindJobDetailsData[] | null
  >(null);
  const [selectedRunTime, setSelectedRunTime] = useState<string>("");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await api.get(
  //         `${process.env.REACT_APP_JOB_DETAILS}${job_name}`
  //       );
  //       const data = response.data;
  //       setJobDetailsData(data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [job_name]);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_JOB_DETAILS}${job_name}`,
      setJobDetailsData
    );
  }, [job_name]);

  useEffect(() => {
    if (jobDetailsData && selectedRunTime) {
      renderChart();
    }
  }, [jobDetailsData, selectedRunTime]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRunTime(event.target.value);
  };

  const renderChart = () => {
    const selectedJob = jobDetailsData!.find(
      (job) => job.run_time === selectedRunTime
    );
    if (selectedJob) {
      const ctx = document.getElementById("jobChart") as HTMLCanvasElement;
      if (ctx) {
        const existingChart = Chart.getChart(ctx);
        if (existingChart) {
          existingChart.destroy();
        }

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: ["Failed", "Running", "Success"],
            datasets: [
              {
                label: "Job Status",
                data: [
                  selectedJob.failed,
                  selectedJob.running,
                  selectedJob.success,
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.5)",
                  "rgba(54, 162, 235, 0.5)",
                  "rgba(75, 192, 192, 0.5)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(75, 192, 192, 1)",
                ],
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    }
  };

  return (
    <section>
      <Link to={"/jobs"}>
        <h1>Go Back</h1>
      </Link>
      {job_name}
      {jobDetailsData && (
        <div>
          <select onChange={handleChange}>
            <option value="">Select Run Time</option>
            {jobDetailsData.map((job, index) => (
              <option key={index} value={job.run_time}>
                {job.run_time}
              </option>
            ))}
          </select>
          <div className=" h-[400px]">
            <canvas id="jobChart"></canvas>
          </div>
        </div>
      )}
    </section>
  );
};

export default JobDetails;
