import { useEffect, useState } from "react";
import EditUser from "./EditUser";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import api from "../../../../api";
import DeleteUser from "./DeleteUser";
import { fetchData, getPermissionForName } from "../../../../GlobalFunctions";

let currentIdDatabase = 0;

function Users({ userPermissions }: any) {
  interface MindUserstData {
    id: string;
    username: string;
    user_email: string;
    is_enabled: number;
  }

  interface AllRoles {
    role_id: string;
    role_name: string;
    role_description: string;
    is_active: number;
  }

  const [allRoles, setAllRoles] = useState<AllRoles[] | null>(null);

  const [mindUsersData, setMindUsersData] = useState<MindUserstData[] | null>(
    null
  );

  const [userEditOpen, setUserEditOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openEditUser = async (id: number) => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_ROLES_BY_USER_ID}${
          mindUsersData ? mindUsersData[id].id : null
        }`
      );
      const roles = response.data;

      setAllRoles(roles);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    currentIdDatabase = id;
    setUserEditOpen(true);
  };

  const closeEditUser = () => {
    setUserEditOpen(false);
  };

  const handleUserEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeEditUser();
    }
  };

  useEffect(() => {
    setIsDeleteRoleOpen(false);
    fetchData(`${process.env.REACT_APP_ALL_USERS}`, setMindUsersData);
  }, [refresh]);

  const [isDeleteRoleOpen, setIsDeleteRoleOpen] = useState(false);
  const openDeleteRole = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteRoleOpen(true);
  };

  const closeDeleteRole = () => {
    setIsDeleteRoleOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteRole();
    }
  };

  const isAllowEdit =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_edit", "Users");

  const isAllowDelete =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_delete", "Users");

  return (
    <div>
      <div className=" overflow-x-auto shadow-md sm:rounded-lg mb-4">
        <table className="w-full text-sm text-left">
          <thead className="border-b bg-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Username
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Active
              </th>
              {userPermissions ? (
                isAllowEdit || isAllowDelete ? (
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                ) : null
              ) : null}
            </tr>
          </thead>
          <tbody>
            {mindUsersData?.map((data, index) => (
              <tr key={index} className="bg-gray-200 border-b ">
                <td className="px-6 py-4">{data.id}</td>
                <td className="px-6 py-4">{data.username}</td>
                <td className="px-6 py-4">{data.user_email}</td>
                <td className="px-6 py-4">
                  {data.is_enabled === 0 ? "False" : "True"}
                </td>
                {userPermissions ? (
                  isAllowEdit || isAllowDelete ? (
                    <td className="px-6 py-4 flex flex-row gap-2">
                      {userPermissions && isAllowEdit && (
                        <>
                          <button
                            onClick={() => openEditUser(index)}
                            className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                          >
                            <BiEdit />
                          </button>
                          {mindUsersData
                            ? userEditOpen && (
                                <EditUser
                                  handleUserEditClick={handleUserEditClick}
                                  closeEditUser={closeEditUser}
                                  nameEdit={
                                    mindUsersData[currentIdDatabase].username
                                  }
                                  emailEdit={
                                    mindUsersData[currentIdDatabase].user_email
                                  }
                                  idEdit={mindUsersData[currentIdDatabase].id}
                                  enabledEdit={
                                    mindUsersData[currentIdDatabase].is_enabled
                                  }
                                  allroles={allRoles}
                                  setRefresh={setRefresh}
                                />
                              )
                            : null}
                        </>
                      )}
                      {userPermissions && isAllowDelete && (
                        <button
                          onClick={() => openDeleteRole(index)}
                          className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                        >
                          <MdDelete />
                        </button>
                      )}
                    </td>
                  ) : null
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {userPermissions && isAllowDelete && (
        <>
          {mindUsersData && isDeleteRoleOpen && (
            <DeleteUser
              handleDeleteClick={handleDeleteClick}
              closeDeleteRole={closeDeleteRole}
              idEdit={mindUsersData[currentIdDatabase].id}
              nameEdit={mindUsersData[currentIdDatabase].username}
              setRefresh={setRefresh}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Users;
