import React, { useState } from "react";
import { FcOpenedFolder } from "react-icons/fc";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { BsFileText, BsImage } from "react-icons/bs";
import { FaFile, FaRegFilePdf } from "react-icons/fa6";
import Cookies from "js-cookie";
import { ImSpinner2 } from "react-icons/im";

var folderID = "";
var prevFolderId = "";

interface AsideProps {
  entries: any[];
  onFolderClick: (path: string) => void;
  handleFileClick: (file: string) => void;
  currentChange: (e: any, temp: string) => void;
  selectedFoldersId: any[];
  tempData: any[];
  setTempData: (temp: any) => void;
  checkboxToChange: string;
}

const SideMenu: React.FC<AsideProps> = ({
  entries,
  onFolderClick,
  handleFileClick,
  currentChange,
  selectedFoldersId,
  tempData,
  setTempData,
  checkboxToChange,
}) => {
  const [data, setData] = useState<any | null>(null);
  const user_id = localStorage.getItem("user_id");
  const token = Cookies.get("access_token");

  const fetchData = async (index: any) => {
    const rem = document.getElementById(`child-folder-${index}`);
    if (rem) {
      rem.remove();
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ONE_DRIVE_LIST_FILES}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: user_id,
              folder_id: folderID,
            }),
          }
        );

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error("Failed to fetch data from onedrive API");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  useEffect(() => {
    const parent = document.getElementById(`parent-folder-${folderID}`);
    // const temp = document.getElementById(`child-folder-${prevFolderId}`);
    // console.log(temp?.parentNode, "AAAAAAAAA");
    // temp?.remove();

    if (parent && data) {
      const newDiv = document.createElement("div");
      newDiv.setAttribute("id", `child-folder-${folderID}`);
      newDiv.className = " ml-5";

      data?.forEach((entry: any, index: any) => {
        if (entry.type === "Folder") {
          const div = document.createElement("div");
          const maindiv = document.createElement("div");
          const innerdiv = document.createElement("div");
          innerdiv.addEventListener("click", () => onFolderClick(entry.id));
          innerdiv.addEventListener("click", () => (folderID = entry?.id));
          innerdiv.addEventListener("click", () => fetchData(entry.id));

          maindiv.setAttribute("id", `parent-folder-${entry.id}`);

          div.className = "flex flex-row gap-2 items-center cursor-pointer";
          innerdiv.className =
            "flex flex-row gap-2 items-center cursor-pointer";

          const folderName = document.createElement("div");
          if (entry.name.length > 10) {
            folderName.textContent = entry.name.substring(0, 10) + "...";
            folderName.setAttribute("title", entry?.name);
          } else {
            folderName.textContent = entry.name;
          }

          const folderIcon = <FcOpenedFolder size={25} />;
          const folderIconContainer = document.createElement("div");

          ReactDOM.render(folderIcon, folderIconContainer);

          const checkbox = document.createElement("input");
          checkbox.type = "checkbox";

          checkbox.addEventListener("change", () => changeFolder(entry.id));
          checkbox.addEventListener("change", () => onFolderClick(entry?.id));
          checkbox.addEventListener("change", (e) =>
            currentChange(e, entry.id)
          );

          checkbox.checked = selectedFoldersId.includes(entry.id);

          checkbox.setAttribute("id", `parent-checkbox-${entry.id}`);

          innerdiv.appendChild(folderIconContainer);
          innerdiv.appendChild(folderName);

          div.appendChild(checkbox);
          div.appendChild(innerdiv);

          maindiv.appendChild(div);
          newDiv.appendChild(maindiv);
        }
      });

      parent.appendChild(newDiv);
      prevFolderId = folderID;
    }
  }, [data]);

  const changeFolder = async (folderPath: string) => {
    if (folderPath !== "") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ONE_DRIVE_LIST_FILES}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: user_id,
              folder_id: folderPath,
            }),
          }
        );

        if (response.ok) {
          const jsonData = await response.json();
          setTempData(jsonData.filter((entry: any) => entry.type !== "Folder"));
        } else {
          console.error("Failed to fetch data from Dropbox API");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  useEffect(() => {
    if (checkboxToChange === "") {
    } else {
      const check = document.getElementById(
        `parent-checkbox-${checkboxToChange}`
      ) as HTMLInputElement;
      if (check) {
        check.checked = false;
      }
    }
  }, [checkboxToChange]);

  const typeToIconMap: { [key: string]: React.ReactNode } = {
    Folder: <FcOpenedFolder size={25} />,
    "text/plain": <BsFileText size={25} />,
    "image/jpeg": <BsImage size={25} />,
    File: <FaRegFilePdf size={25} />,
  };

  const filteredFolders = entries?.filter(
    (folder: any) => folder?.type === "Folder"
  );

  return (
    <aside className="p-2 border-r border-[#C5D4EB] flex flex-col gap-1 w-[20%] h-full overflow-y-auto">
      <ul>
        {filteredFolders === undefined ? (
          <div className=" flex justify-center items-center mt-10">
            <ImSpinner2 size={25} className="animate-spin" />
          </div>
        ) : (
          filteredFolders?.map((data: any, index) => (
            <div key={data?.id} id={`parent-folder-${data.id}`}>
              <div className="flex flex-row items-center ">
                <input
                  id={`parent-checkbox-${data.id}`}
                  className=" mr-2"
                  type="checkbox"
                  checked={selectedFoldersId.includes(data.id)}
                  onChange={(e) => {
                    changeFolder(data.id);
                    onFolderClick(data?.id);
                    currentChange(e, data.id);
                  }}
                />
                <button
                  id={`parent-folder-${data.id}`}
                  onClick={() => {
                    onFolderClick(data?.id);
                    folderID = data?.id;
                    fetchData(data?.id);
                  }}
                >
                  <div className=" flex flex-row items-center text-start gap-2">
                    {typeToIconMap[data?.type] || <FaFile />}
                    <p>
                      {data?.name.length > 20
                        ? data?.name.substring(0, 20) + "..."
                        : data?.name}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          ))
        )}
      </ul>
    </aside>
  );
};

export default SideMenu;
