import { useState, useCallback } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";

const DraggableHeaderCell = ({
  headerName,
  onMoveColumn,
  columnIndex,
}: any) => {
  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN",
    item: { index: columnIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "COLUMN",
    hover: (draggedItem: any) => {
      if (draggedItem.index !== columnIndex) {
        onMoveColumn(draggedItem.index, columnIndex);
        draggedItem.index = columnIndex;
      }
    },
  });

  const style = {
    cursor: "move",
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={(node) => drag(drop(node))} style={style}>
      {headerName}
    </div>
  );
};

const DataTable = ({ data, setSelectedRows }: any) => {
  const [filterText, setFilterText] = useState("");
  const [columns, setColumns] = useState(
    Object?.keys(data[0] || []).map((key) => ({
      field: key,
      headerName: key,
      flex: 1,
    }))
  );

  const filteredData = data?.filter((item: any) =>
    Object?.values(item)?.some((value) =>
      String(value)?.toLowerCase()?.includes(filterText?.toLowerCase())
    )
  );

  const handleSelectionModelChange = (selectionModel: any) => {
    setSelectedRows(selectionModel);
  };

  const handleColumnOrderChange = useCallback(
    (sourceIndex: any, destinationIndex: any) => {
      const newColumns = [...columns];
      const [movedColumn] = newColumns.splice(sourceIndex, 1);
      newColumns.splice(destinationIndex, 0, movedColumn);
      setColumns(newColumns);
    },
    [columns]
  );

  const gridColumns = columns.map((column, index) => ({
    ...column,
    renderHeader: () => (
      <DraggableHeaderCell
        field={column.field}
        headerName={column.headerName}
        onMoveColumn={handleColumnOrderChange}
        columnIndex={index}
      />
    ),
  }));

  return (
    <div className="flex flex-col h-full">
      <TextField
        label="Filter"
        variant="outlined"
        size="small"
        fullWidth
        value={filterText}
        onChange={(e: any) => setFilterText(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <DndProvider backend={HTML5Backend}>
        <div
          className="   max-h-screen overflow-scroll "
          style={{ maxHeight: "calc(100vh - 230px)" }}
        >
          <DataGrid
            rows={filteredData}
            columns={gridColumns}
            checkboxSelection
            onRowSelectionModelChange={handleSelectionModelChange}
          />
        </div>
      </DndProvider>
    </div>
  );
};

export default DataTable;
