import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { MdOutlineDelete } from "react-icons/md";
import api from "../../../../api";
import { RiDeleteBinLine } from "react-icons/ri";

function EditAgent({
  nameEdit,
  logoEdit,
  descriptionEdit,
  idEdit,

  closeEditAgent,
  EngineEdit,
  MilvusEdit,
  ModelEdit,
  typeEdit,
  subcategoryIdEdit,
  subcategoryNameEdit,
  llmEdit,
  setRefresh,
  xCloseEditAgent,
}: any) {
  interface TagData {
    id: string;
    tag_name: string;
  }
  const [tagData, setTagData] = useState<TagData[] | null>(null);
  const user_id = localStorage.getItem("user_id") || "default_user";

  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState(nameEdit);
  const [description, setDescription] = useState(descriptionEdit);
  const [page, setPage] = useState("features");
  const [milvusData, setMilvusData] = useState(MilvusEdit);
  const [modelData, setModelData] = useState(ModelEdit);
  const [engineData, setEngineData] = useState(EngineEdit);
  const [refreshTag, setRefreshTag] = useState(0);
  const [location, SetLocation] = useState<string[]>([]);
  const [locationInput, setLocationInput] = useState("");
  const [availableEmails, setAvailableEmails] = useState<string[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  useEffect(() => {
    if (subcategoryIdEdit === 2) {
      const apiUrl = `${process.env.REACT_APP_LIST_EMAILS}`;
      const formData = new FormData();
      formData.append("user_id", user_id);

      api
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setAvailableEmails(response.data);
        })
        .catch((error) => {
          console.error("Error fetching emails:", error);
        });
    }

    const fetchData = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_AGENT_LOCATIONS_OR_EMAILS}${idEdit}`
        );
        const data = response.data;
        if (subcategoryIdEdit === 1) {
          SetLocation(Array.isArray(data.data) ? data.data : []);
        } else if (subcategoryIdEdit === 2) {
          setSelectedEmails(Array.isArray(data.data) ? data.data : []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEmailSelect = (email: string) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails((prevEmails) => prevEmails.filter((e) => e !== email));
    } else {
      setSelectedEmails((prevEmails) => [...prevEmails, email]);
    }
  };

  const handleEmailDelete = (email: string) => {
    setSelectedEmails((prevEmails) => prevEmails.filter((e) => e !== email));
  };

  const handlelocationChange = (valueToAdd: string) => {
    if (valueToAdd.trim() !== "") {
      if (!location.includes(valueToAdd)) {
        SetLocation((prevLocation) => [...prevLocation, valueToAdd]);
      }
      setLocationInput("");
    }
  };

  const handleAgentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", idEdit);

    if (page === "advanced") {
      formData.append("feature_type", "advanced_features");
      formData.append("agent_type_name", typeEdit);
      formData.append("milvus_config", JSON.stringify(milvusData));
      formData.append("model_config", JSON.stringify(modelData));
      formData.append("engine_config", JSON.stringify(engineData));
    } else if (page === "features") {
      formData.append("feature_type", "main_features");
      formData.append("description", description);
      formData.append("agent", name);
      formData.append("model_name", llmEdit);

      if (subcategoryIdEdit === 1) {
        formData.append("locations", JSON.stringify(location));
      } else if (subcategoryIdEdit === 2) {
        formData.append("emails", JSON.stringify(selectedEmails));
      }

      if (file !== null) {
        formData.append("logo", file);
      }
    }

    try {
      await api.post(`${process.env.REACT_APP_UPDATE_AGENT}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("Data Agent has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while updating the data agent", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  const handleSettingChange = (settingKey: string, value: string) => {
    setMilvusData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };
  const handleModelSettingChange = (settingKey: string, value: string) => {
    setModelData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };

  const handleEngineSettingChange = (settingKey: string, value: string) => {
    setEngineData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_TAGS_BY_AGENT}${idEdit}`
        );
        const data = response.data;
        setTagData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [refreshTag]);

  const handleDeleteTag = async (id: string) => {
    const formData = new FormData();
    formData.append("tag_id", id);

    try {
      await api.post(`${process.env.REACT_APP_DELETE_TAG}`, formData);
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("Tag has been deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while deleting the tag", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={closeEditAgent}
      role="button"
    >
      <div className="bg-white p-4 relative rounded shadow-2xl max-h-[95%] overflow-y-scroll">
        <button
          onClick={xCloseEditAgent}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">
          Edit Data Agent
        </p>
        <div className="my-[21px]">
          <button
            onClick={() => setPage("features")}
            className={`mr-2 px-4 py-2 rounded ${
              page === "features"
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Features
          </button>
          <button
            onClick={() => setPage("advanced")}
            className={`mr-2 px-4 py-2 rounded ${
              page === "advanced"
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Advanced Features
          </button>
          {tagData && tagData.length > 0 && (
            <button
              onClick={() => setPage("tags")}
              className={`px-4 py-2 rounded ${
                page === "tags"
                  ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              Tags
            </button>
          )}
        </div>
        {page === "features" && (
          <form
            onSubmit={handleEditSubmit}
            className=" md:w-[750px] w-[350px]  "
          >
            <div className="p-4 flex flex-col gap-4 max-h-[450px] overflow-y-scroll">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={handleAgentChange}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col lg:flex-row lg:items-center lg:justify-between w-full">
                <div className=" flex flex-col lg:w-[40%]">
                  <label className="pb-2 text-[20px]">Type:</label>
                  <p>{typeEdit}</p>
                </div>
                <div className=" flex flex-col lg:w-[40%]">
                  <label className="pb-2 text-[20px]">Selected Model:</label>
                  <p>{llmEdit}</p>
                </div>
              </div>

              {typeEdit === "RAG" && (
                <div className=" flex flex-col">
                  <label className="pb-2 text-[20px]">Sub Category:</label>
                  <p>{subcategoryNameEdit}</p>
                </div>
              )}
              {subcategoryIdEdit === 1 && (
                <div className=" flex flex-col ">
                  <label className="pb-2 text-[20px]">Location Path:</label>
                  <div className=" w-full flex flex-row">
                    <input
                      type="text"
                      value={locationInput}
                      onChange={(e) => setLocationInput(e.target.value)}
                      placeholder="Add Location Path"
                      className="border-[2px] md:w-[664px] w-[265px] text-black outline-none p-1 rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        handlelocationChange(locationInput);
                        setLocationInput("");
                      }}
                      className="bg-[#4D30B5] hover:bg-[#1b1436] duration-300 text-white px-2 py-1 ml-2 rounded-lg"
                    >
                      Add
                    </button>
                  </div>
                  {location.length > 0 && (
                    <div className="mt-2 h-[80px] max-w-[800px] overflow-y-scroll">
                      {location?.map((key, index) => (
                        <div key={index}>
                          <div className=" flex flex-row items-center">
                            {key}
                            <button
                              type="button"
                              onClick={() => {
                                SetLocation((prevLocation) =>
                                  prevLocation.filter((_, i) => i !== index)
                                );
                              }}
                            >
                              <RiDeleteBinLine className="text-red-600 hover:text-red-700 duration-300 ml-2" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {subcategoryIdEdit === 2 && (
                <div className="flex flex-col">
                  <label className="pb-2 text-[20px]">Select Emails:</label>
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-2  overflow-y-scroll">
                    {availableEmails.map((email) => (
                      <button
                        key={email}
                        onClick={(e) => {
                          e.preventDefault();
                          handleEmailSelect(email);
                        }}
                        className={` mb-2 py-2 rounded ${
                          selectedEmails.includes(email)
                            ? "bg-[#4D30B5]  text-white"
                            : "bg-gray-300 text-gray-600"
                        }`}
                      >
                        {email}
                      </button>
                    ))}
                  </div>
                  {selectedEmails && selectedEmails.length > 0 && (
                    <div className="mt-2">
                      <p className="pb-2 text-[20px]">Selected Emails:</p>
                      <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                        {selectedEmails.map((email) => (
                          <div
                            key={email}
                            className="flex flex-row items-center mb-2"
                          >
                            <p>{email}</p>
                            <button onClick={() => handleEmailDelete(email)}>
                              <RiDeleteBinLine className="text-red-600 hover:text-red-700 duration-300" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Description:</label>
                <textarea
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="border-[2px] text-black rounded-lg outline-none p-1 resize-none h-[140px] custom-scrollbar-chat"
                />
              </div>

              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Image:</label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Edit Data Agent
              </button>
            </div>
          </form>
        )}
        {page === "advanced" && (
          <form
            onSubmit={handleEditSubmit}
            className=" md:w-[750px] w-[350px] "
          >
            <div className="  justify-center items-center max-h-[450px] overflow-y-scroll">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Setting
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(milvusData || {})?.map(
                    ([setting, value]: any) => (
                      <tr key={setting}>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {setting}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap outline-none">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleSettingChange(setting, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                  {Object.entries(modelData || {})?.map(
                    ([setting, value]: any) => (
                      <tr key={setting}>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {setting}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap outline-none">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleModelSettingChange(setting, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                  {Object.entries(engineData || {})?.map(
                    ([setting, value]: any) => (
                      <tr key={setting}>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {setting}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap outline-none">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleEngineSettingChange(setting, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Edit Data Agent
              </button>
            </div>
          </form>
        )}
        {page === "tags" && (
          <div className=" md:w-[750px] w-[350px]  ">
            <div className="p-4 flex flex-col gap-4">
              <div className="p-4">
                <div className="grid grid-cols-3">
                  {tagData?.map((tag) => (
                    <div
                      key={tag.id}
                      className="flex flex-row justify-between col-span-1 bg-[#EDEAF8] p-2 m-2 rounded-lg  duration-300 "
                    >
                      <div>{tag.tag_name}</div>
                      {tag.tag_name !== "Default" && (
                        <button
                          className=" bg-red-500 hover:bg-red-700 text-white h-[27px] p-1 rounded duration-300"
                          onClick={() => handleDeleteTag(tag.id)}
                        >
                          <MdOutlineDelete />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default EditAgent;
