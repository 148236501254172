import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import EditModel from "./EditModel";
import AddModel from "./AddModel";
import { ImSpinner } from "react-icons/im";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { fetchData, getPermissionForName } from "../../../../GlobalFunctions";

let currentIdDatabase = 0;

function Models({ userPermissions }: any) {
  interface MindModelData {
    id: string;
    model_name: string;
    logo: string;
    description: string;
    max_tokens: string;
    training_data_upto: string;
    llm_id: string;
    global_settings: { key: string; temp: string };
  }

  const [mindModelData, setMindModelData] = useState<MindModelData[] | null>(
    null
  );

  const user_id = localStorage.getItem("user_id") || "default_user";

  const [name, setName] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [modelEditOpen, setModelEditOpen] = useState(false);

  const openModel = () => {
    setIsModelOpen(true);
  };

  const openEditModel = (id: number) => {
    setModelEditOpen(true);
    currentIdDatabase = id;
  };

  const closeEditModel = () => {
    setModelEditOpen(false);
  };

  const handleModelEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeEditModel();
    }
  };

  const closeModel = () => {
    setName("");
    setFile(null);
    setIsModelOpen(false);
  };

  const handleModelClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModel();
    }
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_LLMS_BY_USER}${user_id}`,
      setMindModelData
    );
  }, []);

  const isAllowEdit =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_edit", "LLM Models");

  return (
    <div>
      {/* <div className="flex justify-end mb-3">
        <button
          onClick={openModel}
          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
        >
          Create Model
        </button>
      </div> */}
      <div>
        {mindModelData && mindModelData.length > 0 ? (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {mindModelData?.map((data, index) => (
              <div
                onClick={
                  userPermissions && isAllowEdit
                    ? () => openEditModel(index)
                    : undefined
                }
                role="button"
                key={index}
                className={`relative ${
                  isAllowEdit ? "cursor-pointer" : ""
                }  flex flex-col gap-4 p-8 m-4 items-center hover:bg-gray-400 rounded-lg duration-300 bg-[#EDEAF8]`}
              >
                {isAllowEdit && (
                  <button
                    onClick={() => openEditModel(index)}
                    className="absolute right-5 bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                  >
                    <BiEdit />
                  </button>
                )}

                <div className="flex flex-row gap-4">
                  <AsyncImage
                    className=" rounded-lg "
                    src={`data:image/jpg;base64,${data.logo}`}
                    alt={data.model_name}
                    style={{ width: 120, height: 120 }}
                    loading="lazy"
                    Transition={Blur}
                  />
                </div>
                <div className=" flex  items-center">
                  <p className=" text-[25px]">{data.model_name}</p>
                </div>
                <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
                  <p className="text-center">{data.description}</p>
                </div>
                {/* <p className="text-center">Max tokens: {data.max_tokens}</p> */}
                <p className="text-center">
                  {/* Knowledge up to: {data.training_data_upto} */}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center">
            <ImSpinner size={30} className=" animate-spin" />
          </div>
        )}
      </div>

      {isModelOpen && (
        <AddModel handleModelClick={handleModelClick} closeModel={closeModel} />
      )}

      {mindModelData
        ? modelEditOpen &&
          userPermissions &&
          isAllowEdit && (
            <EditModel
              handleModelEditClick={handleModelEditClick}
              closeEditModel={closeEditModel}
              modelEdit={mindModelData[currentIdDatabase].model_name}
              maxTokensEdit={mindModelData[currentIdDatabase].max_tokens}
              knowledgeUpToEdit={
                mindModelData[currentIdDatabase].training_data_upto
              }
              descriptionEdit={mindModelData[currentIdDatabase].description}
              idEdit={mindModelData[currentIdDatabase].id}
              logoEdit={mindModelData[currentIdDatabase].logo}
              llm_id={mindModelData[currentIdDatabase].llm_id}
              kEdit={mindModelData[currentIdDatabase].global_settings?.key}
              tempEdit={mindModelData[currentIdDatabase].global_settings?.temp}
            />
          )
        : null}
    </div>
  );
}

export default Models;
