import { useState, useEffect } from "react";
import { ImSpinner } from "react-icons/im";
import api from "../../../../api";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";

// let currentIdDatabase = 0;

function Colletions() {
  interface MindCollectionData {
    id: string;
    name: string;
    description: string;
    logo: string;
  }

  const [mindCollectionData, setMindCollectionData] = useState<
    MindCollectionData[] | null
  >(null);

  // const [name, setName] = useState("");

  // const [isAgentOpen, setIsAgentOpen] = useState(false);
  // const [agentEditOpen, setAgentEditOpen] = useState(false);

  // const openAgent = () => {
  //   setIsAgentOpen(true);
  // };

  // const openEditAgent = (id: number) => {
  //   setAgentEditOpen(true);
  //   currentIdDatabase = id;
  // };

  // const closeEditAgent = () => {
  //   setAgentEditOpen(false);
  // };

  // const handleAgentEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   if (e.target === e.currentTarget) {
  //     closeEditAgent();
  //   }
  // };

  // const closeAgent = () => {
  //   setName("");
  //   setIsAgentOpen(false);
  // };

  // const handleAgentClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   if (e.target === e.currentTarget) {
  //     closeAgent();
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_LIST_COLLECTION}`
        );
        const data = response.data;
        setMindCollectionData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* <div className="flex justify-end mb-3">
        <button
          onClick={openAgent}
          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
        >
          Upload Collection
        </button>
      </div> */}
      {mindCollectionData && mindCollectionData.length > 0 ? (
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {mindCollectionData?.map((data, index) => (
            <div
              // onClick={() => openEditAgent(index)}
              key={index}
              className="relative cursor-pointer flex flex-col gap-4 p-8 m-4 items-center hover:bg-gray-400 rounded-lg duration-300 bg-[#EDEAF8]"
            >
              {/* <button
                onClick={() => openEditAgent(index)}
                className="absolute right-5 bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
              >
                <BiEdit />
              </button> */}
              <div className="flex flex-row gap-4">
                <AsyncImage
                  className=" h-[120px] object-cover rounded-lg "
                  src={`data:image/jpg;base64,${data.logo}`}
                  alt={data.name}
                  loading="lazy"
                  Transition={Blur}
                  style={{ width: 120, height: 120 }}
                />
              </div>
              <div className=" flex  items-center">
                <p className=" text-[25px]">{data.name}</p>
              </div>
              <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
                <p className="text-center">{data.description}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className=" animate-spin flex justify-center">
          <ImSpinner size={30} />
        </div>
      )}
      {/* {isAgentOpen && (
        <AddCollection
          handleAgentClick={handleAgentClick}
          closeAgent={closeAgent}
        />
      )} */}
      {/* {mindAgentData
        ? agentEditOpen && (
            <EditCollection
              handleAgentEditClick={handleAgentEditClick}
              closeEditAgent={closeEditAgent}
              nameEdit={mindAgentData[currentIdDatabase].name}
              ipAddressEdit={mindAgentData[currentIdDatabase].name}
              descriptionEdit={mindAgentData[currentIdDatabase].description}
              idEdit={mindAgentData[currentIdDatabase].id}
            />
          )
        : null} */}
    </div>
  );
}

export default Colletions;
