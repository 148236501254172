import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import { ImSpinner, ImSpinner2 } from "react-icons/im";

function EditAgent({
  nameEdit,
  modelName,
  descriptionEdit,
  idEdit,
  xCloseEditAgent,
  closeEditAgentGroup,
  MilvusEdit,
  ModelEdit,
  modelIdEdit,
  setRefresh,
}: any) {
  interface MindAgentData {
    id: string;
    agent: string;
  }
  const user_id = localStorage.getItem("user_id") || "default_user";

  const [agents, setAgents] = useState<MindAgentData[] | null>(null);
  const [checkedAgents, setCheckedAgents] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState(nameEdit);
  const [milvusData, setMilvusData] = useState(MilvusEdit);
  const [modelData, setModelData] = useState(ModelEdit);

  const [description, setDescription] = useState(descriptionEdit);
  const [tabChange, setTabChange] = useState("general");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("group_id", idEdit);
    formData.append("model_id", modelIdEdit);

    if (tabChange === "general") {
      formData.append("feature_type", "main_features");
      formData.append("description", description);
      formData.append("group_name", name);
      formData.append("agent_type_name", "4");
      if (file !== null) {
        formData.append("logo", file);
      }
    } else if (tabChange === "agents") {
      formData.append("feature_type", "main_features");
      formData.append("agents_ids", JSON.stringify(checkedAgents));
      formData.append("agent_type_name", "4");
    } else if (tabChange === "advanced features") {
      formData.append("feature_type", "advanced_features");
      formData.append("milvus_config", JSON.stringify(milvusData));
      formData.append("model_config", JSON.stringify(modelData));
    }

    try {
      await api.post(`${process.env.REACT_APP_EDIT_AGENT_GROUP}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("Data Agent Group has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while updating the data agent group", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  const handleTabClick = async (temp: any) => {
    setCheckedAgents([]);

    setLoading1(true);
    setLoading2(true);

    setTabChange(temp);
    if (temp === "general" || temp === "advanced features") {
      setLoading1(false);
      setLoading2(false);
    } else if (temp === "agents") {
      try {
        const responseAgents = await api.get(
          `${process.env.REACT_APP_RAG_AGENT_BY_USER}${user_id}`
        );
        const dataAgents = responseAgents.data;
        const filteredData = dataAgents.filter(
          (name: MindAgentData) => name.agent !== "All Data"
        );
        setAgents(filteredData);

        const responseCheckedAgents = await api.get(
          `${process.env.REACT_APP_CHECKED_AGENT}${idEdit}`
        );
        const dataCheckedAgents = responseCheckedAgents.data;
        setCheckedAgents(dataCheckedAgents.map((agent: any) => agent.id));

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleAgentsCheckBoxChange = (id: string) => {
    setCheckedAgents((prevAgents) => {
      if (prevAgents.includes(id)) {
        return prevAgents.filter((agent_id) => agent_id !== id);
      } else {
        return [...prevAgents, id];
      }
    });
  };

  const handleAgentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleSettingChange = (settingKey: string, value: string) => {
    setMilvusData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };

  const handleModelSettingChange = (settingKey: string, value: string) => {
    setModelData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={closeEditAgentGroup}
      role="button"
    >
      <div
        className={`relative bg-white p-4 rounded shadow-2xl ${
          tabChange !== "advanced features" && "overflow-scroll"
        } min-h-[330px] max-h-[90%] md:w-[750px] w-[350px]`}
      >
        <button
          onClick={xCloseEditAgent}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] p-1 duration-300 rounded-lg text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>
        <p className="text-[25px] text-center content-center">
          Edit Data Group
        </p>
        <div className="mb-4 mt-2 grid grid-cols-2 lg:grid-cols-3 gap-1">
          <button
            onClick={() => handleTabClick("general")}
            className={`mr-2 px-4 py-2 rounded ${
              tabChange === "general"
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            General
          </button>
          <button
            onClick={() => handleTabClick("agents")}
            className={`mr-2 px-4 py-2 rounded ${
              tabChange === "agents"
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Agents
          </button>
          <button
            onClick={() => handleTabClick("advanced features")}
            className={`mr-2 px-4 py-2 rounded ${
              tabChange === "advanced features"
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Advanced Features
          </button>
        </div>

        {loading1 === true || loading2 === true ? (
          <div className=" flex justify-center items-center pt-16">
            <ImSpinner2 size={25} className="animate-spin" />
          </div>
        ) : (
          <>
            {tabChange === "general" && (
              <form onSubmit={handleEditSubmit}>
                <div className="p-4 flex flex-col gap-4">
                  <div className=" flex flex-col ">
                    <label className="pb-2 text-[20px]">Name:</label>
                    <input
                      required
                      type="text"
                      value={name}
                      onChange={handleAgentChange}
                      className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                    />
                  </div>
                  <div className=" flex flex-col">
                    <label className="pb-2 text-[20px]">Selected Model:</label>
                    <p>{modelName}</p>
                  </div>

                  <div className=" flex flex-col ">
                    <label className="pb-2 text-[20px]">Description:</label>
                    <textarea
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="border-[2px] text-black rounded-lg outline-none p-1 resize-none h-[140px] custom-scrollbar-chat"
                    />
                  </div>

                  <div className=" flex flex-col ">
                    <label className="pb-2 text-[20px]">Image:</label>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="mt-4 flex justify-center">
                    <button
                      type="submit"
                      className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                    >
                      Edit Data Group Agents
                    </button>
                  </div>
                </div>
              </form>
            )}
            {tabChange === "agents" && (
              <form onSubmit={handleEditSubmit}>
                <div className="p-4 flex flex-col gap-4">
                  <div>
                    <label className="pb-2 text-[20px]">Agents:</label>
                    <div className="z-10 bg-gray-100 rounded-lg shadow w-full mt-2 ">
                      <ul
                        className=" px-3 pb-3 overflow-y-auto text-sm md:grid md:grid-cols-3"
                        aria-labelledby="dropdownSearchButton"
                      >
                        {agents && agents.length > 0 ? (
                          agents?.map((agent, index) => {
                            if (checkedAgents?.includes(agent?.id)) {
                              return (
                                <li key={index}>
                                  <div className=" flex items-center p-2 rounded">
                                    <input
                                      defaultChecked
                                      id="checkbox-item-13"
                                      type="checkbox"
                                      value=""
                                      className="w-4 h-4 "
                                      onChange={() =>
                                        handleAgentsCheckBoxChange(agent?.id)
                                      }
                                    />
                                    <label className="w-full ml-2 text-sm font-medium ">
                                      {agent?.agent}
                                    </label>
                                  </div>
                                </li>
                              );
                            } else {
                              return (
                                <li key={index}>
                                  <div className="flex items-center p-2 rounded ">
                                    <input
                                      id="checkbox-item-14"
                                      type="checkbox"
                                      value={agent?.id}
                                      className="w-4 h-4 text-blue-600 "
                                      onChange={() =>
                                        handleAgentsCheckBoxChange(agent?.id)
                                      }
                                    />
                                    <label className="w-full ml-2 text-sm font-medium ">
                                      {agent?.agent}
                                    </label>
                                  </div>
                                </li>
                              );
                            }
                          })
                        ) : (
                          <div className=" animate-spin flex justify-center">
                            <ImSpinner size={30} />
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="submit"
                      className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                    >
                      Edit Data Group Agents
                    </button>
                  </div>
                </div>
              </form>
            )}
            {tabChange === "advanced features" && (
              <form onSubmit={handleEditSubmit}>
                <div className="p-4 flex flex-col gap-4">
                  <label className="pb-2 text-[20px]">Advanced Features:</label>
                  <div className="border-2 max-h-[350px] overflow-scroll">
                    <table className=" min-w-full divide-y divide-gray-200 ">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Setting
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {Object.entries(milvusData || {}).map(
                          ([setting, value]: any) => (
                            <tr key={setting}>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                {setting}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap outline-none">
                                <input
                                  type="text"
                                  value={value}
                                  onChange={(e) =>
                                    handleSettingChange(setting, e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          )
                        )}
                        {Object.entries(modelData || {}).map(
                          ([setting, value]: any) => (
                            <tr key={setting}>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                {setting}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap outline-none">
                                <input
                                  type="text"
                                  value={value}
                                  onChange={(e) =>
                                    handleModelSettingChange(
                                      setting,
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-4 flex justify-center">
                    <button
                      type="submit"
                      className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                    >
                      Edit Data Group Agents
                    </button>
                  </div>
                </div>
              </form>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default EditAgent;
