import { Link, useLocation } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import { IoIosMenu } from "react-icons/io";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";

function NavBar({ changeSideActive, userData }: any) {
  const location = useLocation();

  return (
    <nav className=" flex flex-row justify-between items-center bg-[#20144C] border-b border-[#C5D4EB] p-4 h-[70px]">
      <div className=" uppercase text-[white] font-semibold text-[15px]">
        {location.pathname
          .slice(1)
          .replaceAll("-", " ")
          .substring(
            0,
            location.pathname.slice(1).indexOf("/") > 0
              ? location.pathname.slice(1).indexOf("/")
              : location.pathname.length
          )}
      </div>
      <div className="flex flex-row gap-10 max-sm:gap-2 items-center">
        <div className="flex flex-row gap-2 items-center text-[white]">
          <Link to={"/profile"}>
            <div>
              {userData?.data?.profile_picture ? (
                <AsyncImage
                  className="rounded-full w-[40px] h-[40px] object-cover"
                  src={`data:image/jpg;base64,${userData?.data?.profile_picture}`}
                  alt={userData?.data.user_name}
                  style={{ width: 50, height: 50 }}
                  Transition={Blur}
                  loading="lazy"
                />
              ) : (
                <BiUserCircle className=" text-[35px] max-lg:text-[25px]" />
              )}
            </div>
          </Link>
          <div className=" font-semibold text-[16px] max-lg:hidden">
            {userData?.data?.user_name}
          </div>
          <div className=" lg:hidden mt-1">
            <button onClick={() => changeSideActive()}>
              <IoIosMenu size={25} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
