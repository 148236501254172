import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { ImSpinner } from "react-icons/im";
import api from "../api";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";

const Agent = ({
  showAgent,
  agentActive,
  onSelectAgent,
  setSelectedAgent,
  selectedAgent,
  setSelectedAgentId,
  setCanResetTag,
  handleGroupSelectedChangeTrue,
  handleGroupSelectedChangeFalse,
}: any) => {
  interface MindAgentData {
    id: string;
    agent: string;
    logo: string;
    description: string;
  }

  interface MindAgentGroupData {
    id: string;
    name: string;
    description: string;
    logo: string;
  }

  const user_id = localStorage.getItem("user_id") || "default_user";

  const [mindAgentData, setMindAgentData] = useState<MindAgentData[] | null>(
    null
  );
  const [mindAgentGroupData, setMindAgentGroupData] = useState<
    MindAgentGroupData[] | null
  >(null);
  const [tabChange, setTabChange] = useState("Data Agents");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    if (agentActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [agentActive]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading1(true);
      setLoading2(true);
      try {
        const responseAgents = await api.get(
          `${process.env.REACT_APP_AGENTS_BY_USER}${user_id}`
        );
        const dataAgents = responseAgents.data;
        setMindAgentData(dataAgents);

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleTabClick = async (temp: any) => {
    setLoading1(true);
    setLoading2(true);

    setTabChange(temp);
    if (temp === "Data Agents") {
      try {
        const responseAgents = await api.get(
          `${process.env.REACT_APP_AGENTS_BY_USER}${user_id}`
        );
        const dataAgents = responseAgents.data;
        setMindAgentData(dataAgents);

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (temp === "Group Agents") {
      try {
        const responseGroups = await api.get(
          `${process.env.REACT_APP_GROUP_AGENTS_BY_USER}${user_id}`
        );
        const dataGroups = responseGroups.data;
        setMindAgentGroupData(dataGroups);

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleChildClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <AnimatePresence>
      <motion.div
        onClick={showAgent}
        initial={{ opacity: 0, x: -1 }}
        whileInView={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -1 }}
        viewport={{ once: false }}
        transition={{ duration: 0.7 }}
        className={
          agentActive
            ? " fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
            : "hidden"
        }
        role="button"
      >
        <div
          className=" bg-[#EDEAF8] rounded-lg shadow-2xl"
          onClick={handleChildClick}
          role="button"
        >
          <div className=" md:w-[750px] w-[350px]">
            <div className=" flex flex-col ">
              <div className="relative flex flex-row items-center justify-between p-4">
                <button
                  onClick={showAgent}
                  className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] p-1 duration-300 rounded-lg text-white absolute top-4 right-4"
                >
                  <RxCross2 size={25} className="-mt-[1px]" />
                </button>
                <div className="mb-4 mt-2 grid grid-cols-2 lg:grid-cols-2 gap-1">
                  <button
                    onClick={() => handleTabClick("Data Agents")}
                    className={`mr-2 px-4 py-2 rounded ${
                      tabChange === "Data Agents"
                        ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                        : "bg-gray-300 text-gray-600"
                    }`}
                  >
                    Data Agents
                  </button>
                  <button
                    onClick={() => handleTabClick("Group Agents")}
                    className={`mr-2 px-4 py-2 rounded ${
                      tabChange === "Group Agents"
                        ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                        : "bg-gray-300 text-gray-600"
                    }`}
                  >
                    Group Agents
                  </button>
                </div>
              </div>

              <div className=" border-b" />
            </div>
            {tabChange === "Data Agents" && (
              <div className=" max-h-[600px] overflow-y-scroll ">
                <div className=" p-4 grid grid-cols-3 gap-4 ">
                  <div
                    className=" flex flex-col items-center p-2 gap-2 cursor-pointer hover:scale-105 duration-300"
                    onClick={() => {
                      setSelectedAgent("");
                      setSelectedAgentId("");
                      onSelectAgent("");
                      showAgent();
                      handleGroupSelectedChangeFalse();
                    }}
                    role="button"
                  >
                    <div
                      className={`border p-4 rounded-full bg-[#21557b] ${
                        selectedAgent === ""
                          ? "border-[#ac4e01] border-[3px]"
                          : " border-[#21557b]"
                      }`}
                    ></div>
                    <div className=" max-sm:text-[10px] text-black">
                      No Agent
                    </div>
                  </div>
                  {mindAgentData ? (
                    mindAgentData?.map((user) => (
                      <div
                        key={user?.id}
                        className=" flex flex-col items-center p-2 gap-2 cursor-pointer hover:scale-105 duration-300 "
                        onClick={() => {
                          setSelectedAgent(user.agent);
                          setSelectedAgentId(user.id);
                          onSelectAgent(user.agent);
                          setCanResetTag("1");
                          showAgent();
                          handleGroupSelectedChangeFalse();
                        }}
                        role="button"
                      >
                        <div
                          className={`border p-4 rounded-full bg-[#21557b] ${
                            selectedAgent === user.agent
                              ? "border-[#ac4e01] border-[3px]"
                              : " border-[#21557b]"
                          }`}
                        >
                          <AsyncImage
                            src={`data:image/jpg;base64,${user.logo}`}
                            loading="lazy"
                            style={{ width: 45, height: 45, borderRadius: 3 }}
                            Transition={Blur}
                            alt={user.agent}
                            className=" rounded-lg h-[45px] object-cover"
                            loader={<div style={{ background: "#21557b" }} />}
                          />
                        </div>
                        <div className=" max-sm:text-[10px] text-black">
                          {user.agent}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="  flex justify-center items-center">
                      <ImSpinner size={30} className="animate-spin" />
                    </div>
                  )}
                </div>
              </div>
            )}
            {tabChange === "Group Agents" && (
              <div className=" max-h-[600px] overflow-y-scroll ">
                <div className=" p-4 grid grid-cols-3 gap-4 ">
                  {mindAgentGroupData ? (
                    mindAgentGroupData?.map((group) => (
                      <div
                        key={group?.id}
                        className=" flex flex-col items-center p-2 gap-2 cursor-pointer hover:scale-105 duration-300 "
                        onClick={() => {
                          setSelectedAgent(group.name);
                          setSelectedAgentId(group.id);
                          onSelectAgent(group.name);
                          setCanResetTag("1");
                          showAgent();
                          handleGroupSelectedChangeTrue();
                        }}
                        role="button"
                      >
                        <div
                          className={`border p-4 rounded-full bg-[#21557b] ${
                            selectedAgent === group.name
                              ? "border-[#ac4e01] border-[3px]"
                              : " border-[#21557b]"
                          }`}
                        >
                          <AsyncImage
                            src={`data:image/jpg;base64,${group.logo}`}
                            loading="lazy"
                            style={{ width: 45, height: 45, borderRadius: 3 }}
                            Transition={Blur}
                            alt={group.name}
                            className=" rounded-lg h-[45px] object-cover"
                            loader={<div style={{ background: "#21557b" }} />}
                          />
                        </div>
                        <div className=" max-sm:text-[10px] text-black">
                          {group.name}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="  flex justify-center items-center">
                      <ImSpinner size={30} className="animate-spin" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Agent;
