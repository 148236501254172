import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../api";
import Cookies from "js-cookie";
import { createTag, fetchTagsByAgent } from "../../../GlobalFunctions";

interface AgentData {
  id: string;
  agent: string;
}
interface TagData {
  id: string;
  tag_name: string;
}

function Milvus({
  handleMilvusClick,
  selectedFoldersId,
  selectedFiles,
  closeMilvusSend,
}: any) {
  const user_id = localStorage.getItem("user_id");
  const [tagData, setTagData] = useState<TagData[] | null>(null);
  const [agentData, setAgentData] = useState<AgentData[] | null>(null);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [newTagName, setNewTagName] = useState("");
  const [loading, setLoading] = useState(false);
  const token = Cookies.get("access_token");

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_UNSTRUCTURED_AGENT_BY_USER}${user_id}`;

    api
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        setAgentData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleCreateTag = () => {
    createTag(
      newTagName,
      selectedAgent,
      setNewTagName,
      setSelectedTag,
      setTagData
    );
  };

  useEffect(() => {
    fetchTagsByAgent(selectedAgent, setTagData);
  }, [selectedAgent]);

  const handleNewTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagName(e.target.value);
    setSelectedAgent(selectedAgent);
  };

  const handleAgentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTag("");
    setSelectedAgent(e.target.value);
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTag(e.target.value);
  };

  const handleSendMilvus = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GOOGLE_DRIVE_DOWNLOAD}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            file_id:
              selectedFoldersId.length > 0 ? selectedFoldersId : selectedFiles,
            user_id: user_id,
            data_agent_id: selectedAgent,
            tag_id: selectedTag,
          }),
        }
      );

      if (response.ok) {
        toast.success("Files has been uploaded successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      closeMilvusSend();
    }
  };

  return (
    <div
      onClick={handleMilvusClick}
      role="button"
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
    >
      <div className="bg-[#EDEAF8] rounded-lg shadow-2xl relative">
        <button
          onClick={closeMilvusSend}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>
        <div className=" flex flex-col ">
          <div className=" flex items-center justify-center">
            <p className="p-6 font-bold">Upload Files To Milvus?</p>
          </div>
          <div className=" border-b" />
          <div className=" flex flex-row items-center justify-center px-8 gap-5 md:w-[750px] w-[350px]">
            <div className=" flex flex-col gap-4 py-4 ">
              <label>Select Data Agent</label>
              <select
                required
                value={selectedAgent}
                onChange={handleAgentChange}
                className=" text-black outline-none p-1 rounded-lg hover:bg-gray-200 duration-300 cursor-pointer"
              >
                <option value="" disabled>
                  Select a Data Agent
                </option>
                {agentData?.map((llm) => (
                  <option
                    data-agentname={llm.agent}
                    key={llm.id}
                    value={llm.id}
                  >
                    {llm.agent}
                  </option>
                ))}
              </select>
            </div>
            {selectedAgent && (
              <div className=" flex flex-col gap-4 py-4 ">
                <label className="">Select Tag:</label>
                <div className="flex items-center">
                  <select
                    required
                    value={selectedTag}
                    onChange={handleTagChange}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  >
                    <option value="" disabled>
                      Select a Tag
                    </option>
                    {tagData?.map((tag) => (
                      <option key={tag.id} value={tag.id}>
                        {tag.tag_name}
                      </option>
                    ))}
                    <option value="createNewTag">Create New Tag</option>
                  </select>
                  {selectedTag === "createNewTag" && (
                    <div className="ml-4 flex flex-row gap-2">
                      <input
                        type="text"
                        placeholder="New Tag Name"
                        value={newTagName}
                        onChange={handleNewTagChange}
                        className="border-[2px] text-black outline-none p-1 rounded-lg"
                      />
                      <button
                        onClick={handleCreateTag}
                        disabled={newTagName === ""}
                        className={` ${
                          newTagName === "" ? "bg-[#20144C]" : "bg-[#413183]"
                        } text-white px-2 py-1 rounded-md hover:bg-[#20144C] duration-300`}
                      >
                        Create Tag
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className=" flex justify-center gap-4 p-6">
            <button
              onClick={() => {
                handleSendMilvus();
              }}
              className={`${
                selectedAgent === "" ||
                selectedTag === "" ||
                selectedTag === "createNewTag" ||
                loading === true
                  ? "bg-[#1b1436]"
                  : "bg-[#4D30B5]"
              } rounded-md p-2 text-white duration-300`}
              disabled={
                selectedAgent === "" ||
                selectedTag === "" ||
                selectedTag === "createNewTag" ||
                loading === true
              }
            >
              {loading ? (
                <AiOutlineLoading3Quarters
                  size={20}
                  className=" animate-spin"
                />
              ) : (
                "Upload To Milvus"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Milvus;
