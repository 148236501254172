// import { load } from "mime";
import { useState, useEffect } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../api";
import Cookies from "js-cookie";
import { createTag, fetchTagsByAgent } from "../../../GlobalFunctions";

function SyncData({ closePopUp, closeSyncData, selectedEmails, option }: any) {
  interface AgentData {
    id: string;
    agent: string;
  }
  interface TagData {
    id: string;
    tag_name: string;
  }

  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [tagData, setTagData] = useState<TagData[] | null>(null);
  const [agentData, setAgentData] = useState<AgentData[] | null>(null);
  const [newTagName, setNewTagName] = useState("");
  const token = Cookies.get("access_token");

  const handleNewTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagName(e.target.value);
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API}${process.env.REACT_APP_EMAIL_AGENT_BY_USER}${user_id}`;

    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data: AgentData[]) => setAgentData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleCreateTag = () => {
    createTag(
      newTagName,
      selectedAgent,
      setNewTagName,
      setSelectedTag,
      setTagData
    );
  };

  useEffect(() => {
    fetchTagsByAgent(selectedAgent, setTagData);
  }, [selectedAgent]);

  const handleAgentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAgent(e.target.value);
    setSelectedTag("");
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTag(e.target.value);
  };

  const syncEmails = async () => {
    if (selectedEmails.length > 0) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_OUTLOOK_RETRIEVE_EMAIL_BY_ID}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: user_id,
              email_id: selectedEmails,
              data_agent_id: selectedAgent,
              tag_id: selectedTag,
            }),
          }
        );

        if (response.ok) {
          // const jsonData = await response.json();
          setLoading(false);
          toast.success("Emails synced successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
        } else {
          console.error("Failed to fetch data from Email");
          setLoading(false);
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_OUTLOOK_RETRIEVE_ALL_EMAILS}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: user_id,
              data_agent_id: selectedAgent,
              tag_id: selectedTag,
            }),
          }
        );

        if (response.ok) {
          // const jsonData = await response.json();
          setLoading(false);
          toast.success("Emails synced successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
        } else {
          console.error("Failed to fetch data from Email");
          setLoading(false);
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={closePopUp}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeSyncData}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">{option}</p>
        <div className=" md:w-[750px] w-[350px] flex flex-row gap-4 ">
          <div className=" flex flex-col gap-4 py-4 ">
            <label className="ml-1">Select Data Agent:</label>
            <select
              required
              value={selectedAgent}
              onChange={handleAgentChange}
              className="border-[2px] text-black outline-none p-1 rounded-lg"
            >
              <option value="" disabled>
                Select a Data Agent
              </option>
              {agentData?.map((llm) => (
                <option key={llm.id} value={llm.id}>
                  {llm.agent}
                </option>
              ))}
            </select>
          </div>
          {selectedAgent && (
            <div className=" flex flex-col gap-4 py-4 ">
              <label className="ml-1">Select Tag:</label>
              <div className="flex items-center">
                <select
                  required
                  value={selectedTag}
                  onChange={handleTagChange}
                  className="border-[2px] text-black outline-none p-1 rounded-lg"
                >
                  <option value="" disabled>
                    Select a Tag
                  </option>
                  {tagData?.map((tag) => (
                    <option key={tag.id} value={tag.id}>
                      {tag.tag_name}
                    </option>
                  ))}
                  <option value="createNewTag">Create New Tag</option>
                </select>
                {selectedTag === "createNewTag" && (
                  <div className="ml-4 flex flex-row gap-2">
                    <input
                      type="text"
                      placeholder="New Tag Name"
                      value={newTagName}
                      onChange={handleNewTagChange}
                      className="border-[2px] text-black outline-none p-1 rounded-lg"
                    />
                    <button
                      onClick={handleCreateTag}
                      className="bg-[#4D30B5] text-white px-2 py-1 rounded-md hover:bg-[#1b1436] duration-300"
                    >
                      Create Tag
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-center mt-5">
          <button
            disabled={
              selectedAgent === "" ||
              selectedTag === "" ||
              selectedTag === "createNewTag" ||
              loading === true
            }
            onClick={() => {
              syncEmails();
              setLoading(true);
            }}
            className={`${
              selectedAgent === "" ||
              selectedTag === "" ||
              selectedTag === "createNewTag" ||
              loading === true
                ? "bg-[#1b1436]"
                : "bg-[#4D30B5]"
            } ${
              loading === true ? "px-4" : "px-4"
            } text-white py-2  rounded-md hover:bg-[#1b1436] duration-300`}
          >
            {loading === true ? (
              <div>
                <AiOutlineLoading3Quarters
                  size={20}
                  className=" animate-spin  max-sm:mr-4"
                />
              </div>
            ) : (
              "Sync"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SyncData;
