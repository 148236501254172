import { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import api from "../../../../api";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import AddGroupAgent from "./AddGroupAgent";
import DeleteGroupAgent from "./DeleteGroupAgent";
import { ImSpinner } from "react-icons/im";
import EditAgentGroup from "./EditAgentGroup";
import { fetchData, getPermissionForName } from "../../../../GlobalFunctions";

let currentIdDatabase = 0;

function GroupAgents({ userPermissions }: any) {
  interface MindAgentGroupData {
    id: string;
    name: string;
    model_id: string;
    model_name: string;
    description: string;
    logo: string;
    global_settings: { Milvus: any; Model: any };
  }

  const [mindAgentGroupData, setMindAgentGroupData] = useState<
    MindAgentGroupData[] | null
  >(null);

  const user_id = localStorage.getItem("user_id") || "default_user";

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const [isAgentGroupOpen, setIsAgentGroupOpen] = useState(false);
  const [agentGroupEditOpen, setAgentGroupEditOpen] = useState(false);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openDeleteAllButton = (id: number) => {
    currentIdDatabase = id;
    setOpenDeleteAll(true);
  };

  const closeDeleteCancelButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setOpenDeleteAll(false);
    }
  };

  const closeDeleteButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setMindAgentGroupData(null);
      setOpenDeleteAll(false);
    }
  };

  const openGroupAgent = () => {
    setIsAgentGroupOpen(true);
  };

  const openEditAgentGroup = (id: number) => {
    setAgentGroupEditOpen(true);
    currentIdDatabase = id;
  };

  const closeEditAgentGroup = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setAgentGroupEditOpen(false);
    }
  };

  const xCloseEditAgent = (e: React.MouseEvent<HTMLDivElement>) => {
    setAgentGroupEditOpen(false);
  };

  const closeAgentGroup = () => {
    setName("");
    setFile(null);
    setIsAgentGroupOpen(false);
  };

  const handleAgentGroupClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeAgentGroup();
    }
  };

  useEffect(() => {
    setOpenDeleteAll(false);
    setAgentGroupEditOpen(false);

    const fetchData = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_GROUP_AGENTS_BY_USER}${user_id}`
        );
        const data = response.data;
        setMindAgentGroupData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh]);

  const isAllowAdd =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_add", "Group Agents");

  const isAllowEdit =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_edit", "Group Agents");

  const isAllowDelete =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_delete", "Group Agents");

  return (
    <div>
      {loading ? (
        <div className="flex justify-center">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      ) : (
        <>
          <div className="flex flex-row gap-3 justify-end mb-3">
            {userPermissions && isAllowAdd && (
              <button
                onClick={openGroupAgent}
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
              >
                Create Group
              </button>
            )}
          </div>
          {mindAgentGroupData && mindAgentGroupData.length > 0 ? (
            <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {mindAgentGroupData?.map((data, index) => (
                <div
                  role="button"
                  onClick={
                    userPermissions && isAllowEdit
                      ? () => openEditAgentGroup(index)
                      : undefined
                  }
                  key={index}
                  className={`relative ${
                    isAllowEdit ? "cursor-pointer" : ""
                  }  flex flex-col gap-4 p-8 m-4 items-center hover:bg-gray-400 rounded-lg duration-300 bg-[#EDEAF8]`}
                >
                  {userPermissions && isAllowDelete && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteAllButton(index);
                      }}
                      className="absolute right-5 bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <MdDelete />
                    </button>
                  )}
                  <div className="flex flex-row gap-4">
                    <AsyncImage
                      className=" h-[120px] object-cover rounded-lg "
                      src={`data:image/jpg;base64,${data.logo}`}
                      alt={data.name}
                      loading="lazy"
                      Transition={Blur}
                      style={{ width: 120, height: 120, borderRadius: 3 }}
                      loader={<div style={{ background: "#EDEAF8" }} />}
                    />
                  </div>
                  <div className=" flex  items-center">
                    <p className=" text-[25px]">{data.name}</p>
                  </div>
                  <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
                    <p className="text-center">{data.description}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className=" flex justify-center items-center">
              <p>No Data Found!</p>
            </div>
          )}
          {isAgentGroupOpen && userPermissions && isAllowAdd && (
            <AddGroupAgent
              handleAgentGroupClick={handleAgentGroupClick}
              closeAgentGroup={closeAgentGroup}
              setRefresh={setRefresh}
            />
          )}
          {mindAgentGroupData
            ? openDeleteAll &&
              userPermissions &&
              isAllowDelete && (
                <DeleteGroupAgent
                  closeDeleteButton={closeDeleteButton}
                  closeDeleteCancelButton={closeDeleteCancelButton}
                  idEdit={mindAgentGroupData[currentIdDatabase].id}
                  nameEdit={mindAgentGroupData[currentIdDatabase].name}
                  setRefresh={setRefresh}
                />
              )
            : null}
          {mindAgentGroupData
            ? agentGroupEditOpen &&
              userPermissions &&
              isAllowEdit && (
                <EditAgentGroup
                  xCloseEditAgent={xCloseEditAgent}
                  closeEditAgentGroup={closeEditAgentGroup}
                  nameEdit={mindAgentGroupData[currentIdDatabase].name}
                  descriptionEdit={
                    mindAgentGroupData[currentIdDatabase].description
                  }
                  idEdit={mindAgentGroupData[currentIdDatabase].id}
                  modelIdEdit={mindAgentGroupData[currentIdDatabase].model_id}
                  modelName={mindAgentGroupData[currentIdDatabase].model_name}
                  logoEdit={mindAgentGroupData[currentIdDatabase].logo}
                  ModelEdit={
                    mindAgentGroupData[currentIdDatabase].global_settings?.Model
                  }
                  MilvusEdit={
                    mindAgentGroupData[currentIdDatabase].global_settings
                      ?.Milvus
                  }
                  setRefresh={setRefresh}
                />
              )
            : null}
        </>
      )}
    </div>
  );
}

export default GroupAgents;
