import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";

function EmailInfo({
  closePopUp,
  closeSyncData,
  emailData,
  selectedAgent,
}: any) {
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [data, setData] = useState<any | null>(null);
  const token = Cookies.get("access_token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_RETREIVE_EMAIL_BY_SOURCE}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: user_id,
              data_agent_name: selectedAgent,
              source: emailData,
            }),
          }
        );

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error("Failed to fetch data from Email");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, []);

  const [expandedEmailId, setExpandedEmailId] = useState<string | null>(null);

  const handleReadMoreClick = (emailId: string) => {
    setExpandedEmailId((prevId) => (prevId === emailId ? null : emailId));
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={closePopUp}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeSyncData}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>
        <div className=" md:w-[750px] w-[350px] flex flex-row gap-4 ">
          {data ? (
            <div className=" py-2">
              <div className="p-4">
                <div className="flex justify-between">
                  <div className="flex flex-row gap-2">
                    <div className=" font-semibold">From:</div>
                    <div>{data.Sender}</div>
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className=" font-semibold">To:</div>
                  <div>{data.Receiver}</div>
                </div>

                <div className="flex flex-row gap-2">
                  <div className=" font-semibold">CC:</div>
                  <div>{data?.CC}</div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className=" font-semibold">BCC:</div>
                  <div>{data?.BCC}</div>
                </div>

                <div className="flex flex-row gap-2">
                  <div className=" font-semibold">Subject:</div>
                  <div>{data?.Subject}</div>
                </div>

                <div className="flex flex-col mt-5">
                  <div className=" font-semibold">Body:</div>
                  <div>
                    {expandedEmailId === data.messageId
                      ? data["Email Body"]
                      : data["Email Body"]?.length > 500
                      ? data["Email Body"]?.slice(0, 500) + "..."
                      : data["Email Body"]}
                    {data["Email Body"]?.length > 500 && (
                      <button
                        className="text-blue-700 ml-2 underline"
                        onClick={() => handleReadMoreClick(data.messageId)}
                      >
                        {expandedEmailId === data?.messageId
                          ? "Read Less"
                          : "Read More"}
                      </button>
                    )}
                  </div>
                </div>

                <div className="flex flex-row gap-2 mt-5">
                  <div className=" font-semibold">Date:</div>
                  <div>{data?.Date}</div>
                </div>
              </div>
            </div>
          ) : (
            <div>Couldn't fetch data.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailInfo;
