import api from "../../api";

function DeleteHistory({
  handleButtonClick,
  selectedRoomId,
  title,
  setMessageList,
  setHistoryMainData,
  setRefresh,
  setOpenDelete,
  setSelectedRoomId,
  setSelectedAgent,
  setSelectedModel,
  setSelectedAgentId,
  setPdfName,
  setSearchEngine,
  setSelectedTag,
  setSelectedTagName,
  setGroupSelected,
  setFile,
  AgentName,
  AgentID,
}: any) {
  const handleDeleteRoom = async () => {
    const formData = new FormData();
    formData.append("room_id", selectedRoomId);

    try {
      await api.post(`${process.env.REACT_APP_DELETE_ROOM}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {}
    setRefresh((prevRefresh: any) => prevRefresh + 1);
    setSelectedRoomId(Math.floor(Math.random() * 1e9));
    setMessageList([]);
    setHistoryMainData([]);
    setSelectedTag("");
    setSelectedTagName("");
    setPdfName("");
    setSearchEngine("0");
    setSelectedAgent(AgentName);
    setSelectedModel("gpt-4");
    setSelectedAgentId(AgentID);
    setOpenDelete(false);
    setGroupSelected(false);
    setFile(null);
  };

  return (
    <div
      onClick={handleButtonClick}
      role="button"
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md px-4"
    >
      <div className="bg-[#EDEAF8] rounded-lg shadow-2xl w-[420px]">
        <div className=" flex flex-col ">
          <p className="p-6 font-bold">Delete Chat?</p>
          <div className=" border-b border-gray-400" />
          {title && (
            <div className="mt-8 pb-2 px-6">
              <span>This will delete </span>
              <span className=" font-bold">{title}</span>
            </div>
          )}
          <div className=" flex flex-row justify-end gap-4 p-6">
            <button
              onClick={handleButtonClick}
              className=" bg-white rounded-md p-2 hover:bg-[#b5b1c4]  duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteRoom}
              className=" bg-red-700 rounded-md p-2 text-white hover:bg-red-900 duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteHistory;
