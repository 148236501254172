import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { ImSpinner } from "react-icons/im";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { fetchData } from "../GlobalFunctions";

const Model = ({
  showModel,
  modelActive,
  onSelectModel,
  selectedModel,
  setSelectedModel,
}: any) => {
  interface MindModelData {
    id: string;
    model_name: string;
    logo: string;
    description: string;
    max_tokens: string;
    training_data_upto: string;
    llm_id: string;
  }
  // const [selectedModel, setSelectedModel] = useState<string>(selectedModel1);
  const [mindModelData, setMindModelData] = useState<MindModelData[] | null>(
    null
  );

  const user_id = localStorage.getItem("user_id") || "default_user";

  useEffect(() => {
    if (modelActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modelActive]);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_LLMS_BY_USER}${user_id}`,
      setMindModelData
    );
  }, []);

  const handleChildClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <AnimatePresence>
      <motion.div
        onClick={showModel}
        initial={{ opacity: 0, x: -1 }}
        whileInView={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -1 }}
        viewport={{ once: false }}
        transition={{ duration: 0.7 }}
        className={
          modelActive
            ? " fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
            : "hidden"
        }
        role="button"
      >
        <div
          className=" bg-[#EDEAF8] rounded-lg shadow-2xl"
          onClick={handleChildClick}
          role="button"
        >
          <div className=" md:w-[750px] w-[350px]">
            <div className=" flex flex-col ">
              <div className="relative flex flex-row items-center justify-between p-4">
                <p className="text-black">Models</p>
                <button
                  onClick={showModel}
                  className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
                >
                  <RxCross2 size={25} className="-mt-[1px]" />
                </button>
              </div>
              <div className=" border-b" />
            </div>
            <div className="max-h-[600px] overflow-y-scroll ">
              {mindModelData && mindModelData.length > 0 ? (
                <div className=" p-4 grid grid-cols-3 gap-4 ">
                  {mindModelData?.map((model) => (
                    <div
                      key={model?.id}
                      className=" flex flex-col items-center p-2 gap-2 cursor-pointer hover:scale-105 duration-300"
                      onClick={() => {
                        setSelectedModel(model?.model_name);
                        onSelectModel(model?.model_name);
                        showModel();
                      }}
                      role="button"
                    >
                      <div
                        className={`border p-4 rounded-full bg-[#21557b] ${
                          selectedModel === model.model_name
                            ? "border-[#ac4e01] border-[3px]"
                            : " border-[#21557b]"
                        }`}
                      >
                        <AsyncImage
                          src={`data:image/jpg;base64,${model.logo}`}
                          loading="lazy"
                          Transition={Blur}
                          alt={model.model_name}
                          style={{ width: 45, height: 45, borderRadius: 3 }}
                          className=" rounded-lg h-[45px]"
                          loader={<div style={{ background: "#21557b" }} />}
                        />
                      </div>
                      <div className=" max-sm:text-[10px] text-black">
                        {model.model_name}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="  flex justify-center h-[400px]">
                  <ImSpinner size={30} className="animate-spin" />
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Model;
