import { useState, useEffect } from "react";
import EditAgent from "./EditAgent";
import AddAgent from "./AddAgent";
import { ImSpinner } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import DeleteAgent from "./DeleteAgent";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { fetchData, getPermissionForName } from "../../../../GlobalFunctions";

let currentIdDatabase = 0;

function Agents({ userPermissions }: any) {
  interface MindAgentData {
    id: string;
    agent: string;
    logo: string;
    description: string;
    agent_type_name: string;
    subcategory_id: string;
    subcategory_name: string;
    llm_model: string;
    agent_settings: { Milvus: any; Engine: any; Model: any };
  }

  const [mindAgentData, setMindAgentData] = useState<MindAgentData[] | null>(
    null
  );

  const user_id = localStorage.getItem("user_id") || "default_user";

  const [name, setName] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [isAgentOpen, setIsAgentOpen] = useState(false);
  const [agentEditOpen, setAgentEditOpen] = useState(false);
  const [openDeleteAll, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openDeleteButton = (id: number) => {
    currentIdDatabase = id;
    setOpenDelete(true);
  };

  const closeDeleteCancelButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setOpenDelete(false);
    }
  };

  const closeDeleteButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setMindAgentData(null);
      setOpenDelete(false);
    }
  };

  const openAgent = () => {
    setIsAgentOpen(true);
  };

  const openEditAgent = (id: number) => {
    setAgentEditOpen(true);
    currentIdDatabase = id;
  };

  const closeEditAgent = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setAgentEditOpen(false);
    }
  };

  const xCloseEditAgent = (e: React.MouseEvent<HTMLDivElement>) => {
    setAgentEditOpen(false);
  };

  const closeAgent = () => {
    setName("");
    setFile(null);
    setIsAgentOpen(false);
  };

  const handleAgentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeAgent();
    }
  };

  useEffect(() => {
    setOpenDelete(false);
    setAgentEditOpen(false);
    fetchData(
      `${process.env.REACT_APP_AGENTS_BY_USER}${user_id}`,
      setMindAgentData
    );
  }, [refresh]);

  const isAllowAdd =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_add", "Data Agents");

  const isAllowEdit =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_edit", "Data Agents");

  const isAllowDelete =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_delete", "Data Agents");

  return (
    <div>
      <div className="flex flex-row gap-3 justify-end mb-3">
        {userPermissions && isAllowAdd && (
          <button
            onClick={openAgent}
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
          >
            Create Data Agent
          </button>
        )}
      </div>
      <div>
        {mindAgentData && mindAgentData.length > 0 ? (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {mindAgentData?.map((data, index) => (
              <div
                role="button"
                onClick={
                  userPermissions && isAllowEdit
                    ? () => openEditAgent(index)
                    : undefined
                }
                key={index}
                className={`relative ${
                  isAllowEdit ? "cursor-pointer" : ""
                }  flex flex-col gap-4 p-8 m-4 items-center hover:bg-gray-400 rounded-lg duration-300 bg-[#EDEAF8]`}
              >
                {userPermissions && isAllowDelete && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      openDeleteButton(index);
                    }}
                    className="absolute right-5 bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                  >
                    <MdDelete />
                  </button>
                )}
                <div className="flex flex-row gap-4">
                  <AsyncImage
                    className=" h-[120px] object-cover rounded-lg "
                    src={`data:image/jpg;base64,${data.logo}`}
                    alt={data.agent}
                    loading="lazy"
                    Transition={Blur}
                    style={{ width: 120, height: 120, borderRadius: 3 }}
                    loader={<div style={{ background: "#EDEAF8" }} />}
                  />
                </div>
                <div className=" flex  items-center">
                  <p className=" text-[25px]">{data.agent}</p>
                </div>
                <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
                  <p className="text-center">{data.description}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center">
            <ImSpinner size={30} className=" animate-spin" />
          </div>
        )}
      </div>
      {isAgentOpen && userPermissions && isAllowAdd && (
        <AddAgent
          handleAgentClick={handleAgentClick}
          closeAgent={closeAgent}
          setRefresh={setRefresh}
        />
      )}
      {mindAgentData
        ? openDeleteAll &&
          userPermissions &&
          isAllowDelete && (
            <DeleteAgent
              closeDeleteButton={closeDeleteButton}
              idEdit={mindAgentData[currentIdDatabase]?.id}
              nameEdit={mindAgentData[currentIdDatabase].agent}
              setRefresh={setRefresh}
              closeDeleteCancelButton={closeDeleteCancelButton}
            />
          )
        : null}
      {mindAgentData
        ? agentEditOpen &&
          userPermissions &&
          isAllowEdit && (
            <EditAgent
              xCloseEditAgent={xCloseEditAgent}
              closeEditAgent={closeEditAgent}
              nameEdit={mindAgentData[currentIdDatabase].agent}
              descriptionEdit={mindAgentData[currentIdDatabase].description}
              idEdit={mindAgentData[currentIdDatabase].id}
              logoEdit={mindAgentData[currentIdDatabase].logo}
              typeEdit={mindAgentData[currentIdDatabase].agent_type_name}
              subcategoryIdEdit={
                mindAgentData[currentIdDatabase].subcategory_id
              }
              subcategoryNameEdit={
                mindAgentData[currentIdDatabase].subcategory_name
              }
              llmEdit={mindAgentData[currentIdDatabase].llm_model}
              ModelEdit={mindAgentData[currentIdDatabase].agent_settings?.Model}
              MilvusEdit={
                mindAgentData[currentIdDatabase].agent_settings?.Milvus
              }
              EngineEdit={
                mindAgentData[currentIdDatabase].agent_settings?.Engine
              }
              setRefresh={setRefresh}
            />
          )
        : null}
    </div>
  );
}

export default Agents;
