import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import { ImSpinner } from "react-icons/im";

function AddGroupAgent({
  handleAgentGroupClicks,
  closeAgentGroup,
  setRefresh,
}: any) {
  interface MindAgentData {
    id: string;
    agent: string;
    logo: string;
    description: string;
    agent_type_name: string;
    llm_model: string;
  }

  interface MindModelData {
    id: string;
    model_name: string;
    logo: string;
    description: string;
    max_tokens: string;
    training_data_upto: string;
    llm_id: string;
    global_settings: { key: string; temp: string };
  }

  const [name, setName] = useState("");
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [mindAgentData, setMindAgentData] = useState<MindAgentData[] | null>(
    null
  );
  const [mindModelData, setMindModelData] = useState<MindModelData[] | null>(
    null
  );
  const [milvusData, setMilvusData] = useState<any | null>(null);
  const [modelData, setModelData] = useState<any | null>(null);

  const user_id = localStorage.getItem("user_id") || "default_user";

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_RAG_AGENT_BY_USER}${user_id}`;

    api
      .get(apiUrl)
      .then((response) => {
        const filteredData = response.data.filter(
          (agent: MindAgentData) => agent.agent !== "All Data"
        );
        setMindAgentData(filteredData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleAgentCheckboxChange = (id: string) => {
    const updatedSelectedAgents = selectedAgents.includes(id)
      ? selectedAgents.filter((agentId) => agentId !== id)
      : [...selectedAgents, id];
    setSelectedAgents(updatedSelectedAgents);
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_DEFAULT_SETTINGS}1`;

    api
      .get(apiUrl)
      .then((response) => {
        setMilvusData(response.data.Milvus);
        setModelData(response.data.Model);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleSettingChange = (settingKey: string, value: string) => {
    setMilvusData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };

  const handleModelSettingChange = (settingKey: string, value: string) => {
    setModelData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };

  const [showFeatures, setShowFeatures] = useState(true);

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(e.target.value);
  };
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_LLMS_BY_USER}${user_id}`;

    api
      .get(apiUrl)
      .then((response) => setMindModelData(response.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleFeaturesClick = () => {
    setShowFeatures(true);
  };

  const handleAdvancedFeaturesClick = () => {
    setShowFeatures(false);
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (/[ !@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]/.test(name)) {
      toast.error("Group name must not include spaces or special characters!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    if (!file) {
      toast.error("Please select an image!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    const formData = new FormData();
    formData.append("group_name", name);
    formData.append("logo", file);
    formData.append("description", description);
    formData.append("user_id", user_id);
    formData.append("agents_ids", JSON.stringify(selectedAgents));
    formData.append("model_id", selectedModel);
    formData.append("agent_type_name", "4");
    formData.append("milvus_config", JSON.stringify(milvusData));
    formData.append("model_config", JSON.stringify(modelData));

    try {
      await api.post(`${process.env.REACT_APP_CREATE_GROUP_AGENT}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      closeAgentGroup();

      toast.success("Group has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while creating the Group: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while creating the Group", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error creating the Group:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={handleAgentGroupClicks}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeAgentGroup}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">Create Group</p>
        <div className="mb-4">
          <button
            onClick={handleFeaturesClick}
            className={`mr-2 px-4 py-2 rounded ${
              showFeatures
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Features
          </button>
          <button
            onClick={handleAdvancedFeaturesClick}
            className={`px-4 py-2 rounded ${
              !showFeatures
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Advanced Features
          </button>
        </div>
        {showFeatures ? (
          <form
            onSubmit={handleUploadSubmit}
            className=" md:w-[750px] w-[350px] max-h-[600px] overflow-y-scroll"
          >
            <div className="p-4 flex flex-col gap-4">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Group Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">Select Data Agents:</label>
                {mindAgentData && mindAgentData?.length > 0 ? (
                  <div className=" grid grid-cols-2 lg:grid-cols-4 gap-2">
                    {mindAgentData &&
                      mindAgentData.map((agent) => (
                        <div key={agent.id} className=" items-center">
                          <input
                            type="checkbox"
                            id={agent.id}
                            checked={selectedAgents.includes(agent.id)}
                            onChange={() => handleAgentCheckboxChange(agent.id)}
                          />
                          <label htmlFor={agent.id} className="ml-2">
                            {agent.agent}
                          </label>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className=" animate-spin flex justify-center">
                    <ImSpinner size={30} />
                  </div>
                )}
              </div>
              <div className=" flex flex-col">
                <label className="pb-2 text-[20px]">Select Model:</label>
                <select
                  required
                  value={selectedModel}
                  onChange={handleModelChange}
                  className="border-[2px] text-black outline-none p-1 rounded-lg"
                >
                  <option value="" disabled>
                    Select a Model
                  </option>
                  {mindModelData?.map((model) => (
                    <option key={model?.id} value={model?.id}>
                      {model?.model_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Description:</label>
                <textarea
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="border-[2px] text-black rounded-lg outline-none p-1 resize-none h-[140px] custom-scrollbar-chat"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Image:</label>
                <input
                  required
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mt-4 flex justify-center">
                <button
                  type="submit"
                  className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                >
                  Create Group
                </button>
              </div>
            </div>
          </form>
        ) : (
          <form
            onSubmit={handleUploadSubmit}
            className=" md:w-[750px] w-[350px] h-[568px]"
          >
            <div className=" flex flex-col justify-center items-center">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Setting
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(milvusData || {}).map(
                    ([setting, value]: any) => (
                      <tr key={setting}>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {setting}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap outline-none">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleSettingChange(setting, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                  {Object.entries(modelData || {}).map(
                    ([setting, value]: any) => (
                      <tr key={setting}>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {setting}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap outline-none">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleModelSettingChange(setting, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="mt-4 flex justify-center">
                <button
                  type="submit"
                  className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                >
                  Create Group
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default AddGroupAgent;
