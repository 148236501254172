import { toast } from "react-toastify";
import GoogleDrive from "../../../../Assets/Google_Drive_Logo_512px.png";
import Dropbox from "../../../../Assets/Dropbox.png";
import OneDrive from "../../../../Assets/OneDrive.png";
import api from "../../../api";
import Cookies from "js-cookie";

const CloudConnectors = ({ userData }: any) => {
  const user_id = localStorage.getItem("user_id") || "default_user";
  const token = Cookies.get("access_token");

  const handleGoogleDriveRequest = async () => {
    try {
      const response = await api.post(
        // "https://12hw5wfe97.execute-api.eu-north-1.amazonaws.com/default/authenticateGoogleDrive",
        `${process.env.REACT_APP_DRIVE_GGOGLE_LOGIN}`,
        {
          user_id: user_id,
        }
      );
      const { login_url } = JSON.parse(response.data.body);
      if (login_url) {
        window.open(login_url, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleDropBoxRequest = async () => {
    try {
      const response = await api.post(
        // "https://jh6eexj880.execute-api.eu-north-1.amazonaws.com/default/authdropbox",
        `${process.env.REACT_APP_DROPBOX_LOGIN}`,
        {
          user_id: user_id,
        }
      );
      const { login_url } = JSON.parse(response.data.body);
      if (login_url) {
        window.open(login_url, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleOneDriveRequest = async () => {
    try {
      const response = await api.post(
        // "https://42rtebg9gj.execute-api.eu-north-1.amazonaws.com/v1/login",
        `${process.env.REACT_APP_ONE_DRIVE_LOGIN}`,
        {
          user_id: user_id,
        }
      );
      const { login_url } = JSON.parse(response.data.body);
      if (login_url) {
        window.open(login_url, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const disconnectConnector = async (temp: any) => {
    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("cloud_id", temp);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_DISCONNECT_DRIVES}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      if (response.ok) {
        window.location.reload();
      } else {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Something Went Wrong!", error);
    }
  };

  return (
    <div className=" flex justify-center">
      <section className=" grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div
          className={` flex flex-col lg:w-[500px] gap-4 p-8 m-4 items-center  rounded-lg duration-300 bg-[#EDEAF8]`}
        >
          <div className="flex flex-row gap-4">
            <img
              className=" w-[80px] h-[70px]"
              src={Dropbox}
              alt="Dropbox"
              loading="lazy"
            />
          </div>
          <div className=" flex  items-center pt-3">
            <p className=" text-[25px]">DropBox</p>
          </div>
          <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
            <p className="text-center">Connect your DropBox Data</p>
          </div>
          {userData?.data.connected_to_dropbox === true ? (
            <div className=" px-4">
              <button
                className=" bg-[#135991] hover:bg-[#152a3b] duration-300 text-white p-2 rounded-lg"
                onClick={() => disconnectConnector("3")}
              >
                Disconnect
              </button>
            </div>
          ) : (
            <div className=" px-4">
              <button
                className=" bg-[#135991] hover:bg-[#152a3b] duration-300 text-white p-2 rounded-lg"
                onClick={handleDropBoxRequest}
              >
                Connect
              </button>
            </div>
          )}
        </div>
        {/* <div className=" flex flex-row items-center justify-between bg-white rounded-lg">
          <div className="flex flex-col items-center gap-2">
            <div className=" flex flex-row items-center gap-5 bg-white rounded-lg p-4">
              <SlSocialDropbox size={35} />
              <p className=" text-[18px]">DropBox</p>
            </div>
          </div>
          {userData?.data.connected_to_dropbox === true ? (
            <div className=" px-4">
              <button
                className=" bg-[#EDEAF8] p-2 rounded-lg"
                onClick={() => disconnectConnector("3")}
              >
                Disconnect
              </button>
            </div>
          ) : (
            <div className=" px-4">
              <button
                className=" bg-[#EDEAF8] p-2 rounded-lg"
                onClick={handleDropBoxRequest}
              >
                Connect
              </button>
            </div>
          )}
        </div> */}
        <div
          className={` flex flex-col lg:w-[500px] gap-4 p-8 m-4 items-center  rounded-lg duration-300 bg-[#EDEAF8]`}
        >
          <div className="flex flex-row gap-4">
            <img
              className=" w-[80px] h-[70px]"
              src={GoogleDrive}
              alt="Google Drive"
              loading="lazy"
            />
          </div>
          <div className=" flex  items-center pt-3">
            <p className=" text-[25px]">Google Drive</p>
          </div>
          <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
            <p className="text-center">Connect your Google Drive Data</p>
          </div>
          {userData?.data.connected_to_google_drive === true ? (
            <div className=" px-4">
              <button
                className=" bg-[#135991] hover:bg-[#152a3b] duration-300 text-white p-2 rounded-lg"
                onClick={() => disconnectConnector("1")}
              >
                Disconnect
              </button>
            </div>
          ) : (
            <div className=" px-4">
              <button
                className=" bg-[#135991] hover:bg-[#152a3b] duration-300 text-white p-2 rounded-lg"
                onClick={handleGoogleDriveRequest}
              >
                Connect
              </button>
            </div>
          )}
        </div>
        <div
          className={` flex flex-col lg:w-[500px] gap-4 p-8 m-4 items-center  rounded-lg duration-300 bg-[#EDEAF8]`}
        >
          <div className="flex flex-row gap-4">
            <img
              className=" w-[80px] h-[60px]"
              src={OneDrive}
              alt="OneDrive"
              loading="lazy"
            />
          </div>
          <div className=" flex  items-center pt-3">
            <p className=" text-[25px]">One Drive</p>
          </div>
          <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
            <p className="text-center">Connect your One Drive Data</p>
          </div>
          {userData?.data.connected_to_onedrive === true ? (
            <div className=" px-4">
              <button
                className=" bg-[#135991] hover:bg-[#152a3b] duration-300 text-white p-2 rounded-lg"
                onClick={() => disconnectConnector("2")}
              >
                Disconnect
              </button>
            </div>
          ) : (
            <div className=" px-4">
              <button
                className=" bg-[#135991] hover:bg-[#152a3b] duration-300 text-white p-2 rounded-lg"
                onClick={handleOneDriveRequest}
              >
                Connect
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default CloudConnectors;
