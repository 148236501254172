import { useEffect, useState } from "react";
import EditRole from "./EditRole";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import AddRole from "./AddRole";
import DeleteRole from "./DeleteRole";
import { Link } from "react-router-dom";
import { fetchData, getPermissionForName } from "../../../../GlobalFunctions";

let currentIdDatabase = 0;

function Roles({ userPermissions, setRefreshUserData, refreshUserData }: any) {
  interface RoleData {
    role_id: string;
    role_name: string;
  }

  const [roleData, setRoleData] = useState<RoleData[] | null>(null);
  const [userEditOpen, setUserEditOpen] = useState(false);
  const [isDeleteRoleOpen, setIsDeleteRoleOpen] = useState(false);
  const [isRoleOpen, setIsRoleOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openDeleteRole = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteRoleOpen(true);
  };

  const closeDeleteRole = () => {
    setIsDeleteRoleOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteRole();
    }
  };

  useEffect(() => {
    setIsDeleteRoleOpen(false);
    setUserEditOpen(false);
    fetchData(`${process.env.REACT_APP_ROLES_DETAILS}`, setRoleData);
  }, [refresh, refreshUserData]);

  const openRole = () => {
    setIsRoleOpen(true);
  };

  const handleRoleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeRole();
    }
  };

  const closeRole = () => {
    setIsRoleOpen(false);
  };

  const isAllowAdd =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_add", "Roles");

  const isAllowEdit =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_edit", "Roles");

  const isAllowDelete =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_delete", "Roles");

  return (
    <div>
      {userPermissions && isAllowAdd && (
        <div className="flex justify-end mb-3">
          <button
            onClick={openRole}
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
          >
            Create Role
          </button>
        </div>
      )}
      <div className=" overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left">
          <thead className="border-b bg-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3 w-20">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              {userPermissions ? (
                isAllowEdit || isAllowDelete ? (
                  <th scope="col" className="px-6 py-3 w-[180px]">
                    role management
                  </th>
                ) : null
              ) : null}
            </tr>
          </thead>
          <tbody>
            {roleData?.map((data, index) => (
              <tr key={index} className="bg-gray-200 border-b ">
                <td className="px-6 py-4">{data.role_id}</td>
                <td className="px-6 py-4">{data.role_name}</td>
                {userPermissions ? (
                  isAllowEdit || isAllowDelete ? (
                    <td className="px-6 py-4 flex flex-row gap-2 w-40">
                      {isAllowEdit && (
                        <Link
                          to={`/roles/edit_role/${data.role_id}`}
                          className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                        >
                          <BiEdit />
                        </Link>
                      )}

                      {isAllowDelete &&
                        data.role_name !== "user" &&
                        data.role_name !== "admin" && (
                          <button
                            onClick={() => openDeleteRole(index)}
                            className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                          >
                            <MdDelete />
                          </button>
                        )}
                    </td>
                  ) : null
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
        {userPermissions &&
          isAllowEdit &&
          (roleData ? userEditOpen && <EditRole /> : null)}
        {userPermissions && isAllowAdd && isRoleOpen && (
          <AddRole
            handleRoleClick={handleRoleClick}
            closeRole={closeRole}
            setRefresh={setRefresh}
            setRefreshUserData={setRefreshUserData}
          />
        )}
        {userPermissions && isAllowDelete && roleData && isDeleteRoleOpen && (
          <DeleteRole
            handleDeleteClick={handleDeleteClick}
            closeDeleteRole={closeDeleteRole}
            idEdit={roleData[currentIdDatabase].role_id}
            nameEdit={roleData[currentIdDatabase].role_name}
            setRefresh={setRefresh}
            setRefreshUserData={setRefreshUserData}
          />
        )}
      </div>
    </div>
  );
}

export default Roles;
