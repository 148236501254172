import { useEffect, useState } from "react";
import DataTable from "./DataGrid";
import DeleteFile from "./DeleteFile";
import api from "../../api";
import { VscRefresh } from "react-icons/vsc";
import { getPermissionForName } from "../../GlobalFunctions";

const AllFiles = ({ userPermissions }: any) => {
  interface FileData {
    data_agent_name: string;
    file_name: string;
    file_size: number;
    file_type: string;
    id: number;
    tag_name: string;
    auto_id: string;
  }
  const [data, setData] = useState<FileData[] | null>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const openDeleteAllButton = () => {
    setOpenDeleteAll(true);
  };

  const closeDeleteAllButton = () => {
    setOpenDeleteAll(false);
  };

  const handleDeleteButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteAllButton();
    }
  };

  const handleRefreshButtonClick = () => {
    setRefreshData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      formData.append("user_id", user_id);

      try {
        const response = await api.post(
          `${process.env.REACT_APP_LIST_FILES_USER}`,
          formData
        );

        if (response.status === 200) {
          const jsonData: FileData[] = response.data;
          setData(jsonData);
        } else {
          console.error("Failed to fetch Data");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshData(false);
      }
    };

    fetchData();
  }, [refreshData]);

  const filteredData = data?.map(({ auto_id, ...rest }) => rest);

  useEffect(() => {
    setSelectedFiles([]);
    selectedRows.forEach((row) => {
      const foundObject = data?.find((item) => item.id === row);

      if (foundObject) {
        setSelectedFiles((prevSelectedFiles) => [
          ...prevSelectedFiles,
          foundObject.auto_id.toString(),
        ]);
      }
    });
  }, [selectedRows]);

  const isAllowDelete =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_delete", "My Files");

  return (
    <div className="flex flex-col h-full gap-10 px-4">
      {filteredData && filteredData.length !== 0 ? (
        <>
          <div className="flex flex-row gap-2 justify-end mb-2">
            <button
              onClick={() => handleRefreshButtonClick()}
              className={`${
                refreshData ? "bg-gray-500" : "bg-[#4D30B5]"
              } hover:bg-[#1b1436] text-white p-2 rounded duration-300 fixed z-50 mr-[180px]`}
            >
              <VscRefresh
                size={25}
                title="Refresh the data"
                className={`${refreshData ? "animate-spin" : ""}`}
              />
            </button>
            {userPermissions && isAllowDelete && (
              <button
                disabled={selectedRows.length === 0}
                onClick={() => openDeleteAllButton()}
                className={`${
                  selectedRows.length > 0 ? "bg-[#4D30B5]" : "bg-[#1b1436]"
                }  hover:bg-[#1b1436] text-white p-2 rounded duration-300 fixed z-50`}
              >
                Delete Selected Files
              </button>
            )}
          </div>
          <div
            className="h-screen"
            style={{ maxHeight: "calc(100vh - 153px)" }}
          >
            <DataTable
              data={filteredData}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </div>
        </>
      ) : (
        <div className=" flex justify-center">No data found!</div>
      )}
      {openDeleteAll && userPermissions && isAllowDelete && (
        <DeleteFile
          handleDeleteButtonClick={handleDeleteButtonClick}
          idEdit={selectedFiles}
        />
      )}
    </div>
  );
};

export default AllFiles;
