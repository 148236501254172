import React, { useEffect, useState } from "react";
import DataTable from "./DataGrid";
import DeleteFile from "./DeleteFile";
import api from "../../api";
import { VscRefresh } from "react-icons/vsc";
import { getPermissionForName } from "../../GlobalFunctions";

const AllFiles = ({ userPermissions }: any) => {
  interface FileData {
    data_agent_name: string;
    file_name: string;
    file_size: number;
    file_type: string;
    id: number;
    tag_name: string;
    auto_id: string;
  }

  const [data, setData] = useState<FileData[] | null>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [filteredAgent, setFilteredAgent] = useState<string | null>(null);
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const openDeleteAllButton = () => {
    setOpenDeleteAll(true);
  };

  const closeDeleteAllButton = () => {
    setOpenDeleteAll(false);
  };

  const handleRefreshButtonClick = () => {
    setRefreshData((prev) => !prev);
  };

  const handleDeleteButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteAllButton();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      formData.append("user_id", user_id);

      try {
        const response = await api.post(
          `${process.env.REACT_APP_AGENT_ALL_FILES}`,
          formData
        );

        if (response.status === 200) {
          const jsonData: FileData[] = response.data;
          setData(jsonData);
        } else {
          console.error("Failed to fetch Data");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshData(false);
      }
    };

    fetchData();
  }, [refreshData]);

  const agentNames = data
    ? Array.from(new Set(data.map((item) => item.data_agent_name)))
    : [];

  const filteredData = data
    ?.filter((item) => !filteredAgent || item.data_agent_name === filteredAgent)
    .map(({ auto_id, ...rest }) => rest);

  useEffect(() => {
    setSelectedFiles([]);
    selectedRows.forEach((row) => {
      const foundObject = data?.find((item) => item.id === row);
      if (foundObject) {
        setSelectedFiles((prevSelectedFiles) => [
          ...prevSelectedFiles,
          foundObject.auto_id.toString(),
        ]);
      }
    });
  }, [selectedRows, data]);

  const isAllowDelete =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_delete", "Agent Files");

  return (
    <div className="flex flex-col h-full gap-10 px-4">
      {filteredData && filteredData.length !== 0 ? (
        <>
          <div className="flex justify-between items-center sticky bg-[#F8FBFD] top-0 z-50">
            <div>
              <label htmlFor="agentDropdown" className="mr-2">
                Filter by Data Agent:
              </label>
              <select
                id="agentDropdown"
                onChange={(e) => setFilteredAgent(e.target.value || null)}
                value={filteredAgent || ""}
                className=" text-black outline-none p-1 rounded-lg hover:bg-gray-200 duration-300 cursor-pointer"
              >
                <option value="">All Data Agents</option>
                {agentNames.map((agent) => (
                  <option key={agent} value={agent}>
                    {agent}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col items-center lg:flex-row gap-2 justify-end">
              <div>
                <button
                  onClick={() => handleRefreshButtonClick()}
                  className={`${
                    refreshData ? "bg-gray-500" : "bg-[#4D30B5]"
                  } hover:bg-[#1b1436] text-white p-2 rounded duration-300`}
                >
                  <VscRefresh
                    size={25}
                    title="Refresh the data"
                    className={`${refreshData ? "animate-spin" : ""}`}
                  />
                </button>
              </div>
              {userPermissions && isAllowDelete && (
                <button
                  disabled={selectedRows.length === 0}
                  onClick={() => openDeleteAllButton()}
                  className={`${
                    selectedRows.length > 0 ? "bg-[#4D30B5]" : "bg-[#1b1436]"
                  }  hover:bg-[#1b1436] text-white p-2 rounded duration-300`}
                >
                  Delete Selected Files
                </button>
              )}
            </div>
          </div>
          <div
            className="h-screen"
            style={{ maxHeight: "calc(100vh - 190px)" }}
          >
            <DataTable
              data={filteredData}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </div>
        </>
      ) : (
        <div className=" flex justify-center">No data found!</div>
      )}

      {openDeleteAll && userPermissions && isAllowDelete && (
        <DeleteFile
          handleDeleteButtonClick={handleDeleteButtonClick}
          idEdit={selectedFiles}
        />
      )}
    </div>
  );
};

export default AllFiles;
