import React, { useState, useEffect, CSSProperties } from "react";
import { BsSearch } from "react-icons/bs";
import Okation from "../../../Assets/OkationLogo.png";
import Cookies from "js-cookie";
import Paginate from "../../Pagination";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import EmailInfo from "../../Chat/EmailInfo";
import api from "../../api";

let selected_source = "";
interface SearchResult {
  page_content: string;
  sources: string;
  scores: string;
  title: string;
  time: string;
  type: string;
}

interface AgentData {
  id: string;
  agent: string;
}
interface TagData {
  id: string;
  tag_name: string;
}

function SearchEngine() {
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [time, setTime] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [tagData, setTagData] = useState<TagData[] | null>(null);
  const [agentData, setAgentData] = useState<AgentData[] | null>(null);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedAgentName, setSelectedAgentName] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTagName, setSelectedTagName] = useState("");
  const [searchClicked, setSearchClicked] = useState("0");
  const user_id = localStorage.getItem("user_id") || "default_user";

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_RAG_AGENT_BY_USER}${user_id}`;

    const fetchData = async () => {
      try {
        const response = await api.get(apiUrl);

        if (response.status === 200) {
          const data = response.data;
          setAgentData(data);
          setSelectedAgentName(data[0].agent);
          setSelectedAgent(data[0].id);
        } else {
          console.error("Failed to fetch data from Agents API");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedAgent !== "") {
      const apiUrl = `${process.env.REACT_APP_TAGS_BY_AGENT}${selectedAgent}`;

      const fetchData = async () => {
        try {
          const response = await api.get(apiUrl);

          if (response.status === 200) {
            const data = response.data;
            setTagData(data);
          } else {
            console.error("Failed to fetch data from Tags API");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      };

      fetchData();
    }
  }, [selectedAgent]);

  const handleAgentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTag("");
    setSelectedTagName("");
    setSelectedAgent(e.target.value);
    setSelectedAgentName(
      e.target.options[e.target.selectedIndex].getAttribute("data-agentname") ||
        ""
    );
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTag(e.target.value);
    setSelectedTagName(
      e.target.options[e.target.selectedIndex].getAttribute("data-tagname") ||
        ""
    );
  };

  const sendMessageToEndpoint = async () => {
    setSearchClicked("1");
    setLoading(true);

    try {
      type RequestBody = {
        message: string;
        agent?: string;
        tag?: string;
      };

      let requestBody: RequestBody = {
        message: searchText,
      };

      if (selectedTag) {
        requestBody.tag = selectedTag;
      }
      if (selectedAgent) {
        requestBody.agent = selectedAgent;
      }

      const response = await api.post(
        `${process.env.REACT_APP_SEARCH_ENGINE}`,
        requestBody
      );

      const responseData = response.data;

      if (response.status === 200) {
        setLoading(false);
        setSearchResults(responseData.response as SearchResult[]);
        setTime(responseData.time);
      } else {
        toast.error("An error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        console.error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setLoading(false);
    }
  };

  useEffect(() => {}, [searchText]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !loading) {
      sendMessageToEndpoint();
    }
  };

  const passVariable = (index: number) => {
    selected_source = searchResults[index].sources;
    Cookies.set("pdf_name", selected_source);
    Cookies.set("search_engine", "1");
    Cookies.set("selected_tag", selectedTag);
    Cookies.set("selected_tag_name", selectedTagName);
    Cookies.set("selected_agent", selectedAgent);
    Cookies.set("selected_agent_name", selectedAgentName);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = searchResults?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: React.SetStateAction<number>) => {
    setCurrentPage(pageNumber);
  };
  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(searchResults?.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const [isEmailInfoOpen, setEmailInfoOpen] = useState(false);
  const [emailData, setEmailData] = useState("");

  const closePopUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeEmailInfoData();
    }
  };

  const closeEmailInfoData = () => {
    setEmailInfoOpen(false);
  };

  const openEmailInfoData = () => {
    setEmailInfoOpen(true);
  };

  return (
    <div className=" font-sans h-screen">
      {searchClicked === "1" ? (
        <div className=" flex flex-col ">
          <div className="flex flex-col  ">
            <div className="flex flex-col justify-center items-center gap-3  ">
              <div className=" flex flex-col lg:flex-row gap-4 items-center w-full  border-b lg:p-5 bg-white">
                <img
                  className=" max-lg:hidden"
                  loading="lazy"
                  src={Okation}
                  alt="Okation"
                  width={50}
                />
                <div className=" max-lg:w-full px-4">
                  <div className=" shadow-lg flex flex-row gap-4 border rounded-full max-w-[700px] w-full  ">
                    <span
                      className="text-[#9aa0a6] p-4"
                      onClick={sendMessageToEndpoint}
                      role="button"
                    >
                      <BsSearch />
                    </span>
                    <input
                      disabled={loading}
                      autoFocus
                      required
                      className="outline-none md:w-[550px] rounded-full"
                      placeholder="Search"
                      type="text"
                      onKeyPress={handleKeyPress}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    {loading && (
                      <div>
                        <AiOutlineLoading3Quarters
                          size={20}
                          className=" animate-spin mt-4 ml-8"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className=" flex flex-row gap-5">
                  <div className=" flex flex-col gap-4 py-4 ">
                    <select
                      required
                      value={selectedAgent}
                      onChange={handleAgentChange}
                      className=" text-black outline-none p-1 rounded-lg hover:bg-gray-200 duration-300 cursor-pointer"
                    >
                      <option value="" disabled>
                        Select a Data Agent
                      </option>
                      {agentData &&
                        agentData.length > 0 &&
                        agentData?.map((llm) => (
                          <option
                            data-agentname={llm.agent}
                            key={llm.id}
                            value={llm.id}
                          >
                            {llm.agent}
                          </option>
                        ))}
                    </select>
                  </div>
                  {selectedAgent && (
                    <div className=" flex flex-col gap-4 py-4 ">
                      <select
                        required
                        value={selectedTag}
                        onChange={handleTagChange}
                        className=" text-black outline-none p-1 rounded-lg hover:bg-gray-200 duration-300 cursor-pointer"
                      >
                        <option value="" disabled>
                          Select a Tag
                        </option>
                        {tagData?.map((tag) => (
                          <option
                            data-tagname={tag.tag_name}
                            key={tag.id}
                            value={tag.id}
                          >
                            {tag.tag_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="  md:grid md:grid-cols-11">
            <div className=" md:col-span-8">
              <div className="md:px-[120px] px-2 pt-4 text-gray-500 font-light text-[15px] flex flex-row gap-3">
                <span>{searchResults?.length} results</span>
                <span>{time?.substring(0, 5)} seconds</span>
              </div>
              <div
                className="h-screen overflow-y-scroll custom-scrollbar overflow-x-hidden"
                style={{
                  maxHeight: "calc(100vh - 190px)",
                  ...((window.innerWidth <= 1024 && {
                    maxHeight: "calc(100vh - 220px)",
                  }) as CSSProperties),
                }}
              >
                {currentPosts && currentPosts.length > 0 ? (
                  currentPosts.map((result, index) => (
                    <div className="flex" key={index}>
                      <div className=" px-2 lg:pl-28 m-2 rounded-lg w-full">
                        <div className="">
                          {result.title.startsWith("Subject: ") ? (
                            <div
                              onClick={() => {
                                setEmailData(result?.sources);
                                openEmailInfoData();
                              }}
                              role="button"
                              className=" cursor-pointer flex flex-row items-center gap-2 text-[20px] text-[#1A2FAE] max-w-[400px] md:max-w-[800px] hover:underline duration-300"
                            >
                              {result?.title
                                .replace("Subject: ", "")
                                .replace("hrsrc'}", "")
                                .replace(`hrsrc"}`, "")
                                .slice(
                                  0,
                                  result?.title
                                    .replace("Subject: ", "")
                                    .replace("hrsrc'}", "")
                                    .replace(`hrsrc"}`, "")
                                    .indexOf(", Sender:")
                                )}
                              {/* {result?.title} */}
                            </div>
                          ) : (
                            <a
                              href={`file:///C:\\Users\\abdom\\Documents\\data\\notDuplicate_sahelData\\data\\${result?.sources.replace(
                                "data\\hospital-data\\notDuplicate\\",
                                ""
                              )}`}
                              target="_blank"
                              className="flex flex-row items-center gap-2 text-[20px] text-[#1A2FAE] max-w-[400px] md:max-w-[800px] hover:underline duration-300"
                              rel="noreferrer"
                            >
                              {result?.title}
                            </a>
                          )}
                        </div>
                        <p className=" text-[13px] pt-1">
                          {result?.page_content}...
                        </p>
                        <div className=" py-1">
                          <a
                            href="/chat"
                            target="_blank"
                            onClick={() => passVariable(index)}
                            role="button"
                            className="text-[#1A2FAE] hover:text-[#284A66] duration-300 underline"
                          >
                            Chat
                          </a>
                        </div>
                        <div className=" max-sm:gap-2 flex flex-col md:flex-row md:justify-between text-[12px] md:items-center font-light">
                          <p>
                            <span className=" text-gray-500">Source: </span>
                            <span>
                              {result.title.startsWith("Subject: ")
                                ? result?.title
                                    .replace("Subject: ", "")
                                    .replace("hrsrc'}", "")
                                    .replace(`hrsrc"}`, "")
                                    .slice(
                                      0,
                                      result?.title
                                        .replace("Subject: ", "")
                                        .replace("hrsrc'}", "")
                                        .replace(`hrsrc"}`, "")
                                        .indexOf(", Sender:")
                                    )
                                : result?.sources.replace(
                                    "\\/home\\/bizon\\/uploaded_files\\/",
                                    ""
                                  )}
                            </span>
                          </p>
                          <p>
                            <span className=" text-gray-500">Score: </span>
                            <span>{result?.scores}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="px-[120px] mt-2">No Results found!</div>
                )}
              </div>
              {searchResults.length > 0 && (
                <Paginate
                  postsPerPage={postsPerPage}
                  totalPosts={searchResults.length}
                  paginate={paginate}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  currentPage={currentPage}
                />
              )}
            </div>
            <div className="hidden md:block md:col-span-3"></div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col min-w-[490px] h-full">
          <div className="flex flex-col justify-center items-center gap-3  ">
            <div className=" flex flex-col justify-center items-center max-sm:mr-[80px] md:w-[850px] mt-40 p-5 bg-white">
              <img
                className=""
                src={Okation}
                alt="Okation"
                width={120}
                loading="lazy"
              />
              <p className=" pt-4 text-[25px] text-[#1D6196]">AI Search</p>
              <div className=" shadow-lg flex flex-row gap-4 border rounded-full max-w-[700px] mt-10 w-full ">
                <span
                  className="text-[#9aa0a6] p-4"
                  onClick={sendMessageToEndpoint}
                  role="button"
                >
                  <BsSearch />
                </span>
                <input
                  disabled={loading}
                  className="outline-none w-[250px] md:w-[570px] rounded-full"
                  autoFocus
                  required
                  placeholder="Search"
                  type="text"
                  onKeyPress={handleKeyPress}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {loading && (
                  <div>
                    <AiOutlineLoading3Quarters
                      size={20}
                      className=" animate-spin mt-4 ml-2 max-sm:mr-4"
                    />
                  </div>
                )}
              </div>
              <div className=" flex flex-row gap-5">
                <div className=" flex flex-col  py-4 ">
                  <select
                    required
                    value={selectedAgent}
                    onChange={handleAgentChange}
                    className=" text-black outline-none p-1 rounded-lg hover:bg-gray-200 duration-300 cursor-pointer"
                  >
                    <option value="" disabled>
                      Select a Data Agent
                    </option>
                    {agentData &&
                      agentData.length > 0 &&
                      agentData?.map((llm) => (
                        <option
                          data-agentname={llm.agent}
                          key={llm.id}
                          value={llm.id}
                        >
                          {llm.agent}
                        </option>
                      ))}
                  </select>
                </div>
                {selectedAgent && (
                  <div className=" flex flex-col gap-4 py-4 ">
                    <select
                      required
                      value={selectedTag}
                      onChange={handleTagChange}
                      className=" text-black outline-none p-1 rounded-lg hover:bg-gray-200 duration-300 cursor-pointer"
                    >
                      <option value="" disabled>
                        Select a Tag
                      </option>
                      {tagData?.map((tag) => (
                        <option
                          data-tagname={tag.tag_name}
                          key={tag.id}
                          value={tag.id}
                        >
                          {tag.tag_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isEmailInfoOpen && (
        <EmailInfo
          closePopUp={closePopUp}
          closeSyncData={closeEmailInfoData}
          emailData={emailData}
          selectedAgent={selectedAgentName}
        />
      )}
    </div>
  );
}

export default SearchEngine;
