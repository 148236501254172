import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../../../api";
import { RxCross2 } from "react-icons/rx";

function AddModel({ handleModelClick, closeModel }: any) {
  interface ParentModelData {
    llm_id: number;
    provider_name: string;
  }

  const [parentModelData, setParentModelData] = useState<
    ParentModelData[] | null
  >(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState<string>("");
  const [maxTokens, setMaxTokens] = useState("");
  const [knowledgeUpTo, setKnowledgeUpTo] = useState("");
  const [selectedLLM, setSelectedLLM] = useState("");

  const [file, setFile] = useState<File | null>(null);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleMaxTokensChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxTokens(e.target.value);
  };

  const handleKnowledgeUpToChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setKnowledgeUpTo(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleLLMChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLLM(e.target.value);
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select an image!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    const formData = new FormData();
    formData.append("model", name);
    formData.append("logo", file);
    formData.append("description", description);
    formData.append("max_tokens", maxTokens);
    formData.append("training_data_upto", knowledgeUpTo);
    formData.append("llm_id", selectedLLM);

    try {
      await api.post(`${process.env.REACT_APP_CREATE_MODEL}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      window.location.reload();
      toast.success("LLM Model has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while creating the LLM Model", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_MODEL_LLM_KEYS}`;

    api
      .get(apiUrl)
      .then((response) => setParentModelData(response.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={handleModelClick}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeModel}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>
        <p className="text-[25px] text-center content-center">
          Create a LLM Model
        </p>
        <form onSubmit={handleUploadSubmit} className=" md:w-[750px] w-[350px]">
          <div className="p-4 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Select LLM:</label>
                <select
                  required
                  value={selectedLLM}
                  onChange={handleLLMChange}
                  className="border-[2px] text-black outline-none p-1 rounded-lg"
                >
                  <option value="" disabled>
                    Select an LLM model
                  </option>
                  {parentModelData?.map((llm) => (
                    <option key={llm.llm_id} value={llm.llm_id}>
                      {llm.provider_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Max Tokens:</label>
                <input
                  required
                  type="text"
                  value={maxTokens}
                  onChange={handleMaxTokensChange}
                  className=" border-[2px] text-black outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Knowledge Up To:</label>
                <input
                  required
                  type="text"
                  value={knowledgeUpTo}
                  onChange={handleKnowledgeUpToChange}
                  className=" border-[2px] text-black outline-none p-1 rounded-lg"
                />
              </div>
            </div>
            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Description:</label>
              <textarea
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="border-[2px] text-black rounded-lg outline-none p-1 resize-none h-[140px] custom-scrollbar-chat"
              />
            </div>

            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Image:</label>
              <input
                required
                type="file"
                accept=".png, .jpg, .jpeg, .gif"
                onChange={handleFileChange}
              />
            </div>
            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Upload LLM Model
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddModel;
