import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";

function AddRole({
  handleRoleClick,
  closeRole,
  setRefresh,
  setRefreshUserData,
}: any) {
  const [name, setName] = useState("");

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("role_name", name);

    try {
      await api.post(`${process.env.REACT_APP_CREATE_ROLE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshUserData((prevRefresh: any) => prevRefresh + 1);
      closeRole();
      toast.success("Role has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while creating the Role", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={handleRoleClick}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeRole}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">Create Role</p>

        <form
          onSubmit={handleUploadSubmit}
          className=" md:w-[750px] w-[350px] max-h-[600px] overflow-y-scroll"
        >
          <div className="p-4 flex flex-col gap-4">
            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Role Name:</label>
              <input
                required
                type="text"
                value={name}
                onChange={handleNameChange}
                className=" border-[2px] text-black  outline-none p-1 rounded-lg"
              />
            </div>
            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create Role
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddRole;
