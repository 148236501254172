import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";

function EditUser({
  nameEdit,
  emailEdit,
  idEdit,
  handleUserEditClick,
  closeEditUser,
  allroles,
  enabledEdit,
  setRefresh,
}: any) {
  interface AllRoles {
    role_id: string;
    role_name: string;
    role_description: string;
    is_active: number;
  }
  const [name, setName] = useState(nameEdit);
  const [email, setEmail] = useState(emailEdit);

  const [allRoles, setAllRoles] = useState<AllRoles[] | null>(allroles);
  const [checkedRoles, setCheckedRoles] = useState<string[]>([]);
  const [isEnabled, setIsEnabled] = useState<string>(
    enabledEdit === 0 ? "false" : "true"
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    const defaultCheckedRoles =
      allRoles
        ?.filter((role) => role.is_active === 1)
        .map((role) => role.role_id) || [];
    setCheckedRoles(defaultCheckedRoles);
  }, [allRoles]);

  const handleCheckBoxChange = (role_id: string) => {
    setCheckedRoles((prevRoles) => {
      if (prevRoles.includes(role_id)) {
        return prevRoles.filter((roleId) => roleId !== role_id);
      } else {
        return [...prevRoles, role_id];
      }
    });
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("user_id", idEdit);
    formData.append("user_name", name);
    formData.append("user_email", email);
    formData.append("role_ids", JSON.stringify(checkedRoles));
    formData.append("is_enabled", isEnabled);

    try {
      await api.post(`${process.env.REACT_APP_EDIT_USER}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      closeEditUser();
      toast.success("User information has been updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while updating the user information", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={handleUserEditClick}
      role="button"
    >
      <div className="relative bg-white p-4 rounded shadow-2xl">
        <button
          onClick={closeEditUser}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] p-1 duration-300 rounded-lg text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>
        <p className="text-[25px] text-center content-center">Edit User</p>
        <form onSubmit={handleEditSubmit} className=" md:w-[750px] w-[350px]">
          <div className="p-4 flex flex-col gap-4">
            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Username:</label>
              <input
                required
                type="text"
                value={name}
                onChange={handleNameChange}
                className=" border-[2px] text-black  outline-none p-1 rounded-lg"
              />
            </div>
            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Email:</label>
              <input
                required
                type="text"
                value={email}
                onChange={handleEmailChange}
                className=" border-[2px] text-black  outline-none p-1 rounded-lg"
              />
            </div>
            <div>
              <label className="pb-2 text-[20px]">Roles:</label>
              <div
                id="dropdownSearch"
                className="z-10 bg-gray-100 rounded-lg shadow w-60 "
              >
                <ul
                  className="h-48 px-3 pb-3 overflow-y-auto text-sm "
                  aria-labelledby="dropdownSearchButton"
                >
                  {allRoles?.map((role, index) => {
                    if (role.is_active === 1) {
                      return (
                        <li key={index}>
                          <div className="flex items-center p-2 rounded ">
                            <input
                              defaultChecked
                              id="checkbox-item-13"
                              type="checkbox"
                              value=""
                              className="w-4 h-4 "
                              onChange={() =>
                                handleCheckBoxChange(role.role_id)
                              }
                            />
                            <label className="w-full ml-2 text-sm font-medium ">
                              {role.role_name}
                            </label>
                          </div>
                        </li>
                      );
                    } else {
                      return (
                        <li key={index}>
                          <div className="flex items-center p-2 rounded ">
                            <input
                              id="checkbox-item-14"
                              type="checkbox"
                              value={role.role_id}
                              className="w-4 h-4 text-blue-600 "
                              onChange={() =>
                                handleCheckBoxChange(role.role_id)
                              }
                            />
                            <label className="w-full ml-2 text-sm font-medium ">
                              {role.role_name}
                            </label>
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
            <div className="mt-4 flex flex-col">
              <label className="pb-2 text-[20px]">Status:</label>
              <div className="flex items-center gap-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="true"
                    checked={isEnabled === "true"}
                    onChange={() => setIsEnabled("true")}
                    className="form-radio h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2 text-sm">Enabled</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="false"
                    checked={isEnabled === "false"}
                    onChange={() => setIsEnabled("false")}
                    className="form-radio h-5 w-5 text-red-600"
                  />
                  <span className="ml-2 text-sm">Disabled</span>
                </label>
              </div>
            </div>

            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Edit User
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default EditUser;
