import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import FolderContents from "./FolderContents";
import Cookies from "js-cookie";

var eStatus = false;
var checkboxToChange = "";

const DropBox: React.FC = () => {
  const [data, setData] = useState<any | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [tempData, setTempData] = useState<any | null>(null);
  const token = Cookies.get("access_token");

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_DROPBOX_LIST_FILES}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: user_id,
              folder_id: null,
            }),
          }
        );

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error("Failed to fetch data from Google Drive API");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, []);

  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [selectedFoldersId, setSelectedFoldersId] = useState<string[]>([]);

  const handleFolderClick = (folderId: string) => {
    checkboxToChange = "";
    setSelectedFolder(folderId);
  };

  const handleFileClick = (fileId: string) => {
    const isSelected = selectedFiles.includes(fileId);

    if (isSelected) {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((id) => id !== fileId)
      );
    } else {
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, fileId]);
    }
  };

  const handleFileClickFile = (fileId: string, folder: string) => {
    const isSelected = selectedFiles.includes(fileId);

    setSelectedFoldersId((prevSelectedFoldersId) => {
      return prevSelectedFoldersId.filter((id) => id !== folder);
    });

    if (isSelected) {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((id) => id !== fileId)
      );
    } else {
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, fileId]);
    }
    checkboxToChange = folder;
  };

  const currentChange = (e: any, temp: string) => {
    eStatus = e.target.checked;
    if (!e.target.checked) {
      setSelectedFoldersId((prevselectedFolderId) =>
        prevselectedFolderId.filter((id) => id !== temp)
      );
    } else {
      setSelectedFoldersId((prevselectedFolderId) => [
        ...prevselectedFolderId,
        temp,
      ]);
    }
  };

  useEffect(() => {
    if (eStatus === true) {
      if (tempData !== null) {
        tempData.map((val: any) => {
          const isSelected = selectedFiles.includes(val.id);
          if (!isSelected) {
            setSelectedFiles((prevSelectedFiles) => [
              ...prevSelectedFiles,
              val.id,
            ]);
          }
        });
      }
    } else {
      if (tempData !== null) {
        tempData.map((val: any) => {
          const isSelected = selectedFiles.includes(val.id);
          if (isSelected) {
            setSelectedFiles((prevSelectedFiles) =>
              prevSelectedFiles.filter((id) => id !== val.id)
            );
          }
        });
      }
    }
  }, [tempData]);

  // useEffect(() => {
  //   console.log(checkboxToChange, "CHECKBOXXXX");
  // }, [checkboxToChange]);

  // useEffect(() => {
  //   console.log(selectedFoldersId, "FOLDERSSSS");
  // }, [selectedFoldersId]);

  // useEffect(() => {
  //   console.log(selectedFiles, "FILESSSS");
  // }, [selectedFiles]);

  return (
    <div className="h-full flex flex-row border border-[#C5D4EB] justify-between">
      <SideMenu
        entries={data}
        onFolderClick={handleFolderClick}
        handleFileClick={handleFileClick}
        currentChange={currentChange}
        selectedFoldersId={selectedFoldersId}
        tempData={tempData}
        setTempData={setTempData}
        checkboxToChange={checkboxToChange}
      />
      <FolderContents
        entries={data}
        folderPath={selectedFolder}
        onFolderClick={handleFolderClick}
        selectedFoldersId={selectedFoldersId}
        selectedFiles={selectedFiles}
        handleFileClick={handleFileClickFile}
      />
    </div>
  );
};

export default DropBox;
