import React, { useState } from "react";
import Paginate from "../../../Pagination";
import SyncData from "./SyncData";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const Outlook = () => {
  const [data, setData] = useState<any | null>(null);
  const user_id = localStorage.getItem("user_id");
  const [selectedFolderName, setSelectedFolderName] = useState("all");
  const [emailAddress, setEmailAddress] = useState("");
  const [ccAddress, setCcAddress] = useState("");
  const [subjectFilter, setSubjectFilter] = useState("");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [fromTo, setFromTo] = useState("From / To");
  const [option, setOption] = useState("");
  const token = Cookies.get("access_token");

  const handleCheckboxChange = (messageId: string, e: any) => {
    var checkAll = true;
    const check = document.getElementById(
      `select-all-checkbox`
    ) as HTMLInputElement;

    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(messageId)) {
        return prevSelectedEmails.filter((id) => id !== messageId);
      } else {
        return [...prevSelectedEmails, messageId];
      }
    });

    data.map((val: any) => {
      if (val.messageId === messageId) {
        if (e.target.checked === false) {
          checkAll = false;
        }
      } else if (!selectedEmails.includes(val.messageId)) {
        checkAll = false;
      }
    });

    check.checked = checkAll;
  };

  const fetchData = async () => {
    setSelectedEmails([]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_OUTLOOK_RETRIEVE_EMAILS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: user_id,
            folder_name: selectedFolderName,
            email_address: emailAddress,
            cc_address: ccAddress,
            subject_filter: subjectFilter,
            start_date: startDate,
            end_date: endDate,
          }),
        }
      );

      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
        toast.success("Emails fetched successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        console.error("Failed to fetch data from Email");
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleFolderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFolderName(e.target.value);

    if (e.target.value === "all") {
      setFromTo("From / To");
    } else if (
      e.target.value === "sentitems" ||
      e.target.value === "drafts" ||
      e.target.value === "outbox"
    ) {
      setFromTo("To");
    } else {
      setFromTo("From");
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  };

  const handleCcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCcAddress(e.target.value);
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubjectFilter(e.target.value);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;
    setStartDate(
      selectedDate ? new Date(selectedDate).toISOString().split("T")[0] : null
    );
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;
    setEndDate(
      selectedDate ? new Date(selectedDate).toISOString().split("T")[0] : null
    );
  };

  const [expandedEmailId, setExpandedEmailId] = useState<string | null>(null);

  const handleReadMoreClick = (emailId: string) => {
    setExpandedEmailId((prevId) => (prevId === emailId ? null : emailId));
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: React.SetStateAction<number>) => {
    setCurrentPage(pageNumber);
  };
  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(data?.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const selectAll = (e: any) => {
    if (e.target.checked === true) {
      if (data !== null) {
        data.map((val: any) => {
          const isSelected = selectedEmails.includes(val.messageId);
          if (!isSelected) {
            setSelectedEmails((prevSelectedEmails) => [
              ...prevSelectedEmails,
              val.messageId,
            ]);
          }
        });
      }
    } else {
      if (data !== null) {
        data.map((val: any) => {
          const isSelected = selectedEmails.includes(val.messageId);
          if (isSelected) {
            setSelectedEmails((prevSelectedEmails) =>
              prevSelectedEmails.filter(
                (messageId) => messageId !== val.messageId
              )
            );
          }
        });
      }
    }
  };

  const [isSyncOpen, setIsSyncOpen] = useState(false);

  const closePopUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeSyncData();
    }
  };

  const openSyncData = () => {
    setIsSyncOpen(true);
  };

  const closeSyncData = () => {
    setIsSyncOpen(false);
  };

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <section
      className="flex flex-col h-screen "
      style={{ maxHeight: "calc(100vh - 105px)" }}
    >
      <div className=" flex flex-col lg:flex-row gap-4 ">
        <div>
          <div
            id="child"
            className="  flex flex-col border border-[#20144C] p-4 rounded-lg gap-3 items-center"
          >
            <div className=" flex flex-col gap-2 w-full ">
              <label className=" text-[18px]">Select Folder:</label>
              <select
                required
                value={selectedFolderName}
                onChange={handleFolderChange}
                className="border-[2px] text-black outline-none p-1 rounded-lg"
              >
                <option value="all">All</option>
                <option value="inbox">Inbox</option>
                <option value="sentitems">Sent</option>
                <option value="outbox">Outbox</option>
                <option value="archive">Archive</option>
                <option value="deleteditems">Deleted</option>
                <option value="drafts">Drafts</option>
                <option value="junkemail">Junk</option>
              </select>
            </div>

            <div className=" flex flex-col w-full gap-2">
              <label className=" text-[18px]">{fromTo}:</label>
              <input
                type="email"
                placeholder="Email Address"
                value={emailAddress}
                onChange={handleEmailChange}
                className="px-2 py-1 border-b border-[#4D30B5] outline-none bg-transparent "
              />
            </div>

            <div className=" flex flex-col w-full gap-2 ">
              <label className=" text-[18px]">Select CC Email:</label>
              <input
                type="email"
                placeholder="CC Email Address"
                value={ccAddress}
                onChange={handleCcChange}
                className="px-2 py-1 border-b border-[#4D30B5] outline-none bg-transparent "
              />
            </div>
            <div className=" flex flex-col gap-2 w-full">
              <label className=" text-[18px]">Select Subject:</label>
              <input
                type="text"
                placeholder="Subject"
                value={subjectFilter}
                onChange={handleSubjectChange}
                className="px-2 py-1 border-b border-[#4D30B5] outline-none bg-transparent "
              />
            </div>

            <div className=" flex flex-col w-full gap-2">
              <label htmlFor="dateFilterInput" className=" text-[18px]">
                Select Start Date:
              </label>
              <input
                type="date"
                id="startDate"
                value={startDate ? startDate : ""}
                onChange={handleStartDateChange}
                className="px-2 py-1 border-b border-[#4D30B5] outline-none bg-transparent "
              />
            </div>
            <div className=" flex flex-col gap-2 w-full ">
              <label htmlFor="dateFilterInput" className="text-[18px]">
                Select End Date:
              </label>
              <input
                type="date"
                id="endDate"
                value={endDate ? endDate : ""}
                onChange={handleEndDateChange}
                className="px-2 py-1 border-b border-[#4D30B5] outline-none bg-transparent "
              />
            </div>
            <div className=" flex flex-col gap-3">
              <button
                disabled={loading}
                className={` hover:bg-[#1b1436] px-5 text-white duration-300 ${
                  loading === false ? "bg-[#4D30B5] " : "bg-[#1b1436] "
                }  py-2 rounded text-[15px] `}
                onClick={() => {
                  fetchData();
                  setLoading(true);
                }}
              >
                Fetch Emails
              </button>
              <button
                disabled={loading}
                className={` hover:bg-[#1b1436] px-2 text-white duration-300 ${
                  loading === false ? "bg-[#4D30B5] " : "bg-[#1b1436] "
                }  py-2 rounded text-[15px] `}
                onClick={() => {
                  openSyncData();
                  setOption("Sync Selected Emails");
                }}
              >
                Sync Selected Emails
              </button>
            </div>
          </div>
        </div>
        {loading === true ? (
          <div className=" flex justify-center items-center flex-1">
            <AiOutlineLoading3Quarters
              size={40}
              className=" animate-spin justify-center items-center max-sm:mr-4"
            />
          </div>
        ) : data?.length > 0 ? (
          <div className="flex-1">
            <div className=" flex flex-row justify-between">
              <div className="text-[18px] ml-2">
                <h2>Email Details:</h2>
              </div>
              <div className="text-[18px] flex flex-row gap-2 items-center mr-2">
                <h2>Select All:</h2>
                <input
                  id="select-all-checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    selectAll(e);
                  }}
                />
              </div>
            </div>
            <div>
              <div
                className="h-screen overflow-y-scroll max-w-screen overflow-x-hidden"
                style={{
                  maxHeight: "calc(100vh - 180px)",
                  maxWidth: "calc(100vw - 550px)",
                }}
              >
                {currentPosts?.map((email: any) => (
                  <div className=" py-2">
                    <div
                      key={email?.messageId}
                      className=" border rounded-lg border-[#20144C]"
                    >
                      <div className="p-4">
                        <div className="flex justify-between">
                          <div className="flex flex-row gap-2">
                            <div className=" font-semibold">From:</div>
                            <div>{email?.Sender}</div>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              checked={selectedEmails.includes(
                                email?.messageId
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(email?.messageId, e)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex flex-row gap-2">
                          <div className=" font-semibold">To:</div>
                          <div>{email?.Receiver}</div>
                        </div>

                        <div className="flex flex-row gap-2">
                          <div className=" font-semibold">CC:</div>
                          <div>{email?.CC}</div>
                        </div>
                        <div className="flex flex-row gap-2">
                          <div className=" font-semibold">BCC:</div>
                          <div>{email?.BCC}</div>
                        </div>

                        <div className="flex flex-row gap-2">
                          <div className=" font-semibold">Subject:</div>
                          <div>{email?.Subject}</div>
                        </div>

                        <div className="flex flex-col mt-5">
                          <div className=" font-semibold">Body:</div>
                          <div>
                            {expandedEmailId === email.messageId
                              ? email.Body
                              : email.Body.length > 500
                              ? email.Body.slice(0, 500) + "..."
                              : email.Body}
                            {email.Body.length > 500 && (
                              <button
                                className="text-blue-700 ml-2 underline"
                                onClick={() =>
                                  handleReadMoreClick(email.messageId)
                                }
                              >
                                {expandedEmailId === email?.messageId
                                  ? "Read Less"
                                  : "Read More"}
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-row gap-2 mt-5">
                          <div className=" font-semibold">Date:</div>
                          <div>{email?.Date}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Paginate
                postsPerPage={postsPerPage}
                totalPosts={data?.length}
                paginate={paginate}
                previousPage={previousPage}
                nextPage={nextPage}
                currentPage={currentPage}
              />
            </div>
          </div>
        ) : null}
      </div>
      {/* <div className="mt-6 items-center flex flex-col">
        <div className="justify-center mt-5 flex flex-col lg:flex-row lg:items-center lg:justify-between w-[45%] gap-5">
          <button
            disabled={loading}
            className={` hover:bg-[#1b1436] px-5 text-white duration-300 ${
              loading === false ? "bg-[#4D30B5] " : "bg-[#1b1436] "
            }  py-3 rounded `}
            onClick={() => {
              fetchData();
              setLoading(true);
            }}
          >
            Fetch Emails
          </button>
          <button
            disabled={loading}
            className={` hover:bg-[#1b1436] px-5 text-white duration-300 ${
              loading === false ? "bg-[#4D30B5] " : "bg-[#1b1436] "
            }  py-3 rounded `}
            onClick={() => {
              openSyncData();
              setOption("Sync Selected Emails");
            }}
          >
            Sync Selected Emails
          </button>
          <button
            disabled={loading}
            className={` hover:bg-[#1b1436] px-5 text-white duration-300 ${
              loading === false ? "bg-[#4D30B5] " : "bg-[#1b1436] "
            }  py-3 rounded `}
            onClick={() => {
              setSelectedEmails([]);
              openSyncData();
              setOption("Sync All Emails");
            }}
          >
            Sync All Emails
          </button>
        </div>
      </div> */}

      {isSyncOpen && (
        <SyncData
          closePopUp={closePopUp}
          closeSyncData={closeSyncData}
          selectedEmails={selectedEmails}
          option={option}
        />
      )}
    </section>
  );
};

export default Outlook;
