import { useState } from "react";
import { TbLogout } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { RiAdminFill, RiArrowDropDownLine } from "react-icons/ri";
import { BiChat } from "react-icons/bi";
import { CiCloudRainbow, CiSearch } from "react-icons/ci";
import { RiAdminLine } from "react-icons/ri";
import { BsInfoCircle } from "react-icons/bs";
import { MdEmail, MdEngineering, MdSupportAgent } from "react-icons/md";
import { GoDependabot } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { SlCloudUpload } from "react-icons/sl";
// import Boxmind from "../../Assets/boxMind_wide_white.png";
import { CgProfile } from "react-icons/cg";
import { AnimatePresence, motion } from "framer-motion";
import { VscDebugDisconnect } from "react-icons/vsc";
import { SlSocialDropbox } from "react-icons/sl";
import { FaGoogleDrive } from "react-icons/fa";
import { ImOnedrive } from "react-icons/im";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { logout } from "../../authSlice";
import { BsCollection } from "react-icons/bs";
// import { ImFilesEmpty } from "react-icons/im";
import { IoFileTrayStackedOutline } from "react-icons/io5";
import { CgWorkAlt } from "react-icons/cg";
import { VscDatabase } from "react-icons/vsc";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { BiLogoGmail } from "react-icons/bi";
import { PiMicrosoftOutlookLogoFill } from "react-icons/pi";
import { PiFiles } from "react-icons/pi";
import { HiUserGroup } from "react-icons/hi2";

function SideMenu({
  changeSideActive,
  userData,
  userPermissions,
  setUserData,
  setUserID,
}: any) {
  const [infoDropdownOpen, setInfoDropdownOpen] = useState(false);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const [CloudDropdownOpen, setCloudDropdownOpen] = useState(false);
  const [GmailDropdownOpen, setGmailDropdownOpen] = useState(false);
  const [aiPipelineDropdownOpen, setAiPipelineDropdownOpen] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const InfoDropdown = () => {
    setInfoDropdownOpen(!infoDropdownOpen);
  };

  const AdminDropdown = () => {
    setAdminDropdownOpen(!adminDropdownOpen);
  };

  const CloudDropdown = () => {
    setCloudDropdownOpen(!CloudDropdownOpen);
  };

  const GmailDropdown = () => {
    setGmailDropdownOpen(!GmailDropdownOpen);
  };

  const aiPipelineDropdown = () => {
    setAiPipelineDropdownOpen(!aiPipelineDropdownOpen);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("access_token");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_id");
    setUserID(null);
    setUserData(null);
    dispatch(logout());
    navigate("/signin");
  };

  const getAllowViewForName = (searchName: string): boolean | null => {
    const foundPermission = userPermissions.find(
      (permission: any) => permission.object_name === searchName
    );

    if (foundPermission) {
      return foundPermission.permissions.allow_view;
    }
    return null;
  };

  return (
    <menu className="text-[white] font-semibold text-[16px] h-screen select-none">
      <div className="h-[60px]">
        <div className=" flex items-center justify-center">
          {/* <Link to={"/"}>
            <img
              src={Boxmind}
              alt="Boxmind"
              width={150}
              className="pt-2 -ml-5"
              loading="lazy"
            />
          </Link> */}
        </div>
      </div>
      <div
        className="text-[#a9aaac] font-semibold overflow-y-scroll custom-scrollbar h-screen  "
        style={{ maxHeight: "calc(100vh - 140px)" }}
      >
        <div className="overflow-y-scroll custom-scrollbar ">
          <div className="py-4 flex flex-col gap-2">
            {userPermissions && getAllowViewForName("Chat") && (
              <a
                href="/chat"
                target="_blank"
                className=" flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300"
              >
                <BiChat size={25} />
                <p>Chat</p>
              </a>
            )}
            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname === "/admin-panel"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname === "/admin-panel"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                  to={"/admin-panel"}
                >
                  <RiAdminFill title="Admin Panel" size={25} />
                  Admin Panel
                </Link>
              </div>
            </div>
            {userPermissions && getAllowViewForName("GenAI") && (
              <>
                <div
                  onClick={() => InfoDropdown()}
                  className={` justify-between flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300`}
                >
                  <div className="flex flex-row items-center gap-3">
                    <BsInfoCircle title="info" size={25} />
                    <p>Gen AI</p>
                  </div>
                  <div>
                    <RiArrowDropDownLine
                      className={` duration-300 ${
                        infoDropdownOpen === true ? " rotate-180" : " rotate-0 "
                      }`}
                      size={25}
                    />
                  </div>
                </div>
                {infoDropdownOpen && (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, y: -2 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -2 }}
                      viewport={{ once: false }}
                      transition={{ duration: 0.3 }}
                      className="ml-10 flex flex-col gap-2 "
                    >
                      {userPermissions && getAllowViewForName("LLM Models") && (
                        <div
                          className={`${
                            pathname === "/llm-models"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white]  rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                        ${
                          pathname === "/llm-models"
                            ? "text-[white] hover:text-[#20144C]"
                            : null
                        }`}
                            to={"/llm-models"}
                          >
                            <GoDependabot title="LLM Models" size={25} />
                            LLM Models
                          </Link>
                        </div>
                      )}
                      {userPermissions &&
                        getAllowViewForName("Data Agents") && (
                          <div
                            className={`${
                              pathname === "/data-agents"
                                ? " border-l-2 border-[white]"
                                : null
                            }`}
                            onClick={() => changeSideActive()}
                          >
                            <Link
                              className={`ml-1 duration-200 hover:bg-[white] hover:text-[#20144C] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/data-agents"
                              ? "text-[white] hover:text-[#20144C] "
                              : null
                          }`}
                              to={"/data-agents"}
                            >
                              <MdSupportAgent title="Data Agents" size={25} />
                              Data Agents
                            </Link>
                          </div>
                        )}
                      {userPermissions &&
                        getAllowViewForName("Group Agents") && (
                          <div
                            className={`${
                              pathname === "/group-agents"
                                ? " border-l-2 border-[white]"
                                : null
                            }`}
                            onClick={() => changeSideActive()}
                          >
                            <Link
                              className={`ml-1 duration-200 hover:bg-[white] hover:text-[#20144C] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/group-agents"
                              ? "text-[white] hover:text-[#20144C] "
                              : null
                          }`}
                              to={"/group-agents"}
                            >
                              <HiUserGroup title="Group Agents" size={25} />
                              Group Agents
                            </Link>
                          </div>
                        )}
                      {userPermissions &&
                        getAllowViewForName("Upload File") && (
                          <div
                            className={`${
                              pathname === "/upload-file"
                                ? " border-l-2 border-[white]"
                                : null
                            }`}
                            onClick={() => changeSideActive()}
                          >
                            <Link
                              className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/upload-file"
                              ? "text-[white] hover:text-[#20144C] "
                              : null
                          }`}
                              to={"/upload-file"}
                            >
                              <SlCloudUpload title="Upload File" size={25} />
                              Upload File
                            </Link>
                          </div>
                        )}
                      {userPermissions && getAllowViewForName("My Files") && (
                        <div
                          className={`${
                            pathname === "/my-files"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/my-files"
                              ? "text-[white] hover:text-[#20144C] "
                              : null
                          }`}
                            to={"/my-files"}
                          >
                            <IoFileTrayStackedOutline
                              title="My Files"
                              size={25}
                            />
                            My Files
                          </Link>
                        </div>
                      )}
                      {userPermissions &&
                        getAllowViewForName("Agent Files") && (
                          <div
                            className={`${
                              pathname === "/agent-files"
                                ? " border-l-2 border-[white]"
                                : null
                            }`}
                            onClick={() => changeSideActive()}
                          >
                            <Link
                              className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/agent-files"
                              ? "text-[white] hover:text-[#20144C] "
                              : null
                          }`}
                              to={"/agent-files"}
                            >
                              <PiFiles title="Agent Files" size={25} />
                              Agent Files
                            </Link>
                          </div>
                        )}
                      {/* <div
                    className={`${
                      pathname === "/parent-model"
                        ? " border-l-2 border-[white]"
                        : null
                    }`}
                    onClick={() => changeSideActive()}
                  >
                    <Link
                      className={`ml-1 duration-200 hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                  ${pathname === "/parent-model" ? "text-[white]" : null}`}
                      to={"/parent-model"}
                    >
                      <GoHubot title="Models" size={25} />
                      Parent Model
                    </Link>
                  </div> */}
                      {userPermissions &&
                        getAllowViewForName("Collections") && (
                          <div
                            className={`${
                              pathname === "/collections"
                                ? " border-l-2 border-[white]"
                                : null
                            }`}
                            onClick={() => changeSideActive()}
                          >
                            <Link
                              className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/collections"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                              to={"/collections"}
                            >
                              <BsCollection title="Collections" size={25} />
                              Collections
                            </Link>
                          </div>
                        )}
                    </motion.div>
                  </AnimatePresence>
                )}
              </>
            )}
            {userPermissions && getAllowViewForName("AI Search") && (
              <a href={"/ai-search"} target="_blank" rel="noreferrer">
                <div
                  className={`flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300`}
                >
                  <CiSearch title="ai-search" size={25} />
                  <p>AI Search</p>
                </div>
              </a>
            )}
            {/* <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname === "/file-manager"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
              >
                <Link
                  className={`ml-1 duration-200 hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                  ${pathname === "/file-manager" ? "text-[white]" : null}`}
                  to={"/file-manager"}
                >
                  <FiUsers title="File Manager" size={25} />
                  File Manager
                </Link>
              </div>
            </div> */}
            {/* <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname === "/upload" ? " border-l-2 border-[white]" : null
                }`}
                onClick={() => changeSideActive()}
              >
                <Link
                  className={`ml-1 duration-200 hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                  ${pathname === "/upload" ? "text-[white]" : null}`}
                  to={"/upload"}
                >
                  <SlCloudUpload title="Upload" size={25} />
                  Upload File
                </Link>
              </div>
            </div> */}
            {userPermissions && getAllowViewForName("AI Forms") && (
              <div className=" flex flex-col gap-2 ">
                <div
                  className={`${
                    pathname === "/ai-forms"
                      ? " border-l-2 border-[white]"
                      : null
                  }`}
                  onClick={() => changeSideActive()}
                  role="button"
                >
                  <Link
                    className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname === "/ai-forms"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                    to={"/ai-forms"}
                  >
                    <MdEngineering title="AI Forms" size={25} />
                    AI Forms
                  </Link>
                </div>
              </div>
            )}
            {userPermissions && getAllowViewForName("Cloud Storage") && (
              <>
                <div
                  onClick={() => CloudDropdown()}
                  role="button"
                  className={`justify-between flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300`}
                >
                  <div className="flex flex-row items-center gap-3">
                    <CiCloudRainbow title="Cloud Storage" size={25} />
                    <p>Cloud Storage</p>
                  </div>
                  <div>
                    <RiArrowDropDownLine
                      className={` duration-300 ${
                        CloudDropdownOpen === true
                          ? " rotate-180"
                          : " rotate-0 "
                      }`}
                      size={25}
                    />
                  </div>
                </div>
                {CloudDropdownOpen && (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, y: -2 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -2 }}
                      viewport={{ once: false }}
                      transition={{ duration: 0.3 }}
                      className=" ml-10 flex flex-col gap-2 "
                    >
                      {userData?.data.connected_to_dropbox === true ? (
                        <div
                          className={`${
                            pathname === "/dropbox"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname === "/dropbox"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/dropbox"}
                          >
                            <SlSocialDropbox title="DropBox" size={25} />
                            Dropbox
                          </Link>
                        </div>
                      ) : null}

                      {userData?.data.connected_to_google_drive === true ? (
                        <div
                          className={`${
                            pathname === "/google"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname === "/google"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/google"}
                          >
                            <FaGoogleDrive title="Google" size={25} />
                            Google
                          </Link>
                        </div>
                      ) : null}

                      {userData?.data.connected_to_onedrive === true ? (
                        <div
                          className={`${
                            pathname === "/onedrive"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname === "/onedrive"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/onedrive"}
                          >
                            <ImOnedrive title="One Drive" size={25} />
                            One Drive
                          </Link>
                        </div>
                      ) : null}

                      <div
                        className={`${
                          pathname === "/cloud-connectors"
                            ? " border-l-2 border-[white]"
                            : null
                        }`}
                        onClick={() => changeSideActive()}
                        role="button"
                      >
                        <Link
                          className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/cloud-connectors"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                          to={"/cloud-connectors"}
                        >
                          <VscDebugDisconnect
                            title="Cloud Connectors"
                            size={25}
                          />
                          Cloud Connectors
                        </Link>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                )}
              </>
            )}
            {/* <div className=" flex flex-col gap-2 ">
              <div
                className={`${pathname === "/email" ? " border-l-2 border-[white]" : null
                  }`}
                onClick={() => changeSideActive()}
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                  ${pathname === "/email"
                      ? "text-[white] hover:text-[#20144C]"
                      : null
                    }`}
                  to={"/email"}
                >
                  <MdEmail title="Email" size={25} />
                  Email
                </Link>
              </div>
            </div> */}
            {userPermissions && getAllowViewForName("Email Storage") && (
              <>
                <div
                  onClick={() => GmailDropdown()}
                  role="button"
                  className={`justify-between flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300`}
                >
                  <div className="flex flex-row items-center gap-3">
                    <MdEmail title="Gmail Storage" size={25} />
                    <p>Email Storage</p>
                  </div>
                  <div>
                    <RiArrowDropDownLine
                      className={` duration-300 ${
                        GmailDropdownOpen === true
                          ? " rotate-180"
                          : " rotate-0 "
                      }`}
                      size={25}
                    />
                  </div>
                </div>
                {GmailDropdownOpen && (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, y: -2 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -2 }}
                      viewport={{ once: false }}
                      transition={{ duration: 0.3 }}
                      className=" ml-10 flex flex-col gap-2 "
                    >
                      {userData?.data.connected_to_gmail === true ? (
                        <div
                          className={`${
                            pathname === "/gmail"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname === "/gmail"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/gmail"}
                          >
                            <BiLogoGmail title="Gmail" size={25} />
                            Gmail
                          </Link>
                        </div>
                      ) : null}

                      {userData?.data.connected_to_outlook === true ? (
                        <div
                          className={`${
                            pathname === "/outlook"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname === "/outlook"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/outlook"}
                          >
                            <PiMicrosoftOutlookLogoFill
                              title="Gmail"
                              size={25}
                            />
                            Outlook
                          </Link>
                        </div>
                      ) : null}

                      <div
                        className={`${
                          pathname === "/email-connectors"
                            ? " border-l-2 border-[white]"
                            : null
                        }`}
                        onClick={() => changeSideActive()}
                        role="button"
                      >
                        <Link
                          className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/email-connectors"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                          to={"/email-connectors"}
                        >
                          <VscDebugDisconnect
                            title="Gmail Connectors"
                            size={25}
                          />
                          Email Connectors
                        </Link>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                )}
              </>
            )}

            {userPermissions && getAllowViewForName("AI Pipeline") && (
              <>
                <div
                  onClick={() => aiPipelineDropdown()}
                  role="button"
                  className={`justify-between flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300`}
                >
                  <div className="flex flex-row items-center gap-3">
                    <VscDatabase title="AI Pipeline" size={25} />
                    <p>AI Pipeline</p>
                  </div>
                  <div>
                    <RiArrowDropDownLine
                      className={` duration-300 ${
                        aiPipelineDropdownOpen === true
                          ? " rotate-180"
                          : " rotate-0 "
                      }`}
                      size={25}
                    />
                  </div>
                </div>

                {userPermissions &&
                  getAllowViewForName("Jobs") &&
                  aiPipelineDropdownOpen && (
                    <AnimatePresence>
                      <motion.div
                        initial={{ opacity: 0, y: -2 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -2 }}
                        viewport={{ once: false }}
                        transition={{ duration: 0.3 }}
                        className=" ml-10 flex flex-col gap-2 "
                      >
                        <div className=" flex flex-col gap-2 ">
                          <div
                            className={`${
                              pathname === "/jobs"
                                ? " border-l-2 border-[white]"
                                : null
                            }`}
                            onClick={() => changeSideActive()}
                            role="button"
                          >
                            <Link
                              className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                               ${
                                 pathname === "/jobs"
                                   ? "text-[white] hover:text-[#20144C]"
                                   : null
                               }`}
                              to={"/jobs"}
                            >
                              <CgWorkAlt title="Jobs" size={25} />
                              Jobs
                            </Link>
                          </div>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  )}
              </>
            )}

            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname
                    .slice(1)
                    .replaceAll("-", " ")
                    .substring(
                      0,
                      location.pathname.slice(1).indexOf("/") > 0
                        ? location.pathname.slice(1).indexOf("/")
                        : location.pathname.length
                    ) === "profile"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                  ${
                    pathname
                      .slice(1)
                      .replaceAll("-", " ")
                      .substring(
                        0,
                        location.pathname.slice(1).indexOf("/") > 0
                          ? location.pathname.slice(1).indexOf("/")
                          : location.pathname.length
                      ) === "profile"
                      ? "text-[white] hover:text-[#20144C]"
                      : null
                  }`}
                  to={"/profile"}
                >
                  <CgProfile title="Profile" size={25} />
                  Profile
                </Link>
              </div>
            </div>
            {/* <div className=" flex flex-col gap-2 ">
              <div
                className={`${pathname === "/connectors"
                  ? " border-l-2 border-[white]"
                  : null
                  }`}
                onClick={() => changeSideActive()}
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                  ${pathname === "/connectors"
                      ? "text-[white] hover:text-[#20144C]"
                      : null
                    }`}
                  to={"/connectors"}
                >
                  <VscDebugDisconnect title="Connectors" size={25} />
                  Connectors
                </Link>
              </div>
            </div> */}
            {userPermissions && getAllowViewForName("Admin") && (
              <>
                <div
                  onClick={() => AdminDropdown()}
                  role="button"
                  className={`justify-between flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300`}
                >
                  <div className="flex flex-row items-center gap-3">
                    <RiAdminLine title="Admin" size={25} />
                    <p>Admin</p>
                  </div>
                  <div>
                    <RiArrowDropDownLine
                      className={` duration-300 ${
                        adminDropdownOpen === true
                          ? " rotate-180"
                          : " rotate-0 "
                      }`}
                      size={25}
                    />
                  </div>
                </div>

                {adminDropdownOpen && (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, y: -2 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -2 }}
                      viewport={{ once: false }}
                      transition={{ duration: 0.3 }}
                      className=" ml-10 flex flex-col gap-2 "
                    >
                      {userPermissions && getAllowViewForName("Users") && (
                        <div
                          className={`${
                            pathname === "/users"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/users"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                            to={"/users"}
                          >
                            <FiUsers title="Users" size={25} />
                            Users
                          </Link>
                        </div>
                      )}

                      {userPermissions && getAllowViewForName("Roles") && (
                        <div
                          className={`${
                            pathname === "/roles"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/roles"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                            to={"/roles"}
                          >
                            <IoExtensionPuzzleOutline title="Roles" size={25} />
                            Roles
                          </Link>
                        </div>
                      )}
                    </motion.div>
                  </AnimatePresence>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-2 mt-3 ml-3">
        <button
          onClick={handleLogout}
          className=" text-[#9B9CA1]  flex flex-row gap-2 items-center font-medium hover:text-[white] duration-300"
        >
          <TbLogout size={22} /> <p>Log Out</p>
        </button>
        <div>
          <p>v0.0.3</p>
        </div>
      </div>
    </menu>
  );
}

export default SideMenu;
