import { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../api";

function EditPrompt({
  handlePromptEditClick,
  closeEditPrompt,
  custom_form,
  form_id,
  setRefresh,
}: any) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedJsonString, setEditedJsonString] = useState(
    JSON.stringify(custom_form, null, 2)
  );
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setEditedJsonString(JSON.stringify(custom_form, null, 2));
  };

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isEditing]);

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedCustomForm = editedJsonString;

    const formData = new FormData();
    formData.append("prompt_form", updatedCustomForm);
    formData.append("ai_form_id", form_id);

    try {
      await api.post(`${process.env.REACT_APP_AIFORMS_EDIT}`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      closeEditPrompt();
      toast.success("Form Updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while updating the form", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  const handleReset = async () => {
    const formData = new FormData();
    formData.append("ai_form_id", form_id);

    try {
      await api.post(`${process.env.REACT_APP_RESET}`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      closeEditPrompt();
      toast.success("Form Reset successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while resetting the form", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md "
      onClick={handlePromptEditClick}
      role="button"
    >
      <div className="bg-white p-4 relative rounded shadow-2xl overflow-y-scroll max-h-[700px]">
        <button
          onClick={closeEditPrompt}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>
        <p className="text-[25px] text-center content-center">Prompt</p>
        {isEditing ? (
          <form onSubmit={handleEditSubmit}>
            <textarea
              ref={textareaRef}
              value={editedJsonString}
              onChange={(e) => setEditedJsonString(e.target.value)}
              className="md:w-[750px] w-[350px] h-[500px] whitespace-break-spaces"
            />

            <div className=" flex flex-row items-center mt-2 gap-4">
              <button
                onClick={handleEditToggle}
                className="bg-green-500 text-white p-2 "
              >
                {isEditing ? "Cancel Edit" : "Edit"}
              </button>
              <button type="submit" className="bg-blue-500 text-white p-2">
                Save
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="md:w-[750px] w-[350px] h-[500px] overflow-scroll whitespace-break-spaces">
              {editedJsonString}
            </div>
            <div className=" flex flex-row items-center justify-between">
              <button
                onClick={handleEditToggle}
                className="bg-green-500 text-white p-2 mt-2"
              >
                {isEditing ? "Cancel Edit" : "Edit"}
              </button>
              <button
                className="bg-[#4D30B5] hover:bg-[#1b1436] duration-300 text-white p-2 "
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default EditPrompt;
