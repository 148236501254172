import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import { RiDeleteBinLine } from "react-icons/ri";
import { fetchData } from "../../../../GlobalFunctions";

function AddAgent({ handleAgentClick, closeAgent, setRefresh }: any) {
  interface TypeIdData {
    type_id: number;
    type_name: string;
  }

  interface MindModelData {
    id: string;
    model_name: string;
    logo: string;
    description: string;
    max_tokens: string;
    training_data_upto: string;
    llm_id: string;
    global_settings: { key: string; temp: string };
  }

  interface SubCategory {
    subcategory_name: string;
    subcategory_id: string;
  }

  const [name, setName] = useState("");
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [typeIdData, setTypeIdData] = useState<TypeIdData[] | null>(null);
  const [showFeatures, setShowFeatures] = useState(true);
  const [selectedSettings, setSelectedSettings] = useState<any | null>(null);
  const [milvusData, setMilvusData] = useState<any | null>(null);
  const [modelData, setModelData] = useState<any | null>(null);
  const [engineData, setEngineData] = useState<any | null>(null);
  const [locations, SetLocations] = useState<string[]>([]);
  const [locationInput, setLocationInput] = useState("");
  const [availableEmails, setAvailableEmails] = useState<string[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [mindModelData, setMindModelData] = useState<MindModelData[] | null>(
    null
  );

  const user_id = localStorage.getItem("user_id") || "default_user";

  const handlelocationChange = (valueToAdd: string) => {
    if (valueToAdd.trim() !== "") {
      if (!locations.includes(valueToAdd)) {
        SetLocations((prevLocations) => [...prevLocations, valueToAdd]);
      }
      setLocationInput("");
    }
  };

  const handleSubCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const subCategoryId = e.target.value;
    setSelectedSubCategoryId(subCategoryId);
  };

  const handleFeaturesClick = () => {
    setShowFeatures(true);
  };

  const handleAdvancedFeaturesClick = () => {
    setShowFeatures(false);
  };

  const handleTypeIdChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const typeId = e.target.value;

    setSelectedTypeId(typeId);

    try {
      const response = await api.get(
        `${process.env.REACT_APP_DEFAULT_SETTINGS}${typeId}`
      );

      setSelectedSettings(response.data);
      setMilvusData(response.data.Milvus);
      setModelData(response.data.Model);
      setEngineData(response.data.Engine);
    } catch (error) {
      console.error("Error fetching default settings:", error);
    }
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(e.target.value);
  };

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_AGENT_TYPES}`, setTypeIdData);
  }, []);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_LLMS_BY_USER}${user_id}`,
      setMindModelData
    );
  }, []);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (/[ !@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]/.test(name)) {
      toast.error(
        "Data agent name must not include spaces or special characters!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        }
      );
      return;
    }

    if (!file) {
      toast.error("Please select an image!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    if (selectedEmails.length !== 0 || locations.length !== 0) {
      try {
        const formData = new FormData();
        formData.append("agent", name);
        formData.append("logo", file);
        formData.append("description", description);
        formData.append("agent_type", selectedTypeId);
        formData.append("model_name", selectedModel);
        if (milvusData) {
          formData.append("milvus_config", JSON.stringify(milvusData));
        }
        if (modelData) {
          formData.append("model_config", JSON.stringify(modelData));
        }
        if (engineData) {
          formData.append("engine_config", JSON.stringify(engineData));
        }
        if (selectedSubCategoryId) {
          formData.append("rag_type", selectedSubCategoryId);
        }
        if (locations) {
          formData.append("locations", JSON.stringify(locations));
        }
        if (selectedEmails) {
          formData.append("emails", JSON.stringify(selectedEmails));
        }

        await api.post(`${process.env.REACT_APP_CREATE_AGENT}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setRefresh((prevRefresh: any) => prevRefresh + 1);
        closeAgent();

        toast.success("Agent has been created successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          const errorMessage = axiosError.response?.data || axiosError.message;

          toast.error(
            `An error occurred while creating the Agent: ${errorMessage}`,
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: "dark",
            }
          );
        } else {
          toast.error("An unexpected error occurred while creating the Agent", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
        }
        console.error("Error creating the Agent:", error);
      }
    } else {
      toast.error("Please select locations or emails!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    if (selectedTypeId) {
      const apiUrl = `${process.env.REACT_APP_SUBCATEGORIES}`;
      const formData = new FormData();
      formData.append("type_id", selectedTypeId);

      api
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSubCategories(response.data);
        })
        .catch((error) => {
          console.error("Error fetching subcategories:", error);
        });
    }
  }, [selectedTypeId]);

  const handleSettingChange = (settingKey: string, value: string) => {
    setMilvusData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };
  const handleModelSettingChange = (settingKey: string, value: string) => {
    setModelData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };

  const handleEngineSettingChange = (settingKey: string, value: string) => {
    setEngineData((prevData: any) => ({
      ...prevData,
      [settingKey]: value,
    }));
  };

  useEffect(() => {
    if (selectedSubCategoryId === "2") {
      const apiUrl = `${process.env.REACT_APP_LIST_EMAILS}`;
      const formData = new FormData();
      formData.append("user_id", user_id);

      api
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setAvailableEmails(response.data);
        })
        .catch((error) => {
          console.error("Error fetching emails:", error);
        });
    }
  }, [selectedSubCategoryId]);

  const handleEmailSelect = (email: string) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails((prevEmails) => prevEmails.filter((e) => e !== email));
    } else {
      setSelectedEmails((prevEmails) => [...prevEmails, email]);
    }
  };

  const handleEmailDelete = (email: string) => {
    setSelectedEmails((prevEmails) => prevEmails.filter((e) => e !== email));
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={handleAgentClick}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeAgent}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">
          Create Data Agent
        </p>
        <div className="mb-4">
          <button
            onClick={handleFeaturesClick}
            className={`mr-2 px-4 py-2 rounded ${
              showFeatures
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Features
          </button>
          <button
            onClick={handleAdvancedFeaturesClick}
            className={`px-4 py-2 rounded ${
              !showFeatures
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Advanced Features
          </button>
        </div>
        {showFeatures ? (
          <form
            onSubmit={handleUploadSubmit}
            className=" md:w-[750px] w-[350px] "
          >
            <div className="p-4 flex flex-col gap-4 max-h-[450px] overflow-y-scroll">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Data Agent Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col lg:flex-row lg:items-center lg:justify-between w-full">
                <div className=" flex flex-col lg:w-[40%]">
                  <label className="pb-2 text-[20px]">Type:</label>
                  <select
                    required
                    value={selectedTypeId}
                    onChange={handleTypeIdChange}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  >
                    <option value="" disabled>
                      Select a Type
                    </option>
                    {typeIdData?.map((type) => (
                      <option key={type?.type_id} value={type?.type_id}>
                        {type?.type_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" flex flex-col lg:w-[40%]">
                  <label className="pb-2 text-[20px]">Select Model:</label>
                  <select
                    required
                    value={selectedModel}
                    onChange={handleModelChange}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  >
                    <option value="" disabled>
                      Select a Model
                    </option>
                    {mindModelData?.map((model) => (
                      <option key={model?.id} value={model?.model_name}>
                        {model?.model_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {selectedTypeId && subCategories.length > 0 && (
                <div className=" flex flex-col">
                  <label className="pb-2 text-[20px]">Subcategory:</label>
                  <select
                    required
                    value={selectedSubCategoryId}
                    onChange={handleSubCategoryChange}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  >
                    <option value="" disabled>
                      Select a Subcategory
                    </option>
                    {subCategories.map((subcategory) => (
                      <option
                        key={subcategory.subcategory_id}
                        value={subcategory.subcategory_id}
                      >
                        {subcategory.subcategory_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div>
                {selectedSubCategoryId === "1" && (
                  <div className=" flex flex-col ">
                    <label className="pb-2 text-[20px]">Location Path:</label>
                    <div className=" w-full flex flex-row">
                      <input
                        required={locations.length === 0}
                        type="text"
                        value={locationInput}
                        onChange={(e) => setLocationInput(e.target.value)}
                        placeholder="Add Location Path"
                        className="border-[2px] md:w-[664px] w-[265px] text-black outline-none p-1 rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          handlelocationChange(locationInput);
                          setLocationInput("");
                        }}
                        className="bg-[#4D30B5] hover:bg-[#1b1436] duration-300 text-white px-2 py-1 ml-2 rounded-lg"
                      >
                        Add
                      </button>
                    </div>
                    {locations.length > 0 && (
                      <div className="mt-2 h-[80px] max-w-[800px] overflow-y-scroll">
                        {locations?.map((key, index) => (
                          <div key={index}>
                            <div className=" flex flex-row items-center">
                              {key}
                              <button
                                type="button"
                                onClick={() => {
                                  SetLocations((prevLocation) =>
                                    prevLocation.filter((_, i) => i !== index)
                                  );
                                }}
                              >
                                <RiDeleteBinLine className="text-red-600 hover:text-red-700 duration-300 ml-2" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>
                {selectedSubCategoryId === "2" && (
                  <div className="flex flex-col">
                    <label className="pb-2 text-[20px]">Select Emails:</label>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-2  overflow-y-scroll">
                      {availableEmails.map((email) => (
                        <button
                          key={email}
                          onClick={(e) => {
                            e.preventDefault();
                            handleEmailSelect(email);
                          }}
                          className={` mb-2 py-2 rounded ${
                            selectedEmails.includes(email)
                              ? "bg-[#4D30B5]  text-white"
                              : "bg-gray-300 text-gray-600"
                          }`}
                        >
                          {email}
                        </button>
                      ))}
                    </div>
                    {selectedEmails && selectedEmails.length > 0 && (
                      <div className="mt-2">
                        <p className="pb-2 text-[20px]">Selected Emails:</p>
                        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                          {selectedEmails.map((email) => (
                            <div
                              key={email}
                              className="flex flex-row items-center mb-2"
                            >
                              <p>{email}</p>
                              <button onClick={() => handleEmailDelete(email)}>
                                <RiDeleteBinLine className="text-red-600 hover:text-red-700 duration-300" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Description:</label>
                <textarea
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="border-[2px] text-black rounded-lg outline-none p-1 resize-none h-[140px] custom-scrollbar-chat"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Image:</label>
                <input
                  required
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create Agent
              </button>
            </div>
          </form>
        ) : (
          <form
            onSubmit={handleUploadSubmit}
            className=" md:w-[750px] w-[350px] "
          >
            <div className="  justify-center items-center max-h-[450px] overflow-y-scroll">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Setting
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(milvusData || {}).map(
                    ([setting, value]: any) => (
                      <tr key={setting}>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {setting}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap outline-none">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleSettingChange(setting, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                  {Object.entries(modelData || {}).map(
                    ([setting, value]: any) => (
                      <tr key={setting}>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {setting}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap outline-none">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleModelSettingChange(setting, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                  {Object.entries(engineData || {}).map(
                    ([setting, value]: any) => (
                      <tr key={setting}>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {setting}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap outline-none">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleEngineSettingChange(setting, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create Data Agent
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default AddAgent;
