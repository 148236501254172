import { toast } from "react-toastify";
import api from "../../../../api";
import { useState } from "react";

function DeleteUser({
  handleDeleteClick,
  closeDeleteRole,
  idEdit,
  nameEdit,
  setRefresh,
}: any) {
  const [id] = useState(idEdit);

  const deleteFiles = async () => {
    const formData = new FormData();
    formData.append("user_id", id);

    try {
      await api.post(`${process.env.REACT_APP_DELETE_USER}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("User Deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while deleting the user", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  return (
    <div
      onClick={handleDeleteClick}
      role="button"
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
    >
      <div className="bg-[#EDEAF8] rounded-lg shadow-2xl w-[420px]">
        <div className=" flex flex-col ">
          <p className="p-6 font-bold">Delete {nameEdit}?</p>
          <div className=" border-b" />
          <div className=" flex flex-row justify-end gap-4 p-6">
            <button
              onClick={closeDeleteRole}
              className=" bg-white rounded-md p-2 hover:bg-[#b5b1c4]  duration-300"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                deleteFiles();
              }}
              className=" bg-red-700 rounded-md p-2 text-white hover:bg-red-900 duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteUser;
