import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { ImSpinner } from "react-icons/im";
import api from "../api";
import { fetchData } from "../GlobalFunctions";

const Instruction = ({
  showCustomInstructions,
  customInstruction,
  setCustomInstruction,
  customInstructionsActive,
  setCustomInstructionsName,
  newChatRefresh,
}: any) => {
  interface InstructionsData {
    id: string;
    prompt_name: string;
    instruction: string;
  }
  const user_id = localStorage.getItem("user_id") || "";

  const [name, setName] = useState("");
  const [description, setDescription] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const [mindInstructionsData, setMindInstructionsData] = useState<
    InstructionsData[] | null
  >(null);
  const [selectedInstruction, setSelectedInstruction] =
    useState<string>(customInstruction);

  useEffect(() => {
    if (customInstructionsActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [customInstructionsActive]);

  const handleChildClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_LIST_INSTROCTIONS}${user_id}`,
      setMindInstructionsData
    );
  }, []);

  const handleInstructionsChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (e.target.value === "") {
      setCustomInstruction("");
      setSelectedInstruction("");
      setCustomInstructionsName("");
      setCurrentIndex(-1);
    } else {
      setCustomInstruction(e.target.value);
      setSelectedInstruction(e.target.value);
      setCustomInstructionsName(
        e.target.options[e.target.selectedIndex].textContent
      );
      setCurrentIndex(
        mindInstructionsData?.findIndex((item) => item.id === e.target.value) ??
          -1
      );
    }
  };
  useEffect(() => {
    if (currentIndex !== -1) {
      setName(
        mindInstructionsData
          ? mindInstructionsData[currentIndex].prompt_name
          : ""
      );
      setDescription(
        mindInstructionsData
          ? mindInstructionsData[currentIndex].instruction
          : ""
      );
    } else {
      setCurrentIndex(-1);
      setName("");
      setDescription("");
    }
  }, [currentIndex]);

  useEffect(() => {
    setCurrentIndex(-1);
    setName("");
    setDescription("");
    setSelectedInstruction("");
  }, [newChatRefresh]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("prompt_name", name);
    formData.append("instruction", description);

    if (currentIndex === -1) {
      try {
        await api.post(
          `${process.env.REACT_APP_CUSTOM_INSTROCTIONS}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Instruction has been created successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } catch (error) {
        toast.error("An error occurred while creating the instruction", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        console.log(error);
      } finally {
        setName("");
        setDescription("");
        fetchData(
          `${process.env.REACT_APP_LIST_INSTROCTIONS}${user_id}`,
          setMindInstructionsData
        );
      }
    } else {
      try {
        await api.post(
          `${process.env.REACT_APP_EDIT_INSTROCTION}${customInstruction}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Instruction has been edited successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } catch (error) {
        toast.error("An error occurred while editing the instruction", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        console.log(error);
      } finally {
        fetchData(
          `${process.env.REACT_APP_LIST_INSTROCTIONS}${user_id}`,
          setMindInstructionsData
        );
      }
    }
  };

  const deleteInstruction = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await api.post(
        `${process.env.REACT_APP_DELETE_INSTRUCTION}${customInstruction}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Instruction has been deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      setCustomInstructionsName("");
      setCustomInstruction(null);
    } catch (error) {
      toast.error("An error occurred while deleting the instruction", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    } finally {
      setName("");
      setDescription("");
      fetchData(
        `${process.env.REACT_APP_LIST_INSTROCTIONS}${user_id}`,
        setMindInstructionsData
      );
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        onClick={showCustomInstructions}
        initial={{ opacity: 0, x: -1 }}
        whileInView={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -1 }}
        viewport={{ once: false }}
        transition={{ duration: 0.7 }}
        className={
          customInstructionsActive
            ? " fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
            : "hidden"
        }
      >
        <div
          className=" bg-[#EDEAF8] rounded-lg shadow-2xl"
          onClick={handleChildClick}
        >
          <div className=" md:w-[750px] w-[350px]">
            <div className=" flex flex-col ">
              <div className="relative flex flex-row items-center justify-between p-4">
                <button
                  onClick={showCustomInstructions}
                  className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
                >
                  <RxCross2 size={25} className="-mt-[1px]" />
                </button>
              </div>
              <div className=" border-b" />
            </div>
            <div className=" ">
              <div className=" p-4 grid lg:grid-cols-3 gap-4 ">
                <label className="pb-2 max-lg:text-center text-black text-[20px]">
                  Select Instruction:
                </label>
                {mindInstructionsData ? (
                  <select
                    required
                    value={selectedInstruction}
                    onChange={handleInstructionsChange}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  >
                    <option value="">Create Instruction</option>
                    {mindInstructionsData.length > 0 &&
                      mindInstructionsData.map((instruction, index) => (
                        <option key={index} value={instruction.id}>
                          {instruction.prompt_name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <div className="animate-spin flex justify-center">
                    <ImSpinner size={30} />
                  </div>
                )}
              </div>
              <div className="border border-b-2 border-[#4D30B5] mb-2 mt-2"></div>
              <form
                onSubmit={handleUploadSubmit}
                className=" md:w-[750px] w-[350px]"
              >
                <div className="p-4">
                  <label className="pb-2 text-black text-[20px]">
                    Create Instruction:
                  </label>

                  <div className="mt-2 flex flex-col ">
                    <label className="pb-2 text-black">Name:</label>
                    <input
                      required
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                    />
                  </div>
                  <div className=" flex flex-col mt-2">
                    <label className="pb-2 text-black">Description:</label>
                    <textarea
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="border-[2px] text-black rounded-lg outline-none p-1 resize-none h-[140px] custom-scrollbar-chat"
                    />
                  </div>
                  <div className="mt-4 flex gap-4 justify-center">
                    {currentIndex === -1 ? (
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Create Instruction
                      </button>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                        >
                          Edit Instruction
                        </button>
                        <button
                          onClick={(e: any) => deleteInstruction(e)}
                          className="bg-red-700 hover:bg-red-900 text-white px-5 py-3 rounded duration-300"
                        >
                          Delete Instruction
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Instruction;
