import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { ImSpinner } from "react-icons/im";
import { fetchData } from "../GlobalFunctions";

const Tag = ({
  showTag,
  tagActive,
  onSelectTag,
  selectedTag1,
  selectedAgentId,
  setSelectedTagName,
}: any) => {
  interface TagData {
    id: string;
    tag_name: string;
  }

  const [mindTagData, setMindTagData] = useState<TagData[] | null>(null);
  const [selectedTag, setSelectedTag] = useState<string>(selectedTag1);

  useEffect(() => {
    if (tagActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [tagActive]);

  const handleChildClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_TAGS_BY_AGENT}${selectedAgentId}`,
      setMindTagData
    );
  }, [selectedAgentId]);

  return (
    <AnimatePresence>
      <motion.div
        onClick={showTag}
        initial={{ opacity: 0, x: -1 }}
        whileInView={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -1 }}
        viewport={{ once: false }}
        transition={{ duration: 0.7 }}
        className={
          tagActive
            ? " fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
            : "hidden"
        }
        role="button"
      >
        <div
          className=" bg-[#EDEAF8] rounded-lg shadow-2xl"
          onClick={handleChildClick}
          role="button"
        >
          <div className=" md:w-[750px] w-[350px]">
            <div className=" flex flex-col ">
              <div className="relative flex flex-row items-center justify-between p-4">
                <p className="text-black">Tags</p>
                <button
                  onClick={showTag}
                  className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
                >
                  <RxCross2 size={25} className="-mt-[1px]" />
                </button>
              </div>
              <div className=" border-b" />
            </div>
            <div className="max-h-[600px] overflow-y-scroll">
              <div className=" p-4 grid grid-cols-3 gap-4 ">
                <div
                  className=" flex flex-col items-center p-2 gap-2 cursor-pointer hover:scale-105 duration-300"
                  onClick={() => {
                    setSelectedTagName("");
                    setSelectedTag("");
                    onSelectTag("");
                    showTag();
                  }}
                  role="button"
                >
                  <div
                    className={`border p-4 rounded-full bg-[#21557b] ${
                      selectedTag === ""
                        ? "border-[#ac4e01] border-[3px]"
                        : " border-[#21557b]"
                    }`}
                  ></div>
                  <div className=" max-sm:text-[10px] text-black">No Tag</div>
                </div>
                {mindTagData ? (
                  mindTagData?.map((user) => (
                    <div
                      key={user?.id}
                      className=" flex flex-col items-center p-2 gap-2 cursor-pointer hover:scale-105 duration-300"
                      onClick={() => {
                        setSelectedTagName(user.tag_name);
                        setSelectedTag(user.id);
                        onSelectTag(user.id);
                        showTag();
                      }}
                      role="button"
                    >
                      <div
                        className={`border p-4 rounded-full bg-[#21557b] ${
                          selectedTag === user.id
                            ? "border-[#ac4e01] border-[3px]"
                            : " border-[#21557b]"
                        }`}
                      ></div>
                      <div className=" max-sm:text-[10px] text-black">
                        {user.tag_name}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="  flex justify-center">
                    <ImSpinner size={30} className="animate-spin" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Tag;
