import { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { RxDotsHorizontal } from "react-icons/rx";

const Paginate = ({
  postsPerPage,
  totalPosts,
  paginate,
  previousPage,
  nextPage,
  currentPage,
}: any) => {
  let totalPages = Math.ceil(totalPosts / postsPerPage);
  const [pageNumbers, setPageNumbers] = useState([1]);
  useEffect(() => {
    setPageNumbers([]);
    totalPages = Math.ceil(totalPosts / postsPerPage);
    if (currentPage < 6) {
      for (let i = 1; i <= totalPages; i++) {
        if (i < 10) {
          setPageNumbers((prevPageNumber) => {
            return [...prevPageNumber, i];
          });
        }
      }
    } else if (currentPage > totalPages - 5 && totalPages > 9) {
      for (let i = totalPages - 8; i <= totalPages; i++) {
        setPageNumbers((prevPageNumber) => {
          return [...prevPageNumber, i];
        });
      }
    } else {
      for (let i = currentPage - 4; i <= currentPage + 4; i++) {
        setPageNumbers((prevPageNumber) => {
          return [...prevPageNumber, i];
        });
      }
    }
  }, [currentPage, totalPosts]);

  return (
    <div className="  my-2">
      <ul className="flex items-center justify-center gap-2">
        <li
          onClick={previousPage}
          role="button"
          className=" py-1 cursor-pointer px-2 text-[#1A2FAE] rounded hover:text-black duration-500"
        >
          <BsArrowLeft size={20} />
        </li>
        {currentPage > 5 && (
          <div className="text-[#1A2FAE]">
            <RxDotsHorizontal size={20} />
          </div>
        )}
        {pageNumbers.map((number) => (
          <li
            key={number}
            onClick={() => paginate(number)}
            role="button"
            className={` ${
              number === currentPage &&
              "bg-[#1A2FAE] text-white hover:text-white"
            } py-1 cursor-pointer px-2 text-[#1A2FAE] rounded  hover:text-black `}
          >
            {number}
          </li>
        ))}
        {currentPage < totalPages - 5 && (
          <div className="text-[#1A2FAE]">
            <RxDotsHorizontal size={20} />
          </div>
        )}

        <li
          onClick={nextPage}
          role="button"
          className=" py-1 cursor-pointer px-2 text-[#1A2FAE] rounded hover:text-black duration-500"
        >
          <BsArrowLeft size={20} className=" rotate-180" />
        </li>
      </ul>
    </div>
  );
};

export default Paginate;
