import { toast } from "react-toastify";
import api from "./api";

export async function createTag(
  newTagName: any,
  selectedAgent: any,
  setNewTagName: any,
  setSelectedTag: any,
  setTagData: any
) {
  const formData = new FormData();
  formData.append("tag_name", newTagName);
  formData.append("data_agent_id", selectedAgent);

  if (newTagName.trim() !== "") {
    try {
      await api.post(`${process.env.REACT_APP_CREATE_TAG}`, formData);

      toast.success("New tag created successfully!", {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });

      const apiUrl = `${process.env.REACT_APP_TAGS_BY_AGENT}${selectedAgent}`;
      const response = await api.get(apiUrl);
      const updatedTagData = response.data;
      setTagData(updatedTagData);
    } catch (error) {
      console.error("Error creating a new tag:", error);
      toast.error("Failed to create a new tag.", {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } finally {
      setNewTagName("");
      setSelectedTag("");
    }
  }
}

export async function fetchTagsByAgent(selectedAgent: any, setTagData: any) {
  if (selectedAgent !== "") {
    const apiUrl = `${process.env.REACT_APP_TAGS_BY_AGENT}${selectedAgent}`;

    const fetchData = async () => {
      try {
        const response = await api.get(apiUrl);

        if (response.status === 200) {
          const data = response.data;
          setTagData(data);
        } else {
          console.error("Failed to fetch data from Tags API");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }
}

export async function fetchData(url: string, setData: Function) {
  try {
    const response = await api.get(url);
    const data = response.data;
    setData(data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export const getPermissionForName = (
  userPermissions: any[],
  permission: string,
  searchName: string
): boolean | null => {
  const foundPermission = userPermissions.find(
    (permission: any) => permission.object_name === searchName
  );

  if (foundPermission) {
    return foundPermission.permissions[permission];
  }

  return null;
};
