import { useState, useRef, ChangeEvent, DragEvent, useEffect } from "react";
import { FiUpload } from "react-icons/fi";
import { BsUpload } from "react-icons/bs";
import { SlCloudUpload } from "react-icons/sl";
import { toast } from "react-toastify";
// import { io } from "socket.io-client";
import api from "../../api";
import { createTag, fetchTagsByAgent } from "../../GlobalFunctions";

function FileUpload() {
  interface AgentData {
    id: string;
    agent: string;
  }
  interface TagData {
    id: string;
    tag_name: string;
  }

  const [files, setFiles] = useState<File[]>([]);
  const [fileContents, setFileContents] = useState<{ name: string }[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [tagData, setTagData] = useState<TagData[] | null>(null);
  const [agentData, setAgentData] = useState<AgentData[] | null>(null);
  const [newTagName, setNewTagName] = useState("");

  const handleNewTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagName(e.target.value);
    setSelectedAgent(selectedAgent);
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_UNSTRUCTURED_AGENT_BY_USER}${user_id}`;

    const fetchData = async () => {
      try {
        const response = await api.get(apiUrl);

        if (response.status === 200) {
          const data = response.data;
          setAgentData(data);
        } else {
          console.error("Failed to fetch data from Agent API");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, []);

  const handleCreateTag = () => {
    createTag(
      newTagName,
      selectedAgent,
      setNewTagName,
      setSelectedTag,
      setTagData
    );
  };

  useEffect(() => {
    fetchTagsByAgent(selectedAgent, setTagData);
  }, [selectedAgent]);

  useEffect(() => {
    if (tagData?.length && tagData?.length > 0) {
      setSelectedTag(tagData[0].id);
    } else {
      setSelectedTag("");
    }
  }, [tagData]);

  const handleAgentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAgent(e.target.value);
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTag(e.target.value);
  };

  const allowedFileExtensions = [".pdf", ".doc", ".docx"];
  const MAX_FILE_SIZE_MB = 10;

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles).filter(
        (file) =>
          allowedFileExtensions.some((ext) =>
            file.name.toLowerCase().endsWith(ext)
          ) && file.size <= MAX_FILE_SIZE_MB * 1024 * 1024
      );

      const uniqueNewFiles = newFiles.filter(
        (file) =>
          !fileContents.some((existingFile) => existingFile.name === file.name)
      );

      setFiles([...files, ...uniqueNewFiles]);
      setFileContents([
        ...fileContents,
        ...uniqueNewFiles.map((file) => ({ name: file.name })),
      ]);
    }
  };

  const handleFileDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles) {
      const newFiles = Array.from(droppedFiles).filter(
        (file) =>
          allowedFileExtensions.some((ext) =>
            file.name.toLowerCase().endsWith(ext)
          ) && file.size <= MAX_FILE_SIZE_MB * 1024 * 1024
      );

      const uniqueNewFiles = newFiles.filter(
        (file) =>
          !fileContents.some((existingFile) => existingFile.name === file.name)
      );

      setFiles([...files, ...uniqueNewFiles]);
      setFileContents([
        ...fileContents,
        ...uniqueNewFiles.map((file) => ({ name: file.name })),
      ]);
    }
  };

  const handleDeleteFile = (index: number) => {
    const updatedFiles = [...files];
    const updatedFileContents = [...fileContents];
    updatedFiles.splice(index, 1);
    updatedFileContents.splice(index, 1);
    setFiles(updatedFiles);
    setFileContents(updatedFileContents);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async () => {
    if (files.length > 0 && selectedAgent?.trim() !== "") {
      setIsLoading(true);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("data_agent", selectedAgent);
      formData.append("user_id", user_id);
      formData.append("tag_id", selectedTag);

      try {
        const response = await api.post(
          `${process.env.REACT_APP_UPLOAD_FILES}`,
          formData
        );

        if (response.status === 200) {
          toast.success("Files have been uploaded successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          setFileContents([]);
          setFiles([]);
          setSelectedAgent("");
        } else {
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        setFileContents([]);
        setFiles([]);
        setSelectedAgent("");
        setSelectedTag("");
        setIsLoading(false);
      } finally {
        setIsLoading(false);
        setFileContents([]);
        setSelectedAgent("");
        setSelectedTag("");
        setFiles([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };

  // const SERVER_URL = "http://172.15.15.34.5000";
  // const NAMESPACE = "/files";
  // const socket = io(`${SERVER_URL}${NAMESPACE}`);
  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("Connected to server");
  //     socket.emit("join", { user_id: user_id });
  //   });
  //   socket.on("token", (token: { data: string }) => {
  //     const responseMessage: Message = {
  //       type: "response",
  //       message: token.data,
  //     };
  //     console.log(responseMessage, "response");
  //   });
  //   return () => {
  //     socket.off("connect");
  //     socket.off("token");
  //   };
  // }, [user_id]);

  return (
    <div>
      <div className="flex flex-row gap-2 mb-5 items-center">
        <button
          onClick={handleSubmit}
          disabled={
            isLoading ||
            selectedTag === "" ||
            selectedTag === "createNewTag" ||
            files.length === 0
          }
          className={`px-4 ${
            isLoading ||
            selectedTag === "" ||
            selectedTag === "createNewTag" ||
            files.length === 0
              ? "bg-[#20144C]"
              : "bg-[#413183]"
          } p-2 rounded-lg flex flex-row items-center gap-2 text-white hover:bg-[#20144C] duration-300`}
        >
          <BsUpload />
          <p>Upload Files</p>
        </button>
        {/* <div className=" h-[40px] border-l-2 mx-2 border-[#4D30B5]"></div>
        <div className=" ">
          <button className="bg-[#EDEAF8] p-3 rounded-lg hover:bg-[#b5b1c4] duration-300">
            <BsThreeDots />
          </button>
        </div> */}
      </div>
      <div className="flex flex-col items-center space-y-4">
        <div
          className="border-dashed border-2 border-gray-400 p-4 rounded-lg w-full h-[400px] flex flex-col items-center justify-center cursor-pointer"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleFileDrop}
          onClick={() => fileInputRef.current?.click()}
          role="button"
        >
          {fileContents.length > 0 ? (
            <div className=" max-h-[200px] overflow-y-scroll">
              {fileContents.map((file, index) => (
                <div key={index}>{file.name}</div>
              ))}
            </div>
          ) : (
            <div className=" flex flex-col items-center gap-4">
              <FiUpload size={50} color="#20144C" />
              <p className="text-sm">Click or drag and drop your files here</p>
            </div>
          )}
          <button
            disabled={isLoading}
            className={`bg-[#413183] mt-10 text-white px-4 py-2 rounded-md hover:bg-[#20144C] duration-300 ${
              isLoading && "opacity-50 cursor-not-allowed"
            }`}
          >
            {isLoading ? (
              <div className=" flex flex-row gap-2 items-center">
                <p>Uploading</p>
                <SlCloudUpload size={25} className=" animate-pulse" />
              </div>
            ) : (
              "Select Files"
            )}
          </button>
          <div className=" py-2 flex flex-col items-center gap-2">
            <p>Supported formats</p>
            <div className=" flex flex-row gap-2 text-white">
              <span className=" bg-[#20144C] px-2 p-1 rounded-lg">PDF</span>
              <span className=" bg-[#20144C] px-2 p-1 rounded-lg">DOC</span>
              <span className=" bg-[#20144C] px-2 p-1 rounded-lg">DOCX</span>
            </div>
            <p>Document must be less than 10 MB</p>
          </div>
        </div>
      </div>
      <div className=" flex flex-row gap-4 ">
        <div className=" flex flex-col gap-4 py-4 ">
          <label className="">Select Data Agent:</label>
          <select
            required
            value={selectedAgent}
            onChange={handleAgentChange}
            className="px-4 py-2 pl-0 border-b border-[#4D30B5] outline-none hover:bg-[#EDEAF8] cursor-pointer duration-300"
          >
            <option value="" disabled>
              Select a Data Agent
            </option>
            {agentData &&
              agentData.length > 0 &&
              agentData?.map((llm) => (
                <option key={llm.id} value={llm.id}>
                  {llm.agent}
                </option>
              ))}
          </select>
        </div>
        {selectedAgent && (
          <div className=" flex flex-col gap-4 py-4 ">
            <label className="">Select Tag:</label>
            <div className="flex items-center">
              <select
                required
                value={selectedTag}
                onChange={handleTagChange}
                className="px-4 py-2 pl-0 border-b border-[#4D30B5] outline-none hover:bg-[#EDEAF8] cursor-pointer duration-300"
              >
                <option value="" disabled>
                  Select a Tag
                </option>
                {tagData?.map((tag) => (
                  <option key={tag.id} value={tag.id}>
                    {tag.tag_name}
                  </option>
                ))}
                <option value="createNewTag">Create New Tag</option>
              </select>
              {selectedTag === "createNewTag" && (
                <div className="ml-4 flex flex-row gap-2">
                  <input
                    type="text"
                    placeholder="New Tag Name"
                    value={newTagName}
                    onChange={handleNewTagChange}
                    className=" px-4 py-2 pl-0 border-b border-[#4D30B5] outline-none hover:bg-[#EDEAF8] duration-300"
                  />
                  <button
                    onClick={handleCreateTag}
                    className="bg-[#413183] text-white px-2 py-1 rounded-md hover:bg-[#20144C] duration-300"
                  >
                    Create Tag
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <input
        type="file"
        multiple
        accept="application/pdf, .docx"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileSelect}
      />
      <div>
        <div>
          {fileContents.map((file, index) => (
            <div key={index} className=" flex flex-row gap-4 items-center">
              <p>{file.name}</p>
              <button
                className="text-red-500 hover:text-red-700"
                onClick={() => handleDeleteFile(index)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
