import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { RiLoaderLine } from "react-icons/ri";
import Paginate from "../../../Pagination";
import { GoFileDirectory } from "react-icons/go";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import CreateJob from "./CreateJob";
import api from "../../../api";
import { toast } from "react-toastify";
import { ImSpinner } from "react-icons/im";
import DeleteJob from "./DeleteJob";
// import EditJob from "./EditJob";
import { IoIosTimer } from "react-icons/io";
import { IoCheckmark } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineLoading } from "react-icons/ai";
import { Link } from "react-router-dom";
import { fetchData } from "../../../GlobalFunctions";

let currentIdDatabase = 0;

const Jobs = () => {
  interface MindJobData {
    job_id: string;
    job_name: string;
    last_runtime: string;
    directory_path: string;
    allowed_extensions: [];
    ip_address: string;
    remote_username: string;
    time: string;
    frequency: string;
    crawler_location: string;
    success: string;
    failed: string;
    running: string;
  }

  const user_id = localStorage.getItem("user_id") || "default_user";

  const [jobData, setJobData] = useState<MindJobData[] | null>(null);
  const [refresh, setRefresh] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openedJobs, setOpenedJobs] = useState<string[]>([]);

  // const [jobEditOpen, setJobEditOpen] = useState(false);

  // const openEditJob = async (id: number) => {
  //   currentIdDatabase = id;
  //   setJobEditOpen(true);
  // };

  // const closeEditJob = () => {
  //   setJobEditOpen(false);
  // };

  // const handleJobEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   if (e.target === e.currentTarget) {
  //     closeEditJob();
  //   }
  // };

  const handleOpenedJobs = (job: string) => {
    setOpenedJobs((prevOpenedJobs) => {
      if (prevOpenedJobs.includes(job)) {
        return prevOpenedJobs.filter((extension) => extension !== job);
      } else {
        return [...prevOpenedJobs, job];
      }
    });
  };

  const openDeleteButton = (id: number) => {
    currentIdDatabase = id;
    setOpenDelete(true);
  };

  const closeDeleteCancelButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setOpenDelete(false);
    }
  };

  const closeDeleteButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setJobData(null);
      setOpenDelete(false);
    }
  };

  useEffect(() => {
    setOpenDelete(false);
    setCurrentPage(1);
    fetchData(`${process.env.REACT_APP_JOBS}${user_id}`, setJobData);
  }, [refresh]);

  const [createJob, setCreateJob] = useState(false);

  const openJob = () => {
    setCreateJob(true);
  };

  const closeJob = () => {
    // setName("");
    // setFile(null);
    setCreateJob(false);
  };

  const handleJobOpenClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeJob();
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = jobData?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: React.SetStateAction<number>) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (jobData && currentPage !== Math.ceil(jobData.length / postsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const runJob = async (job_name: string) => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_RUN_JOB}${job_name}`
      );
      console.log(response);
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("Error triggering job", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <h2 className="text-[20px] mb-2">Jobs:</h2>
        <div className="flex flex-row justify-end ">
          <button
            onClick={openJob}
            className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
          >
            Add Job
          </button>
        </div>
      </div>
      <div>
        {jobData && jobData.length > 0 ? (
          <div
            className="overflow-scroll mt-2 grid grid-cols-1 gap-2"
            style={{ maxHeight: "calc(100vh - 183px)" }}
          >
            {currentPosts && currentPosts.length > 0 ? (
              currentPosts &&
              currentPosts.map((job, index) => (
                <div
                  className={`relative flex flex-col gap-4 p-2  hover:bg-gray-400 rounded-lg duration-300 bg-[#EDEAF8] cursor-pointer`}
                  key={job?.job_id}
                  onClick={(e) => {
                    handleOpenedJobs(job?.job_name || "");
                  }}
                >
                  <div
                    className={`${
                      openedJobs.includes(job?.job_name)
                        ? " flex flex-row justify-between items-center"
                        : " grid grid-cols-10 items-center"
                    } `}
                  >
                    <div className="text-[18px] font-semibold  col-span-4">
                      {job?.job_name}
                    </div>
                    {!openedJobs.includes(job?.job_name) && (
                      <div className="flex flex-row gap-2 col-span-4 items-center">
                        <div>
                          <strong>Last Run:</strong> {job?.last_runtime}
                        </div>
                        {job.last_runtime === "Running" ? (
                          <div>
                            <RiLoaderLine
                              size={25}
                              color="green"
                              className="animate-spin mt-[2px]"
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                    <div className="flex flex-row justify-end gap-2 col-span-2 items-center">
                      {/* <button
                        onClick={() => openEditJob(index)}
                        className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white h-[27px] p-1 rounded duration-300"
                      >
                        <BiEdit />
                      </button> */}
                      {!openedJobs.includes(job?.job_name) && (
                        <div>
                          <button
                            onClick={() => runJob(job?.job_name)}
                            className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white px-2 py-1 rounded duration-300"
                          >
                            Run
                          </button>
                        </div>
                      )}
                      <button
                        className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white h-[27px] p-1 rounded duration-300"
                        onClick={(e) => {
                          e.stopPropagation();
                          openDeleteButton(index);
                        }}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                  {openedJobs.includes(job?.job_name) && (
                    <>
                      <div className="flex flex-col gap-1">
                        <div className="flex flex-row gap-2">
                          <GoFileDirectory
                            size={25}
                            color="#4D30B5"
                            className="-mt-[1px]"
                          />
                          <strong> Directory Path:</strong>{" "}
                          {job?.directory_path}
                        </div>
                        <div className="flex flex-row gap-2">
                          <IoExtensionPuzzleOutline
                            size={25}
                            color="#4D30B5"
                            className="-mt-[1px]"
                          />
                          <strong>Extensions:</strong>{" "}
                          {job?.allowed_extensions.map(
                            (extension, index) =>
                              (index > 0 ? " | " : "") + extension
                          )}
                        </div>
                        <div className="flex flex-row gap-2">
                          <IoIosTimer
                            size={25}
                            color="#4D30B5"
                            className="-mt-[1px]"
                          />
                          <strong>Frequency:</strong> {job?.frequency}
                        </div>
                        <div className="flex flex-row gap-2">
                          <IoCheckmark
                            size={25}
                            color="green"
                            className="-mt-[1px]"
                          />
                          <strong>Success:</strong> {job?.success}
                        </div>
                        <div className="flex flex-row gap-2">
                          <RxCross2
                            size={25}
                            color="red"
                            className="-mt-[1px]"
                          />
                          <strong>Failed:</strong> {job?.failed}
                        </div>
                        <div className="flex flex-row gap-2">
                          <AiOutlineLoading
                            size={25}
                            color="#4D30B5"
                            className="-mt-[1px] animate-spin"
                          />
                          <strong>Running:</strong> {job?.running}
                        </div>
                        <Link to={`/jobs/job_details/${job?.job_name}`}>
                          View More
                        </Link>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row gap-2">
                          <div>
                            <strong>Last Run:</strong> {job?.last_runtime}
                          </div>
                          {job.last_runtime === "Running" && (
                            <div>
                              <RiLoaderLine
                                size={25}
                                color="green"
                                className="animate-spin mt-[2px]"
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          <button
                            onClick={() => runJob(job?.job_name)}
                            className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white px-2 py-1 rounded duration-300"
                          >
                            Run
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <div className="flex justify-center">
                <ImSpinner size={30} className=" animate-spin" />
              </div>
            )}
          </div>
        ) : (
          <div className=" flex justify-center">No Jobs Found!</div>
        )}
        <div className="fixed bottom-0 left-[50%]">
          {jobData && jobData.length > 0 && (
            <Paginate
              postsPerPage={postsPerPage}
              totalPosts={jobData?.length}
              paginate={paginate}
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
      {createJob && (
        <CreateJob
          handleJobOpenClick={handleJobOpenClick}
          closeJob={closeJob}
          setRefresh={setRefresh}
        />
      )}
      {/* {jobData
        ? jobEditOpen && (
            <EditJob
              handleJobEditClick={handleJobEditClick}
              closeEditJob={closeEditJob}
              idEdit={jobData[currentIdDatabase].job_id}
              nameEdit={jobData[currentIdDatabase].job_name}
              extensionsEdit={jobData[currentIdDatabase].allowed_extensions}
              directoryEdit={jobData[currentIdDatabase].directory_path}
              ipaddressEdit={jobData[currentIdDatabase].ip_address}
              usernameEdit={jobData[currentIdDatabase].remote_username}
              setRefresh={setRefresh}
            />
          )
        : null} */}
      {jobData
        ? openDelete && (
            <DeleteJob
              closeDeleteButton={closeDeleteButton}
              nameDelete={jobData[currentIdDatabase]?.job_name}
              setRefresh={setRefresh}
              closeDeleteCancelButton={closeDeleteCancelButton}
            />
          )
        : null}
    </div>
  );
};

export default Jobs;
