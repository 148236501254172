import Gmail from "../../../../Assets/Gmail_Logo_512px.png";
import Outlook from "../../../../Assets/Outllok.png";
import api from "../../../api";
import { useEffect, useState } from "react";
import DisconnectEmail from "./DisconnectEmail";

let currentIdDatabase = 0;

const EmailConnectors = ({ userData }: any) => {
  interface MailData {
    email: string;
    type: string;
    email_id: string;
  }

  const user_id = localStorage.getItem("user_id") || "default_user";
  const [openDisconnectEmail, setOpenDisconnectEmail] = useState(false);
  const [mailData, setMailData] = useState<MailData[] | null>(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setOpenDisconnectEmail(false);

    const fetchData = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_CONNECTED_EMAILS}${user_id}`
        );
        const data = response.data;
        setMailData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [refresh]);

  const handleGmailRequest = async () => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_GMAIL_CONNECT}`,
        {
          user_id: user_id,
        }
      );
      const { login_url } = JSON.parse(response.data.body);
      if (login_url) {
        window.open(login_url, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleOutlookRequest = async () => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_OUTLOOK_CONNECT}`,
        {
          user_id: user_id,
        }
      );
      const login_url = response.data.login_url;
      if (login_url) {
        window.open(login_url, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const openDisconnectEmailButton = (id: number) => {
    currentIdDatabase = id;
    setOpenDisconnectEmail(true);
  };

  const closeDisconnectCancelButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setOpenDisconnectEmail(false);
    }
  };

  const closeDisconnectButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setOpenDisconnectEmail(false);
    }
  };

  return (
    <div className=" flex flex-col ">
      <div className=" grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="flex flex-row justify-center">
          <div
            className={`flex flex-col lg:w-[500px] gap-4 p-8 m-4 items-center rounded-lg duration-300 bg-[#EDEAF8]`}
          >
            <div className="flex flex-row gap-4">
              <img
                className=" w-[80px] h-[70px]"
                src={Outlook}
                alt="Outlook"
                loading="lazy"
              />
            </div>
            <div className=" flex  items-center">
              <p className=" text-[25px]">Outlook</p>
            </div>
            <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
              <p className="text-center">Connect your Outlook Data</p>
            </div>

            <div className=" px-4">
              <button
                className=" bg-[#135991] hover:bg-[#152a3b] duration-300 text-white p-2 rounded-lg"
                onClick={handleOutlookRequest}
              >
                Connect
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center">
          <div
            className={` flex flex-col lg:w-[500px] gap-4 p-8 m-4 items-center  rounded-lg duration-300 bg-[#EDEAF8]`}
          >
            <div className="flex flex-row gap-4">
              <img
                className=" w-[80px] h-[70px]"
                src={Gmail}
                alt="Gmail"
                loading="lazy"
              />
            </div>
            <div className=" flex  items-center ">
              <p className=" text-[25px]">Gmail</p>
            </div>
            <div className="max-w-[400px] max-h-[200px] overflow-y-scroll custom-scrollbar-chat">
              <p className="text-center">Connect your Gmail Data</p>
            </div>
            <div className=" px-4">
              <button
                className=" bg-[#135991] hover:bg-[#152a3b] duration-300 text-white p-2 rounded-lg"
                onClick={handleGmailRequest}
              >
                Connect
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex justify-center overflow-scroll"
        style={{ maxHeight: "calc(100vh - 410px)" }}
      >
        <div className="w-[96%] overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left">
            <thead className="border-b bg-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3 w-20">
                  ID
                </th>
                <th scope="col" className=" justify-center px-6 py-3 w-20">
                  Type
                </th>
                <th scope="col" className="px-9 py-4">
                  Mail
                </th>

                <th scope="col" className="px-6 py-3 w-[180px] ">
                  <div className="flex justify-center">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {mailData?.map((data, index) => (
                <tr
                  key={index}
                  className={`bg-gray-200 border-b ${
                    index % 2 === 0 ? "bg-gray-200" : "bg-gray-300"
                  } `}
                >
                  <td className="px-6 py-4">{index + 1}</td>
                  <td className=" justify-center px-6 py-4">
                    {data.type === "Gmail" ? (
                      <img
                        className=" w-[30px] h-[26px]"
                        src={Gmail}
                        alt="Outlook"
                        loading="lazy"
                      />
                    ) : (
                      <img
                        className=" w-[30px] h-[28px]"
                        src={Outlook}
                        alt="Outlook"
                        loading="lazy"
                      />
                    )}
                  </td>
                  <td className="px-9 py-4">{data.email}</td>

                  <td className="px-6 py-4 flex flex-row gap-2  justify-center">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();

                        openDisconnectEmailButton(index);
                      }}
                      className=" bg-[#135991] hover:bg-[#152a3b] text-white p-2 rounded duration-300"
                    >
                      Disconnect
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {mailData
        ? openDisconnectEmail && (
            <DisconnectEmail
              closeDisconnectButton={closeDisconnectButton}
              closeDisconnectCancelButton={closeDisconnectCancelButton}
              idEdit={mailData[currentIdDatabase].email_id}
              emailEdit={mailData[currentIdDatabase].email}
              setRefresh={setRefresh}
            />
          )
        : null}
    </div>
  );
};

export default EmailConnectors;
