import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSunburst from "highcharts/modules/sunburst";
import HighchartsFunnel from "highcharts/modules/funnel";
import HighchartsTimeline from "highcharts/modules/timeline";
import HighchartsVariablePie from "highcharts/modules/variable-pie";
import { useState } from "react";

HighchartsMore(Highcharts);
HighchartsSunburst(Highcharts);
HighchartsFunnel(Highcharts);
HighchartsTimeline(Highcharts);
HighchartsVariablePie(Highcharts);

const analyticsData = {
  responseTime: {
    average: 1.5,
    min: 0.3,
    max: 3.2,
    percentile_95: 2.8,
    dailyResponseTimes: [
      { date: "2024-10-01", responseTime: 1.7 },
      { date: "2024-10-02", responseTime: 1.6 },
      { date: "2024-10-03", responseTime: 1.5 },
      { date: "2024-10-04", responseTime: 1.4 },
      { date: "2024-10-05", responseTime: 1.8 },
      { date: "2024-10-06", responseTime: 1.9 },
      { date: "2024-10-07", responseTime: 1.6 },
    ],
  },
  messagesPerDay: [
    { date: "2024-10-01", messages: 5400 },
    { date: "2024-10-02", messages: 5200 },
    { date: "2024-10-03", messages: 5600 },
    { date: "2024-10-04", messages: 5300 },
    { date: "2024-10-05", messages: 5000 },
    { date: "2024-10-06", messages: 4800 },
    { date: "2024-10-07", messages: 5500 },
  ],
  messageApproval: {
    totalLikes: 2500,
    totalDislikes: 500,
    neutral: 2000,
    dailyApprovalStats: [
      { date: "2024-10-01", likes: 245, dislikes: 55, neutral: 200 },
      { date: "2024-10-02", likes: 260, dislikes: 60, neutral: 180 },
      { date: "2024-10-03", likes: 240, dislikes: 50, neutral: 210 },
      { date: "2024-10-04", likes: 250, dislikes: 45, neutral: 220 },
      { date: "2024-10-05", likes: 235, dislikes: 50, neutral: 215 },
      { date: "2024-10-06", likes: 230, dislikes: 60, neutral: 210 },
      { date: "2024-10-07", likes: 255, dislikes: 40, neutral: 205 },
    ],
  },
  accuracy: {
    accurateMessages: 85,
    inaccurateMessages: 15,
    dailyAccuracyStats: [
      { date: "2024-10-01", accurate: 4100, inaccurate: 900 },
      { date: "2024-10-02", accurate: 4200, inaccurate: 1000 },
      { date: "2024-10-03", accurate: 4300, inaccurate: 800 },
      { date: "2024-10-04", accurate: 4000, inaccurate: 1000 },
      { date: "2024-10-05", accurate: 3900, inaccurate: 1100 },
      { date: "2024-10-06", accurate: 4150, inaccurate: 850 },
      { date: "2024-10-07", accurate: 4050, inaccurate: 950 },
    ],
  },
  countryStats: [
    { country: "USA", percentage: 30 },
    { country: "UK", percentage: 20 },
    { country: "Canada", percentage: 15 },
    { country: "Germany", percentage: 10 },
    { country: "India", percentage: 15 },
    { country: "Australia", percentage: 10 },
  ],
  peakTimes: [
    { time: "10:00-12:00", percentage: 25 },
    { time: "17:00-19:00", percentage: 20 },
    { time: "13:00-15:00", percentage: 15 },
    { time: "09:00-10:00", percentage: 10 },
    { time: "19:00-21:00", percentage: 5 },
    { time: "00:00-06:00", percentage: 5 },
  ],
  funnelChart: {
    totalQuestions: 100000,
    meaningfulEngagements: 80000,
    accurateResponses: 65000,
    positiveFeedback: 55000,
    followUpQuestions: 20000,
  },
  windRoseChart: [
    { direction: "North", percentage: 15 },
    { direction: "East", percentage: 35 },
    { direction: "South", percentage: 25 },
    { direction: "West", percentage: 25 },
  ],
  mostRequestedPDFs: [
    { title: "User Guide to AI-Assisted Conversations", requests: 5000 },
    { title: "AI Features Overview", requests: 3200 },
    { title: "Getting Started with ChatBot", requests: 2800 },
    { title: "Advanced User Manual", requests: 2600 },
    { title: "Security Best Practices", requests: 2400 },
    { title: "Understanding NLP", requests: 2200 },
    { title: "AI and Ethics", requests: 2000 },
  ],
  userRatings: [
    {
      date: "2024-10-01",
      averageRating: 4.3,
      fiveStar: 300,
      fourStar: 250,
      threeStar: 100,
      twoStar: 30,
      oneStar: 20,
    },
    {
      date: "2024-10-02",
      averageRating: 4.1,
      fiveStar: 290,
      fourStar: 240,
      threeStar: 110,
      twoStar: 40,
      oneStar: 20,
    },
    {
      date: "2024-10-03",
      averageRating: 4.2,
      fiveStar: 295,
      fourStar: 245,
      threeStar: 105,
      twoStar: 35,
      oneStar: 20,
    },
    {
      date: "2024-10-04",
      averageRating: 4.0,
      fiveStar: 280,
      fourStar: 235,
      threeStar: 120,
      twoStar: 45,
      oneStar: 25,
    },
    {
      date: "2024-10-05",
      averageRating: 4.3,
      fiveStar: 305,
      fourStar: 255,
      threeStar: 90,
      twoStar: 25,
      oneStar: 25,
    },
    {
      date: "2024-10-06",
      averageRating: 4.4,
      fiveStar: 315,
      fourStar: 265,
      threeStar: 85,
      twoStar: 20,
      oneStar: 15,
    },
    {
      date: "2024-10-07",
      averageRating: 4.2,
      fiveStar: 295,
      fourStar: 250,
      threeStar: 100,
      twoStar: 35,
      oneStar: 20,
    },
  ],
  departments: [
    {
      department: "Customer Support",
      tokens_used: 414380,
      users: 156,
      activeUsers: 134,
      monthlyGrowth: 12,
      avgTokensPerUser: 2656,
    },
    {
      department: "Engineering",
      tokens_used: 363479,
      users: 89,
      activeUsers: 82,
      monthlyGrowth: 5,
      avgTokensPerUser: 4084,
    },
    {
      department: "Marketing",
      tokens_used: 463314,
      users: 100,
      activeUsers: 90,
      monthlyGrowth: 8,
      avgTokensPerUser: 4633,
    },
    {
      department: "Sales",
      tokens_used: 211112,
      users: 70,
      activeUsers: 65,
      monthlyGrowth: 7,
      avgTokensPerUser: 3016,
    },
    {
      department: "Finance",
      tokens_used: 334520,
      users: 95,
      activeUsers: 88,
      monthlyGrowth: 6,
      avgTokensPerUser: 3521,
    },
    {
      department: "Product",
      tokens_used: 483715,
      users: 120,
      activeUsers: 110,
      monthlyGrowth: 9,
      avgTokensPerUser: 4031,
    },
  ],
  tokensPerSecond: [
    {
      date: "2024-10-01",
      tokens_per_second: 69.78,
    },
    {
      date: "2024-10-02",
      tokens_per_second: 62.15,
    },
    {
      date: "2024-10-03",
      tokens_per_second: 58.32,
    },
    {
      date: "2024-10-04",
      tokens_per_second: 97.0,
    },
    {
      date: "2024-10-05",
      tokens_per_second: 76.64,
    },
    {
      date: "2024-10-06",
      tokens_per_second: 67.66,
    },
    {
      date: "2024-10-07",
      tokens_per_second: 92.23,
    },
    {
      date: "2024-10-08",
      tokens_per_second: 92.5,
    },
    {
      date: "2024-10-09",
      tokens_per_second: 85.37,
    },
    {
      date: "2024-10-10",
      tokens_per_second: 68.6,
    },
    {
      date: "2024-10-11",
      tokens_per_second: 66.56,
    },
    {
      date: "2024-10-12",
      tokens_per_second: 51.46,
    },
    {
      date: "2024-10-13",
      tokens_per_second: 61.96,
    },
    {
      date: "2024-10-14",
      tokens_per_second: 90.37,
    },
    {
      date: "2024-10-15",
      tokens_per_second: 78.41,
    },
    {
      date: "2024-10-16",
      tokens_per_second: 65.73,
    },
    {
      date: "2024-10-17",
      tokens_per_second: 77.49,
    },
    {
      date: "2024-10-18",
      tokens_per_second: 56.04,
    },
    {
      date: "2024-10-19",
      tokens_per_second: 86.8,
    },
    {
      date: "2024-10-20",
      tokens_per_second: 93.0,
    },
    {
      date: "2024-10-21",
      tokens_per_second: 84.83,
    },
    {
      date: "2024-10-22",
      tokens_per_second: 98.59,
    },
    {
      date: "2024-10-23",
      tokens_per_second: 69.89,
    },
    {
      date: "2024-10-24",
      tokens_per_second: 63.75,
    },
    {
      date: "2024-10-25",
      tokens_per_second: 64.46,
    },
    {
      date: "2024-10-26",
      tokens_per_second: 62.07,
    },
    {
      date: "2024-10-27",
      tokens_per_second: 62.44,
    },
    {
      date: "2024-10-28",
      tokens_per_second: 66.7,
    },
    {
      date: "2024-10-29",
      tokens_per_second: 60.97,
    },
    {
      date: "2024-10-30",
      tokens_per_second: 75.8,
    },
  ],
};

const HomeScreen = () => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const responseTimeOptions = {
    chart: { type: "line" },
    title: { text: "Daily Response Times" },
    xAxis: {
      categories: analyticsData.responseTime.dailyResponseTimes.map(
        (item) => item.date
      ),
    },
    series: [
      {
        name: "Response Time",
        data: analyticsData.responseTime.dailyResponseTimes.map(
          (item) => item.responseTime
        ),
      },
    ],
  };

  const messagesPerDayOptions = {
    chart: { type: "column" },
    title: { text: "Messages Per Day" },
    xAxis: {
      categories: analyticsData.messagesPerDay.map((item) => item.date),
    },
    series: [
      {
        name: "Messages",
        data: analyticsData.messagesPerDay.map((item) => item.messages),
      },
    ],
  };

  const approvalOptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Message Approval Distribution Over Time",
    },
    xAxis: {
      categories: analyticsData.messageApproval.dailyApprovalStats.map(
        (item) => item.date
      ),
    },
    yAxis: {
      title: {
        text: "Number of Responses",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    series: [
      {
        name: "Likes",
        data: analyticsData.messageApproval.dailyApprovalStats.map(
          (item) => item.likes
        ),
        color: "#36B37E",
      },
      {
        name: "Neutral",
        data: analyticsData.messageApproval.dailyApprovalStats.map(
          (item) => item.neutral
        ),
        color: "#8993A4",
      },
      {
        name: "Dislikes",
        data: analyticsData.messageApproval.dailyApprovalStats.map(
          (item) => -item.dislikes
        ),
        color: "#FF5630",
      },
    ],
  };

  const countryStatsOptions = {
    chart: {
      type: "waterfall",
    },
    title: {
      text: "Usage by Country",
    },
    xAxis: {
      categories: analyticsData.countryStats.map((item) => item.country),
    },
    yAxis: {
      title: {
        text: "Percentage (%)",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "<b>{point.y}%</b> of total usage",
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y}%",
        },
      },
    },
    series: [
      {
        upColor: "#36B37E",
        color: "#1D6196",
        data: analyticsData.countryStats.map((item) => ({
          y: item.percentage,
          name: item.country,
        })),
        pointPadding: 0,
      },
    ],
  };

  const funnelChartOptions = {
    chart: {
      type: "funnel",
    },
    title: {
      text: "Conversation Funnel",
    },
    plotOptions: {
      funnel: {
        neckWidth: "30%",
        neckHeight: "25%",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y:,.0f}",
          softConnector: true,
        },
        width: "90%",
      },
    },
    series: [
      {
        name: "Conversations",
        data: [
          ["Total Questions", analyticsData.funnelChart.totalQuestions],
          [
            "Meaningful Engagements",
            analyticsData.funnelChart.meaningfulEngagements,
          ],
          ["Accurate Responses", analyticsData.funnelChart.accurateResponses],
          ["Positive Feedback", analyticsData.funnelChart.positiveFeedback],
          ["Follow-up Questions", analyticsData.funnelChart.followUpQuestions],
        ],
      },
    ],
  };

  const windRoseOptions = {
    chart: {
      polar: true,
      type: "column",
    },
    title: {
      text: "Distribution Analysis",
    },
    subtitle: {
      text: "Directional Distribution",
    },
    pane: {
      size: "85%",
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      y: 100,
      layout: "vertical",
    },
    xAxis: {
      tickmarkPlacement: "on",
      categories: analyticsData.windRoseChart.map((item) => item.direction),
    },
    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,
      title: {
        text: "Percentage (%)",
      },
      labels: {
        formatter: function () {
          return this.value + "%";
        },
      },
      reversedStacks: false,
    },
    tooltip: {
      valueSuffix: "%",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPlacement: "on",
      },
    },
    series: [
      {
        name: "Distribution",
        data: analyticsData.windRoseChart.map((item) => item.percentage),
        pointPlacement: "on",
      },
    ],
  };

  const peakTimesOptions = {
    chart: {
      type: "timeline",
    },
    xAxis: {
      type: "datetime",
      visible: false,
    },
    yAxis: {
      gridLineWidth: 1,
      title: null,
      labels: {
        enabled: false,
      },
    },
    title: {
      text: "Peak Usage Times",
    },
    subtitle: {
      text: "Daily Activity Distribution",
    },
    series: [
      {
        dataLabels: {
          allowOverlap: false,
          format:
            '<span style="color:{point.color}">● </span><span style="font-weight: bold;" > {point.x:%H:%M}</span><br/>{point.label}',
        },
        marker: {
          symbol: "circle",
        },
        data: analyticsData.peakTimes.map((item) => {
          const [start] = item.time.split("-");
          const [hours, minutes] = start.split(":").map(Number);
          return {
            x: Date.UTC(2024, 0, 1, hours, minutes),
            label: `${item.percentage}% Usage`,
            description: `Peak usage of ${item.percentage}%`,
          };
        }),
      },
    ],
    tooltip: {
      style: {
        width: "150px",
      },
    },
  };

  const pdfRequestsOptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Most Requested PDFs",
    },
    xAxis: {
      categories: analyticsData.mostRequestedPDFs.map((item) => item.title),
      labels: {
        style: {
          fontSize: "11px",
        },
      },
    },
    yAxis: {
      title: {
        text: "Number of Requests",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: "{point.y:,.0f}",
        },
      },
    },
    series: [
      {
        name: "Requests",
        data: analyticsData.mostRequestedPDFs.map((item) => item.requests),
        color: "#1D6196",
      },
    ],
    tooltip: {
      pointFormat: "<b>{point.y:,.0f}</b> requests",
    },
  };

  const getDepartmentDetailsOptions = (dept) => ({
    chart: { type: "column" },
    title: { text: `${dept.department} Department Statistics` },
    xAxis: {
      categories: ["Total Users", "Active Users"],
    },
    yAxis: {
      title: { text: "Number of Users" },
    },
    series: [
      {
        name: "Users",
        data: [dept.users, dept.activeUsers],
        color: "#1D6196",
      },
    ],
  });

  const departmentOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Token Usage by Department",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y:,.0f}</b> tokens",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f}%",
        },
        point: {
          events: {
            click: function () {
              const dept = analyticsData.departments.find(
                (d) => d.department === this.name
              );
              setSelectedDepartment(dept);
            },
          },
        },
      },
    },
    series: [
      {
        name: "Tokens Used",
        data: analyticsData.departments.map((item) => ({
          name: item.department,
          y: item.tokens_used,
        })),
      },
    ],
  };

  const tokensPerSecondOptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Tokens Per Second Over Time",
    },
    xAxis: {
      categories: analyticsData.tokensPerSecond.map((item) => item.date),
      labels: {
        rotation: -45,
      },
    },
    yAxis: {
      title: {
        text: "Tokens per Second",
      },
    },
    tooltip: {
      valueSuffix: " tokens/s",
    },
    series: [
      {
        name: "Tokens/Second",
        data: analyticsData.tokensPerSecond.map(
          (item) => item.tokens_per_second
        ),
      },
    ],
  };

  return (
    <div className="">
      <h1 className="text-3xl font-bold text-[#1D6196] mb-8 text-center">
        AI Analytics Dashboard
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-gray-500 text-sm">Average Response Time</h3>
          <p className="text-2xl font-bold">
            {analyticsData.responseTime.average}s
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-gray-500 text-sm">Messages Today</h3>
          <p className="text-2xl font-bold">
            {analyticsData.messagesPerDay[
              analyticsData.messagesPerDay.length - 1
            ].messages.toLocaleString()}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-gray-500 text-sm">Accuracy Rate</h3>
          <p className="text-2xl font-bold">
            {analyticsData.accuracy.accurateMessages}%
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-gray-500 text-sm">Total Likes</h3>
          <p className="text-2xl font-bold">
            {analyticsData.messageApproval.totalLikes.toLocaleString()}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <HighchartsReact
            highcharts={Highcharts}
            options={departmentOptions}
          />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <HighchartsReact
            highcharts={Highcharts}
            options={tokensPerSecondOptions}
          />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <HighchartsReact
            highcharts={Highcharts}
            options={responseTimeOptions}
          />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <HighchartsReact
            highcharts={Highcharts}
            options={messagesPerDayOptions}
          />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <HighchartsReact highcharts={Highcharts} options={approvalOptions} />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <HighchartsReact
            highcharts={Highcharts}
            options={countryStatsOptions}
          />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md ">
          <HighchartsReact
            highcharts={Highcharts}
            options={funnelChartOptions}
          />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md ">
          <HighchartsReact highcharts={Highcharts} options={windRoseOptions} />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <HighchartsReact highcharts={Highcharts} options={peakTimesOptions} />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <HighchartsReact
            highcharts={Highcharts}
            options={pdfRequestsOptions}
          />
        </div>
      </div>

      {/* <div className="my-8 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Most Requested PDFs</h3>
          <div className="space-y-4">
            {analyticsData.mostRequestedPDFs.slice(0, 5).map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center border-b pb-2"
              >
                <span>{item.title}</span>
                <span className="font-bold">
                  {item.requests.toLocaleString()}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {selectedDepartment && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-[#1D6196]">
                {selectedDepartment.department} Details
              </h2>
              <button
                onClick={() => setSelectedDepartment(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="bg-gray-50 p-4 rounded">
                <h3 className="text-gray-500 text-sm">Total Users</h3>
                <p className="text-xl font-bold">{selectedDepartment.users}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded">
                <h3 className="text-gray-500 text-sm">Active Users</h3>
                <p className="text-xl font-bold">
                  {selectedDepartment.activeUsers}
                </p>
              </div>
              <div className="bg-gray-50 p-4 rounded">
                <h3 className="text-gray-500 text-sm">Monthly Growth</h3>
                <p className="text-xl font-bold">
                  +{selectedDepartment.monthlyGrowth}%
                </p>
              </div>
              <div className="bg-gray-50 p-4 rounded">
                <h3 className="text-gray-500 text-sm">Avg Tokens/User</h3>
                <p className="text-xl font-bold">
                  {selectedDepartment.avgTokensPerUser}
                </p>
              </div>
            </div>

            <div className="h-[400px]">
              <HighchartsReact
                highcharts={Highcharts}
                options={getDepartmentDetailsOptions(selectedDepartment)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeScreen;
