import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";

function EditModel({
  handleModelEditClick,
  closeEditModel,
  modelEdit,
  maxTokensEdit,
  knowledgeUpToEdit,
  descriptionEdit,
  idEdit,
  logoEdit,
  llm_id,
  tempEdit,
  kEdit,
}: any) {
  interface ParentModelData {
    llm_id: number;
    llm_name: string;
  }

  const [parentModelData, setParentModelData] = useState<
    ParentModelData[] | null
  >(null);

  const [file, setFile] = useState<File | null>(null);
  const [model, setModel] = useState(modelEdit);
  const [description, setDescription] = useState(descriptionEdit);
  const [maxTokens, setMaxTokens] = useState(maxTokensEdit);
  const [knowledgeUpTo, setKnowledgeUpTo] = useState(knowledgeUpToEdit);
  const [selectedLLM, setSelectedLLM] = useState(llm_id);
  const [k, setK] = useState(kEdit);
  const [temp, setTemp] = useState(tempEdit);
  const [showFeatures, setShowFeatures] = useState(true);

  // const handleFeaturesClick = () => {
  //   setShowFeatures(true);
  // };

  // const handleAdvancedFeaturesClick = () => {
  //   setShowFeatures(false);
  // };

  const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
  };

  const handleMaxTokensChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxTokens(e.target.value);
  };

  const handleKChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setK(e.target.value);
  };

  const handleTempChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemp(e.target.value);
  };

  const handleKnowledgeUpToChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setKnowledgeUpTo(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("model", model);
    formData.append("description", description);
    formData.append("max_tokens", maxTokens);
    formData.append("training_data_upto", knowledgeUpTo);
    formData.append("id", idEdit);
    formData.append("llm_id", selectedLLM);

    const settings = {
      key: k,
      temp: temp,
    };

    formData.append("global_settings", JSON.stringify(settings));

    if (file === null) {
      formData.append("logo", logoEdit);
    } else {
      formData.append("logo", file);
    }

    try {
      await api.post(`${process.env.REACT_APP_MODEL_UPDATE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      window.location.reload();
      toast.success("LLM Model has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while updating the LLM Model", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_MODEL_LLM_KEYS}`
        );
        const data = response.data;
        setParentModelData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!parentModelData) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={handleModelEditClick}
      role="button"
    >
      <div className="relative bg-white p-4 rounded shadow-2xl">
        <button
          onClick={closeEditModel}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">Edit LLM Model</p>
        {/* <div className="mb-4">
          <button
            onClick={handleFeaturesClick}
            className={`mr-2 px-4 py-2 rounded ${
              showFeatures
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Features
          </button>
          <button
            onClick={handleAdvancedFeaturesClick}
            className={`px-4 py-2 rounded ${
              !showFeatures
                ? "bg-[#4D30B5] hover:bg-[#1b1436] text-white rounded duration-300"
                : "bg-gray-300 text-gray-600"
            }`}
          >
            Advanced Features
          </button>
        </div> */}
        {showFeatures ? (
          <form onSubmit={handleEditSubmit} className=" md:w-[750px] w-[350px]">
            <div className="p-4 flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-4">
                <div className=" flex flex-col ">
                  <label className="pb-2 text-[20px]">Name:</label>
                  <input
                    required
                    type="text"
                    value={model}
                    onChange={handleModelChange}
                    className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                  />
                </div>
                <div className=" flex flex-col ">
                  <label className="pb-2 text-[20px]">Select LLM:</label>
                  <select
                    disabled
                    required
                    value={selectedLLM}
                    onChange={(e) => setSelectedLLM(e.target.value)}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  >
                    <option value="" disabled>
                      Select an LLM model
                    </option>
                    {parentModelData?.map((llm) => (
                      <option key={llm?.llm_id} value={llm?.llm_id}>
                        {llm?.llm_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                <div className=" flex flex-col ">
                  <label className="pb-2 text-[20px]">Max Tokens:</label>
                  <input
                    required
                    type="text"
                    value={maxTokens}
                    onChange={handleMaxTokensChange}
                    className=" border-[2px] text-black outline-none p-1 rounded-lg"
                  />
                </div>
                <div className=" flex flex-col ">
                  <label className="pb-2 text-[20px]">Knowledge Up To:</label>
                  <input
                    required
                    type="text"
                    value={knowledgeUpTo}
                    onChange={handleKnowledgeUpToChange}
                    className=" border-[2px] text-black outline-none p-1 rounded-lg"
                  />
                </div>
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Description:</label>
                <textarea
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="border-[2px] text-black rounded-lg outline-none p-1 resize-none h-[140px] custom-scrollbar-chat"
                />
              </div>

              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Image:</label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mt-4 flex justify-center">
                <button
                  type="submit"
                  className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                >
                  Edit LLM Model
                </button>
              </div>
            </div>
          </form>
        ) : (
          <form
            onSubmit={handleEditSubmit}
            className=" md:w-[750px] w-[350px] h-[568px]"
          >
            <div className=" flex flex-col justify-center items-center">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Setting
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                      K
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      <input
                        required
                        type="text"
                        value={k}
                        onChange={handleKChange}
                        className="outline-none"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                      K-regenrate
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      8
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                      Temp
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      <input
                        required
                        type="text"
                        value={temp}
                        onChange={handleTempChange}
                        className=" outline-none"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-4 flex justify-center">
                <button
                  type="submit"
                  className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                >
                  Edit LLM Model
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
export default EditModel;
