import React, { useEffect, useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import {
  BsArrowLeft,
  BsChat,
  BsFileText,
  BsGrid3X3Gap,
  BsImage,
} from "react-icons/bs";
import { FcOpenedFolder } from "react-icons/fc";
import { Menu, Item, Separator, contextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { FaFile, FaRegFilePdf } from "react-icons/fa6";
import { WiCloudRefresh } from "react-icons/wi";
import Milvus from "./Milvus";
import Cookies from "js-cookie";

interface MainSectionProps {
  folderPath: string;
  onFolderClick: (path: string) => void;
  entries: [];
  selectedFoldersId: string[];
  selectedFiles: string[];
  handleFileClick: (file: string, folder: string) => void;
}

const FolderContents: React.FC<MainSectionProps> = ({
  folderPath,
  onFolderClick,
  entries,
  selectedFoldersId,
  selectedFiles,
  handleFileClick,
}) => {
  const [data, setData] = useState<any | null>(null);
  const [isGridLayout, setGridLayout] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pathHistory, setPathHistory] = useState<string[]>([]);
  const user_id = localStorage.getItem("user_id");
  const token = Cookies.get("access_token");

  const typeToIconMap: { [key: string]: React.ReactNode } = {
    Folder: <FcOpenedFolder size={25} />,
    "text/plain": <BsFileText size={25} />,
    "image/jpeg": <BsImage size={25} />,
    "application/pdf": <FaRegFilePdf size={25} />,
  };

  useEffect(() => {
    const fetchData = async (folderPath: string) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ONE_DRIVE_LIST_FILES}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: user_id,
              folder_id: folderPath ? folderPath : null,
            }),
          }
        );

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error("Failed to fetch data from Dropbox API");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData(folderPath);
  }, [folderPath]);

  const navigateBack = () => {
    if (pathHistory?.length > 1) {
      const previousPath = pathHistory[pathHistory?.length - 2];
      setPathHistory(pathHistory.slice(0, -1));
      onFolderClick(previousPath);
    }
  };

  useEffect(() => {
    setPathHistory((prevPathHistory) => [...prevPathHistory, folderPath]);
  }, [folderPath]);

  const toggleGrid = () => {
    setGridLayout(true);
  };

  const toggleList = () => {
    setGridLayout(false);
  };

  const fileDisplay = isGridLayout ? "grid grid-cols-5" : "grid grid-cols-1";

  const handleContextMenu = (event: React.MouseEvent, file: any) => {
    event.preventDefault();
    contextMenu.show({
      id: "fileContextMenu",
      event,
      props: { file },
    });
  };

  const filteredFiles = entries
    ? entries.filter(
        (entry: any) =>
          entry.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
          entry.type !== "Folder"
      )
    : [];

  const filteredContent = data
    ? data.filter(
        (entry: any) =>
          entry.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
          entry.type !== "Folder"
      )
    : [];

  const handleSendRequest = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_ONE_DRIVE_HOOKS}`, {
        method: "POST",
        body: JSON.stringify({
          chosen_ids:
            selectedFoldersId.length > 0 ? selectedFoldersId : selectedFiles,
          user_id: user_id,
        }),
      });

      if (response.ok) {
        console.log("POST request successful");
      } else {
        console.error("Failed to send POST request");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const [openMilvus, setOpenMilvus] = useState(false);

  const openMilvusSend = () => {
    setOpenMilvus(true);
  };

  const closeMilvusSend = () => {
    setOpenMilvus(false);
  };

  const handleMilvusClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeMilvusSend();
    }
  };

  return (
    <div className="p-2 w-[80%] h-full overflow-y-auto">
      <div className=" sticky top-0 z-10 flex flex-row justify-between items-center pb-3">
        <div className=" flex flex-col gap-2">
          <input
            type="text"
            placeholder="Search by file name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className=" outline-none p-2 rounded-md"
          />
          <div className=" items-center gap-2 flex flex-row">
            <button
              onClick={navigateBack}
              className="hover:cursor-pointer p-2 hover:-ml-1 duration-300 hover:mr-1"
            >
              <BsArrowLeft size={20} title="Back to Previous Path" />
            </button>
            <h2>Path {folderPath}:</h2>
          </div>
        </div>
        <div className=" flex flex-col gap-2">
          <div className=" flex justify-end px-2">
            <div className=" flex flex-row gap-2">
              <button
                onClick={handleSendRequest}
                disabled={
                  selectedFiles.length === 0 && selectedFoldersId.length === 0
                }
                className="bg-[#4D30B5] text-white p-2 rounded-md hover:bg-opacity-80 disabled:opacity-50 duration-300"
              >
                <WiCloudRefresh title="Create Webhook" size={20} />
              </button>
              <button
                onClick={openMilvusSend}
                disabled={
                  selectedFiles.length === 0 && selectedFoldersId.length === 0
                }
                className="bg-[#4D30B5] text-white p-2 rounded-md hover:bg-opacity-80 disabled:opacity-50 duration-300"
              >
                <BsChat title="Upload to Milvus" size={20} />
              </button>
            </div>
          </div>
          <div className="flex flex-row gap-[2px] items-center pt-1 px-2">
            <div
              role="button"
              onClick={toggleGrid}
              className={`hover:cursor-pointer p-2  ${
                isGridLayout === true ? "bg-[#4D30B5]" : "bg-[#EDEAF8]"
              } `}
            >
              <BsGrid3X3Gap
                title="Grid View"
                size={20}
                className={`${
                  isGridLayout === true ? " text-white" : "text-[#4D30B5]"
                }`}
              />
            </div>
            <div
              role="button"
              onClick={toggleList}
              className={` hover:cursor-pointer p-2 ${
                isGridLayout === true ? "bg-[#EDEAF8]" : "bg-[#4D30B5]"
              } `}
            >
              <AiOutlineUnorderedList
                title="List View"
                size={20}
                className={`${
                  isGridLayout === true ? "text-[#4D30B5]" : " text-white"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      {data &&
        (isGridLayout ? (
          <div>
            {folderPath ? (
              <div>
                {data?.length === 0 ? (
                  <div>This folder is empty!</div>
                ) : (
                  <div className={`${fileDisplay} gap-3`}>
                    {filteredContent?.length === 0 ? (
                      <div>No matching files found</div>
                    ) : (
                      filteredContent?.map((entry: any, index: any) =>
                        entry[".tag"] === "folder" ? null : (
                          <div
                            key={index}
                            onContextMenu={(event) =>
                              handleContextMenu(event, entry)
                            }
                          >
                            <div className="grid grid-rows-4 border border-[#4D30B5] cursor-pointer ">
                              <div className=" row-span-3 relative">
                                <div className=" absolute right-1 top-1">
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedFiles?.includes(entry.id)
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      handleFileClick(entry.id, folderPath)
                                    }
                                  />
                                </div>
                                <div>hi</div>
                              </div>
                              <div className=" border-t-2 p-2 row-span-1 grid grid-cols-10 items-center">
                                <div className=" col-span-1 ">
                                  {typeToIconMap[entry?.type] || (
                                    <FaFile size={25} />
                                  )}
                                </div>
                                <div className=" col-span-1"></div>
                                <p className=" col-span-8 text-center text-[13px] truncate">
                                  {entry.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  {entries?.length === 0 ? (
                    <div>This folder is empty!</div>
                  ) : (
                    <div className={`${fileDisplay} gap-3`}>
                      {filteredFiles?.length === 0 ? (
                        <div>No matching files found</div>
                      ) : (
                        filteredFiles?.map((entry: any, index: any) =>
                          entry[".tag"] === "folder" ? null : (
                            <div
                              key={index}
                              onContextMenu={(event) =>
                                handleContextMenu(event, entry)
                              }
                            >
                              <div className="grid grid-rows-4 border border-[#4D30B5] cursor-pointer ">
                                <div className=" row-span-3 relative">
                                  <div className=" absolute right-1 top-1">
                                    <input
                                      type="checkbox"
                                      checked={
                                        selectedFiles?.includes(entry.id)
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        handleFileClick(entry.id, folderPath)
                                      }
                                    />
                                  </div>
                                  <div>hi</div>
                                </div>
                                <div className=" border-t-2 p-2 row-span-1 grid grid-cols-10 items-center">
                                  <div className=" col-span-1 ">
                                    {typeToIconMap[entry?.type] || (
                                      <FaFile size={25} />
                                    )}
                                  </div>
                                  <div className=" col-span-1"></div>
                                  <p className=" col-span-8 text-center text-[13px] truncate">
                                    {entry.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {folderPath ? (
              <div>
                {data?.length === 0 ? (
                  <div>This Folder is empty!</div>
                ) : (
                  <div className={`${fileDisplay} gap-3`}>
                    {filteredContent?.length === 0 ? (
                      <div>No matching files found!</div>
                    ) : (
                      filteredContent?.map((entry: any, index: any) => (
                        <div
                          key={index}
                          onContextMenu={(event) =>
                            handleContextMenu(event, entry)
                          }
                        >
                          <div className=" flex flex-row justify-between">
                            <div
                              className={`gap-2 cursor-pointer flex flex-row items-center`}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  selectedFiles?.includes(entry.id)
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleFileClick(entry.id, folderPath)
                                }
                              />
                              {typeToIconMap[entry?.type] || (
                                <FaFile size={25} />
                              )}
                              <p className="text-center text-[13px]">
                                {entry.name?.length > 60
                                  ? entry.name.substring(0, 60) + "..."
                                  : entry.name}
                              </p>
                            </div>
                            <div className=" flex flex-row gap-5 items-center">
                              <p>{entry?.modifiedTime}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  {entries?.length === 0 ? (
                    <div>This Folder is empty!</div>
                  ) : (
                    <div className={`${fileDisplay} gap-3`}>
                      {filteredFiles?.length === 0 ? (
                        <div>No matching files found!</div>
                      ) : (
                        filteredFiles?.map((entry: any, index: any) => (
                          <div
                            key={index}
                            onContextMenu={(event) =>
                              handleContextMenu(event, entry)
                            }
                          >
                            <div className=" flex flex-row justify-between">
                              <div
                                className={`gap-2 cursor-pointer flex flex-row items-center`}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedFiles?.includes(entry.id)
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleFileClick(entry.id, folderPath)
                                  }
                                />
                                {typeToIconMap[entry?.type] || (
                                  <FaFile size={25} />
                                )}
                                <p className="text-center text-[13px]">
                                  {entry.name?.length > 60
                                    ? entry.name.substring(0, 60) + "..."
                                    : entry.name}
                                </p>
                              </div>
                              <div className=" flex flex-row gap-5 items-center">
                                <p>{entry?.lastModified}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      <Menu id="fileContextMenu">
        <Item onClick={({ props }) => console.log(`Chat ${props.file.name}`)}>
          Chat
        </Item>
        <Item onClick={({ props }) => console.log(`Open ${props.file.name}`)}>
          Open
        </Item>
        <Item onClick={({ props }) => console.log(`Rename ${props.file.name}`)}>
          Rename
        </Item>
        <Separator />
        <Item onClick={({ props }) => console.log(`Delete ${props.file.name}`)}>
          <p className=" text-red-700 ">Delete</p>
        </Item>
      </Menu>
      {openMilvus && (
        <Milvus
          handleMilvusClick={handleMilvusClick}
          selectedFoldersId={selectedFoldersId}
          selectedFiles={selectedFiles}
          closeMilvusSend={closeMilvusSend}
        />
      )}
    </div>
  );
};

export default FolderContents;
