import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../api";
import { RiDeleteBinLine } from "react-icons/ri";
import { createTag, fetchTagsByAgent } from "../../../GlobalFunctions";

function CreateJob({ handleJobOpenClick, closeJob, setRefresh }: any) {
  interface AgentData {
    id: string;
    agent: string;
  }
  interface TagData {
    id: string;
    tag_name: string;
  }

  const [extensions] = useState([
    {
      name: ".pdf",
    },
    {
      name: ".docx",
    },
    {
      name: ".txt",
    },
  ]);

  const [name, setName] = useState("");
  const [selectedAgent, setSelectedAgent] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<string>("");
  const [agentData, setAgentData] = useState<AgentData[] | null>(null);
  const [tagData, setTagData] = useState<TagData[] | null>(null);
  const [newTagName, setNewTagName] = useState("");
  const [frequency, setFrequency] = useState("");
  const [time, setTime] = useState("");

  const [isEmailType, setIsEmailType] = useState<boolean>(false);
  const [emails, setEmails] = useState<string[]>([]);

  const [availableLocations, setAvailableLocations] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const [checkedExtensions, setCheckedExtensions] = useState<string[]>([]);

  const [isMinuteSelected, setIsMinuteSelected] = useState<boolean>(false);
  const [minuteValue, setMinuteValue] = useState<number | undefined>(undefined);

  const user_id = localStorage.getItem("user_id") || "default_user";

  const handleLocationSelect = (location: string) => {
    setSelectedLocations((prevLocations) => [...prevLocations, location]);
  };

  const handleLocationDelete = (location: string) => {
    setSelectedLocations((prevLocations) =>
      prevLocations.filter((e) => e !== location)
    );
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_RAG_AGENT_BY_USER}${user_id}`;

    const fetchData = async () => {
      try {
        const response = await api.get(apiUrl);

        if (response.status === 200) {
          const data = response.data;
          setAgentData(data);
        } else {
          console.error("Failed to fetch data from Agent API");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setSelectedTag("");
    setSelectedLocations([]);
    setAvailableLocations([]);
    setEmails([]);
    setIsEmailType(false);

    if (selectedAgent !== "") {
      let apiUrl = `${process.env.REACT_APP_TAGS_BY_AGENT}${selectedAgent}`;

      const fetchData = async () => {
        try {
          const response = await api.get(apiUrl);

          if (response.status === 200) {
            const data = response.data;
            setTagData(data);
          } else {
            console.error("Failed to fetch data from Tags API");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }

        apiUrl = `${process.env.REACT_APP_AGENT_LOCATIONS_OR_EMAILS}${selectedAgent}`;
        try {
          const response = await api.get(apiUrl);

          if (response.status === 200) {
            const data = response.data;
            if (data.type === "email") {
              setIsEmailType(true);
              setEmails(data.data);
            } else {
              setIsEmailType(false);
              setAvailableLocations(data.data);
            }
          } else {
            console.error("Failed to fetch data from Locations API");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      };

      fetchData();
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (tagData?.length && tagData?.length > 0) {
      setSelectedTag(tagData[0].id);
    } else {
      setSelectedTag("");
    }
  }, [tagData]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleAgentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAgent(e.target.value);
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTag(e.target.value);
  };

  const handleNewTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagName(e.target.value);
    setSelectedAgent(selectedAgent);
  };

  const handleExtensionCheckBoxChange = (ext: string) => {
    setCheckedExtensions((prevExtensions) => {
      if (prevExtensions.includes(ext)) {
        return prevExtensions.filter((extension) => extension !== ext);
      } else {
        return [...prevExtensions, ext];
      }
    });
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedLocations.length === 0) {
      toast.error(
        "Please select at least one location or email to create the job",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        }
      );
      return;
    }

    const formData = new FormData();
    formData.append("job_name", name);
    formData.append("user_id", user_id);
    formData.append("agent_id", selectedAgent);
    formData.append("tag_id", selectedTag);
    formData.append("extensions", JSON.stringify(checkedExtensions));
    formData.append("frequency", frequency);
    formData.append("time", time);

    if (isMinuteSelected && minuteValue !== undefined) {
      formData.append("minute", minuteValue.toString());
    }

    if (isEmailType) {
      formData.append("emails", JSON.stringify(selectedLocations));
    } else {
      formData.append("locations", JSON.stringify(selectedLocations));
    }

    try {
      await api.post(
        `${process.env.REACT_APP_CREATE_JOB}${user_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      closeJob();
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("Job has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while creating the Job: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while creating the Job", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error creating the Job:", error);
    }
  };

  const handleCreateTag = () => {
    createTag(
      newTagName,
      selectedAgent,
      setNewTagName,
      setSelectedTag,
      setTagData
    );
  };

  useEffect(() => {
    fetchTagsByAgent(selectedAgent, setTagData);
  }, [selectedAgent]);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
      onClick={handleJobOpenClick}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeJob}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">Create Job</p>

        <div className=" md:w-[750px] w-[350px] max-h-[600px] overflow-y-scroll">
          <div className="p-4 flex flex-col gap-4">
            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Name:</label>
              <input
                required
                type="text"
                value={name}
                onChange={handleNameChange}
                className=" border-[2px] text-black  outline-none p-1 rounded-lg"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">Frequency:</label>
                <select
                  required
                  value={frequency}
                  onChange={(e) => {
                    setFrequency(e.target.value);
                    if (e.target.value === "minute") {
                      setIsMinuteSelected(true);
                    } else {
                      setIsMinuteSelected(false);
                    }
                  }}
                  className="py-2 pl-0 border-b border-[#4D30B5] outline-none hover:bg-[#EDEAF8] cursor-pointer duration-300"
                >
                  <option value="" disabled>
                    Select Frequency
                  </option>
                  <option value="minute">Minute</option>
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">Time:</label>
                <input
                  type="datetime-local"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className="border-[2px] text-black outline-none p-1 rounded-lg"
                />
              </div>
            </div>

            {isMinuteSelected && (
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">Minute:</label>
                <input
                  type="number"
                  value={minuteValue || ""}
                  onChange={(e) => setMinuteValue(parseInt(e.target.value))}
                  className="border-[2px] text-black outline-none p-1 rounded-lg"
                />
              </div>
            )}

            <div className=" grid grid-cols-2 gap-4 ">
              <div className=" flex flex-col gap-1 py-4 ">
                <label className=" text-[20px]">Select Data Agent:</label>
                <select
                  required
                  value={selectedAgent}
                  onChange={handleAgentChange}
                  className=" py-2 pl-0 border-b border-[#4D30B5] outline-none hover:bg-[#EDEAF8] cursor-pointer duration-300"
                >
                  <option value="" disabled>
                    Select a Data Agent
                  </option>
                  {agentData &&
                    agentData.length > 0 &&
                    agentData?.map((llm) => (
                      <option key={llm.id} value={llm.id}>
                        {llm.agent}
                      </option>
                    ))}
                </select>
              </div>
              {selectedAgent && (
                <div className=" flex flex-col gap-1 py-4 ">
                  <label className=" text-[20px]">Select Tag:</label>

                  <select
                    required
                    value={selectedTag}
                    onChange={handleTagChange}
                    className=" py-2 pl-0 border-b border-[#4D30B5] outline-none hover:bg-[#EDEAF8] cursor-pointer duration-300"
                  >
                    <option value="" disabled>
                      Select a Tag
                    </option>
                    {tagData?.map((tag) => (
                      <option key={tag.id} value={tag.id}>
                        {tag.tag_name}
                      </option>
                    ))}
                    <option value="createNewTag">Create New Tag</option>
                  </select>
                </div>
              )}
            </div>
            {selectedTag === "createNewTag" && (
              <div className="grid grid-cols-2 gap-4">
                <div></div>
                <div className="grid grid-cols-5 gap-2 ">
                  <div className=" col-span-3">
                    <input
                      type="text"
                      placeholder="New Tag Name"
                      value={newTagName}
                      onChange={handleNewTagChange}
                      className="w-full py-2 pl-0 border-b border-[#4D30B5] outline-none hover:bg-[#EDEAF8] duration-300"
                    />
                  </div>
                  <div className=" col-span-2">
                    <button
                      onClick={handleCreateTag}
                      className="bg-[#413183] text-white px-5 py-2 rounded-md hover:bg-[#20144C] duration-300"
                    >
                      Create Tag
                    </button>
                  </div>
                </div>
              </div>
            )}

            {selectedAgent && (
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">
                  {isEmailType ? "Select Emails:" : "Select Location:"}
                </label>
                <div className="grid grid-cols-2 gap-2 overflow-y-scroll">
                  {isEmailType
                    ? emails?.map((email) => (
                        <button
                          disabled={selectedLocations.includes(email)}
                          key={email}
                          onClick={() => handleLocationSelect(email)}
                          className={` mb-2 py-2 rounded ${
                            selectedLocations.includes(email)
                              ? "bg-[#4D30B5]  text-white"
                              : "bg-gray-300 text-gray-600"
                          }`}
                        >
                          {email}
                        </button>
                      ))
                    : availableLocations?.map((location) => (
                        <button
                          disabled={selectedLocations.includes(location)}
                          key={location}
                          onClick={() => handleLocationSelect(location)}
                          className={` mb-2 py-2 rounded ${
                            selectedLocations.includes(location)
                              ? "bg-[#4D30B5]  text-white"
                              : "bg-gray-300 text-gray-600"
                          }`}
                        >
                          {location}
                        </button>
                      ))}
                </div>
                {selectedLocations && selectedLocations?.length > 0 && (
                  <div className="mt-2">
                    <p className="pb-2 text-[20px]">
                      Selected {isEmailType ? "Emails" : "Locations"}:
                    </p>
                    <div className="grid grid-cols-2 gap-2">
                      {selectedLocations?.map((location) => (
                        <div
                          key={location}
                          className="flex flex-row items-center mb-2"
                        >
                          <p>{location}</p>
                          <button
                            onClick={() => handleLocationDelete(location)}
                          >
                            <RiDeleteBinLine className="text-red-600 hover:text-red-700 duration-300" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {!isEmailType && (
              <div>
                <label className="pb-2 text-[20px]">Extensions:</label>
                <div className="z-10 bg-gray-100 rounded-lg shadow w-full mt-2 ">
                  <ul
                    className=" px-3 pb-3 overflow-y-auto text-sm md:grid md:grid-cols-3"
                    aria-labelledby="dropdownSearchButton"
                  >
                    {extensions?.map((extension, index) => {
                      if (checkedExtensions?.includes(extension?.name)) {
                        return (
                          <li key={index}>
                            <div className=" flex items-center p-2 rounded">
                              <input
                                defaultChecked
                                id="checkbox-item-13"
                                type="checkbox"
                                value=""
                                className="w-4 h-4 "
                                onChange={() =>
                                  handleExtensionCheckBoxChange(extension?.name)
                                }
                              />
                              <label className="w-full ml-2 text-sm font-medium ">
                                {extension?.name}
                              </label>
                            </div>
                          </li>
                        );
                      } else {
                        return (
                          <li key={index}>
                            <div className="flex items-center p-2 rounded ">
                              <input
                                id="checkbox-item-14"
                                type="checkbox"
                                value={extension?.name}
                                className="w-4 h-4 text-blue-600 "
                                onChange={() =>
                                  handleExtensionCheckBoxChange(extension?.name)
                                }
                              />
                              <label className="w-full ml-2 text-sm font-medium ">
                                {extension?.name}
                              </label>
                            </div>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            )}

            <div className="mt-4 flex justify-center">
              <button
                onClick={(e: any) => handleUploadSubmit(e)}
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create Job
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateJob;
