import React, { useState } from "react";
import { IoChevronBackSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import Star from "../../../../Assets/star.png";
import Vector from "../../../../Assets/Vector.png";
import Circle from "../../../../Assets/circle.png";

const Profile = () => {
  const [selectedOption, setSelectedOption] = useState("annual");

  return (
    <div>
      <div className="flex flex-row items-center w-[200px]  gap-1">
        <Link
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg  hover:cursor-pointer  cursor-pointer   "
          to={"/profile"}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px]">Dashboard</p>
      </div>
      <div className="mt-2 flex flex-col justify-center items-center ">
        <div className="rounded-lg shadow-md bg-[#FFFFFF] h-full p-5 w-[750px] max-lg:w-full">
          <div className="flex flex-col justify-center items-center">
            <img
              src={Star}
              alt="Star"
              className="mt-7"
              width={95}
              loading="lazy"
            />
            <p className="text-[20px] font-semibold text-[#4D30B5] mt-5">
              Upgrade To Pro
            </p>
          </div>
          <div className="bg-[#FBE8F8] mt-5 rounded-lg p-5">
            <p className=" font-semibold text-[16px]">What You Will Get:</p>
            <div className="mt-5">
              <div className="flex flex-row items-center gap-2 mb-3">
                <div>
                  <img src={Vector} alt="Vector" width={20} loading="lazy" />
                </div>
                <div>
                  <p className=" font-medium">Placeholder</p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-2 mb-3">
                <div>
                  <img src={Vector} alt="Vector" width={20} loading="lazy" />
                </div>
                <div>
                  <p className=" font-medium">Placeholder</p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-2 mb-3">
                <div>
                  <img src={Vector} alt="Vector" width={20} loading="lazy" />
                </div>
                <div>
                  <p className=" font-medium">Placeholder</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div
              className={` cursor-pointer rounded-lg mb-5 ${
                selectedOption === "annual"
                  ? "bg-[#EDEAF8] border-2 border-[#4D30B5] "
                  : "bg-[#f9f9f9]"
              }`}
              onClick={() => setSelectedOption("annual")}
              role="button"
            >
              <div className="flex flex-row items-center gap-3 p-3 ml-3">
                <div>
                  <img
                    src={selectedOption === "annual" ? Vector : Circle}
                    width={18}
                    alt="Circle"
                    loading="lazy"
                  />
                </div>
                <div>
                  <p className=" font-medium text-[14px]">Annual</p>
                  <p className=" font-semibold text-[16px]">16$</p>
                </div>
              </div>
            </div>
            <div
              className={` cursor-pointer rounded-lg mb-5 ${
                selectedOption === "monthly"
                  ? "bg-[#EDEAF8] border-2 border-[#4D30B5] "
                  : "bg-[#f9f9f9]"
              }`}
              onClick={() => setSelectedOption("monthly")}
              role="button"
            >
              <div className="flex flex-row items-center gap-3 p-3 ml-3">
                <div>
                  <img
                    src={selectedOption === "monthly" ? Vector : Circle}
                    width={18}
                    alt="Circle"
                    loading="lazy"
                  />
                </div>
                <div>
                  <p className=" font-medium text-[14px]">Monthly</p>
                  <p className=" font-semibold text-[16px]">16$</p>
                </div>
              </div>
            </div>
            <div
              className={` cursor-pointer rounded-lg mb-5 ${
                selectedOption === "weekly"
                  ? "bg-[#EDEAF8] border-2 border-[#4D30B5] "
                  : "bg-[#f9f9f9]"
              }`}
              onClick={() => setSelectedOption("weekly")}
              role="button"
            >
              <div className="flex flex-row items-center gap-3 p-3 ml-3">
                <div>
                  <img
                    src={selectedOption === "weekly" ? Vector : Circle}
                    width={18}
                    alt="Circle"
                    loading="lazy"
                  />
                </div>
                <div>
                  <p className=" font-medium text-[14px]">Weekly</p>
                  <p className=" font-semibold text-[16px]">16$</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center mb-5">
            <button className=" text-[15px] font-extrabold text-white bg-[#4D30B5] w-full rounded-lg p-4">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
